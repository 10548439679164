// SiteIdleAverage.js
import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { Card } from 'react-bootstrap';

const SiteIdleAverage = () => {
  useEffect(() => {
    const chartDom = document.getElementById('idle-period-chart');
    const idlePeriodChart = echarts.init(chartDom);

    const option = {
      title: {
        left: 'center',
        top: '2%',
        textStyle: {
          fontSize: 14,
          color: '#333',
        },
      },
      grid: {
        top: '20%',
        left: '10%',
        right: '10%',
        bottom: '15%',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'cross' },
      },
      legend: {
        data: ['유휴율 (%)', '평균 유휴 기간 (일)'],
        bottom: '0.1%',
      },
      xAxis: {
        type: 'category',
        data: ['고객사 A', '고객사 B', '고객사 C', '고객사 D', '고객사 E'],
        axisLabel: {
          interval: 0,
        },
      },
      yAxis: [
        {
          type: 'value',
          name: '유휴율 (%)',
          position: 'left',
          splitLine: { show: false },
          axisLabel: {
            formatter: '{value} %',
          },
        },
        {
          type: 'value',
          name: '평균 유휴 기간 (일)',
          position: 'right',
          splitLine: { show: false },
          axisLabel: {
            formatter: '{value} 일',
          },
        },
      ],
      series: [
        {
          name: '유휴율 (%)',
          type: 'bar',
          data: [35, 50, 40, 60, 45],
          itemStyle: {
            color: 'lightgrey',
          },
        },
        {
          name: '평균 유휴 기간 (일)',
          type: 'line',
          yAxisIndex: 1,
          data: [30, 45, 35, 50, 40],
          itemStyle: {
            color: '#4682B4',
          },
          label: {
            show: false,
          },
        },
      ],
    };

    idlePeriodChart.setOption(option);

    const handleResize = () => {
      idlePeriodChart.resize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      idlePeriodChart.dispose();
    };
  }, []);

  //return <div id="idle-period-chart" style={{ width: '600px', height: '300px', margin: '0 auto', border: '1px solid #ddd' }}></div>;
  return (


    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>고객사별 정책 유휴 기간 평균</h5>

      </Card.Header>
      <Card.Body>
        <div id="idle-period-chart" style={{ width: '100%', height: '20rem' }}></div>
        {/* <div>
            <div className="dropdown">
              <label htmlFor="timeScale">시간 단위 선택: </label>
              <select id="timeScale" value={timeScale} onChange={(e) => updateCharts(e.target.value)}>
                <option value="daily">일</option>
                <option value="weekly">주</option>
                <option value="monthly">월</option>
              </select>
            </div>
            
          </div> */}
      </Card.Body>

    </Card>


  );
};

export default SiteIdleAverage;
