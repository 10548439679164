import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEchart from 'components/common/ReactEchart';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const AlgorithmCompare = ({

  TotalValue = 132435,
  firstRatio = 0.8,
  secondRatio = 0.15,
  thirdRatio = 0.05,

}) => {
  const { getThemeColor } = useAppContext();
  const encryptionChartRef = useRef(null);
  const decryptionChartRef = useRef(null);

  const {
    config: { dashboardDate },
    setConfig
  } = useAppContext();

  // 암호화 및 복호화에 대한 성공 및 실패 처리량 계산
  const firstValue = Math.round(TotalValue * firstRatio);
  const secondValue = Math.round(TotalValue * secondRatio);
  const thirdValue = Math.round(TotalValue * thirdRatio);

  const getOption = (
    titleText,
    aesValue,
    ariaValue,
    seedValue,
    aesColor,
    ariaColor,
    seedColor,
  ) => {
    return {
      legend: {
        orient: 'horizontal',
        bottom: 0,
        left: 'center',
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          color: getThemeColor('gray-600'),
          fontSize: 12
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '70%'],
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 3,
            borderColor: getThemeColor('gray-100'),
            borderWidth: 2
          },
          label: {
            show: true,
            position: 'center',
            fontSize: 10,
            fontWeight: 'bold',
            color: getThemeColor('gray-700'),
            formatter: `{b|${titleText}}\n{c|${(
              aesValue + ariaValue + seedValue
            ).toLocaleString()}건}`,
            rich: {
              b: {
                fontSize: 14,
                lineHeight: 12,
                color: getThemeColor('gray-700')
              },
              c: {
                fontSize: 14,
                lineHeight: 16,
                fontWeight: 'bold',
                color: getThemeColor('gray-900')
              }
            }
          },
          labelLine: { show: false },
          data: [
            {
              value: aesValue,
              name: 'AES',
              itemStyle: {
                color: aesColor
              }
            },
            {
              value: ariaValue,
              name: 'ARIA',
              itemStyle: {
                color: ariaColor
              }
            },
            {
              value: seedValue,
              name: 'SEED',
              itemStyle: {
                color: seedColor
              }
            }
          ]
        }
      ],
      tooltip: {
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getThemeColor('gray-100'),
        borderColor: getThemeColor('gray-300'),
        textStyle: { color: getThemeColor('gray-1100') },
        borderWidth: 1,
        transitionDuration: 0,
        axisPointer: { type: 'none' }
      }
    };
  };

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>전체 알고리즘 요청률</h5>
        <span

          style={{
            marginLeft: '1px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
            whiteSpace: 'nowrap'
          }}
        >
          {dashboardDate}

        </span>
      </Card.Header>

      <Card.Body as={Flex} className="pt-0" alignItems="center" justifyContent="space-between">
        <ReactEchart
          echarts={echarts}
          option={getOption(
            '암호화 옵션',
            firstValue,
            secondValue,
            thirdValue,
            '#FFFEAC',
            '#FA8072',
            '#00FF00'
          )}
          ref={encryptionChartRef}
          style={{ width: '100%', height: '15rem' }}
        />

      </Card.Body>
    </Card>
  );
};

AlgorithmCompare.propTypes = {
  totalRequests: PropTypes.number,
  encryptionValue: PropTypes.number,
  decryptionValue: PropTypes.number,
  encryptionSuccessRatio: PropTypes.number,
  decryptionSuccessRatio: PropTypes.number
};

export default AlgorithmCompare;

