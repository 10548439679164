import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

const SummaryChart = () => {
  const chartRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1536);

  const {
    config: { dashboardDate },
    setConfig
  } = useAppContext();

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 1536);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    const option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)'
      },
      legend: {
        orient: 'horizontal',
        bottom: 10,
        textStyle: { fontSize: 10 },
        data: ['API', 'CloudFunctions']
      },
      series: [
        {
          name: '요청 분포',
          type: 'pie',
          radius: ['50%', '85%'],
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: [
              '{total|총 요청량}',
              '{count|132,435건}'
            ].join('\n'),
            rich: {
              total: {
                fontSize: 16,
                color: '#333',
                fontWeight: 'bold',
                align: 'center',
                padding: [10, 0]
              },
              count: {
                fontSize: 14,
                color: '#666',
                align: 'center'
              }
            }
          },
          labelLine: { show: false },
          data: [
            { value: 80, name: 'API' },
            { value: 20, name: 'CloudFunctions' }
          ],
          color: ['#FF7F50', '#27a093']
        }
      ]
    };

    chartInstance.setOption(option);
    chartInstance.resize();

    return () => chartInstance.dispose();
  }, []);

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>요약</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3',
            color: 'white',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          {dashboardDate}
        </span>
      </Card.Header>

      <Card.Body as={Flex} alignItems="center" className="px-0">
        <div
          className="container"
          style={{
            ...containerStyle,
            flexDirection: isMobileView ? 'column' : 'row'
          }}
        >
          <div className="left" style={{ ...leftStyle, width: isMobileView ? '100%' : '10%' }}>
            <div className="label" style={{ fontSize: '0.9rem' }}><strong>전체 고객사 수</strong></div>
            <div className="total-customers" style={{ fontSize: '1rem' }}>28개 사</div>
          </div>
          <div className="center-chart px-0" style={centerChartStyle} ref={chartRef}></div>
          <div className="right" style={{ ...rightStyle, width: isMobileView ? '100%' : '30%' }}>
            <div className="center-title" style={{ fontSize: '0.7rem' }}><strong>인증키 타입별 평균 처리 시간, <br />요청 중 실패 비율</strong></div>
            <div className="right-content">
              <div className="metric api" style={metricAPIStyle}>API</div>
              <div className="value">0.8초 <span className="label" style={labelStyle}>0.1%</span></div>
              <div className="metric cloudfunctions" style={metricCloudFunctionsStyle}>CloudFunctions</div>
              <div className="value">0.2초 <span className="label" style={labelStyle}>0.1%</span></div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

// 스타일 객체 정의
const containerStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  padding: '10px',
  boxSizing: 'border-box'
};

const leftStyle = {
  flex: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center'
};

const centerChartStyle = {
  flex: 4,
  width: '100%',
  height: '15rem'
};

const rightStyle = {
  flex: 3,
  textAlign: 'left',
  marginLeft: '1%',
  marginTop: '1%',
  marginBottom: '0%'
};

const metricAPIStyle = {
  fontSize: '1em',
  fontWeight: 'bold',
  marginBottom: '3%',
  color: '#FF7F50'
};

const metricCloudFunctionsStyle = {
  fontSize: '1em',
  fontWeight: 'bold',
  marginBottom: '3%',
  color: '#27a093',
  marginTop: '10%'
};

const labelStyle = {
  color: '#666',
  fontSize: '0.7em'
};

export default SummaryChart;
