import { useState } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Row, Col } from 'react-bootstrap';

const PolicyFormSwitch = ({ title, off, on, children }) => {

  return (
    <div style={{ marginTop: '1rem' }}>
      <span>{title}</span>
      <div style={{ display: 'flex' }}>
        <span>{off}</span>
        <Form.Check
          type="switch"
          id="custom-switch"
        />
        <span>{on}</span>
        {children}
      </div>
    </div>
  );
};

export default PolicyFormSwitch;
