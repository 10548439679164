import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';

import AuthCardLayout from 'layouts/AuthCardLayout';
import paths from 'routes/paths';

const Login = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">

        </p>
      }
    >

      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
