import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Modal, InputGroup, } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AiFillEye, AiFillEyeInvisible, AiFillCloseCircle } from 'react-icons/ai';
import { settings } from 'config'; // getSettings를 임포트합니다.
import { handleValueDelete } from 'components/common/SunnyUtils';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import { loginUser, getAdminInfo } from 'hooks/CallApi'; // API 함수 가져오기 
import FirstChangePasswordModal from 'safedb/common/FirstChangePasswordModal';


const LoginForm = ({ hasLabel = false, layout = 'simple' }) => {
  const navigate = useNavigate();
  // State
  const [formData, setFormData] = useState({
    id: '',
    password: '',
    isIdEmpty: false,
    isPasswordEmpty: false
  });

  const {
    config,
    setConfig
  } = useAppContext();

  const [showModal, setShowModal] = useState(false); // Modal state
  const [modalMessage, setModalMessage] = useState(''); // Modal message
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false); // 비밀번호 변경 모달 상태
  const [PwMessage, setPwMessage] = useState('');
  const openChangePasswordModal = () => setShowPasswordModal(true); // 비밀번호 변경 모달 열기 함수 
  useEffect(() => {
    if (isLoggedIn) {

      if (config.navvalue === 'SA') {
        navigate('/dashboard/crm');
      } else if (config.navvalue) {
        navigate('/');
      }
    }
  }, [config.navvalue, isLoggedIn]); // Use config.userAuth


  const [pwType, setpwType] = useState({
    type: "password",
    visible: false,
  });


  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { id, password } = formData;

    if (!id || !password) {
      toast.error('ID와 비밀번호를 입력해 주세요.');
      return;
    }

    try {
      console.log("id : ", id);
      const { token, refreshToken } = await loginUser({ username: id, password });

      sessionStorage.setItem('token', token);
      sessionStorage.setItem('refreshToken', refreshToken);
      // setIsLoggedIn(true);

      const { adminInfo, isPasswordChangeNeeded, changeReasonCode } = await getAdminInfo();

      if (isPasswordChangeNeeded) {
        let message = '';
        if (changeReasonCode === -5) {
          message = '보안 정책에 따라 계정의 비밀번호는 [N]일 마다 변경해야합니다. ';
          // setPwMessage('보안 정책에 따라 계정의 비밀번호는 [N]일 마다 변경해야합니다. ')
        } else if (changeReasonCode === -6) {
          message = 'SafeDB Cloud를 이용해주셔서 감사합니다. 보안을 위해 최초 접속시 비밀번호를 변경해주세요. ';
          // setPwMessage('SafeDB Cloud를 이용해주셔서 감사합니다. 보안을 위해 최초 접속시 비밀번호를 변경해주세요. ')
        }
        setPwMessage(message); // 먼저 메시지 설정
        setConfig('userId', formData.id);
        setShowPasswordModal(true);
        return;
      }
      let setNav = adminInfo.data.userRole;

      const setting = settings(setNav); // navValue에 따라 설정 가져오기
      setConfig('navvalue', setNav);
      setConfig('userAuth', setting.userAuth);
      setConfig('userId', formData.id);
      setConfig('userAuth_K', setting.userAuth_K);
      setConfig('userName', adminInfo.data.username);
      setConfig('checkorg', adminInfo.data.userRole === 'SA' ? '' : adminInfo.data.companyName);
      setConfig('navbarPosition', setting.navbarPosition);
      setConfig('firstLogin', setting.firstLogin);
      setConfig('userUuid', adminInfo.data.userIds.userUuid);

      if (adminInfo.data.active) {
        setIsLoggedIn(true);
      } else {
        toast.error('로그인 실패, 계정이 만료되었습니다.');
      }
    } catch (error) {
      toast.error('로그인 실패. ID와 비밀번호를 확인하세요.');

    }
  };

  const handleFieldChange = e => {
    const { name, value } = e.target;
    const hasKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value);

    if (hasKorean) {
      return;
    }

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
      isIdEmpty: false,
      isPasswordEmpty: false
    }));
  };

  const handleClose = () => setShowModal(false); // Close modal

  const handlePasswordType = (e) => {
    setpwType(() => {
      // 만약 현재 pwType.visible이 false 라면
      if (!pwType.visible) {
        return { type: "text", visible: true };

        //현재 pwType.visible이 true 라면
      } else {
        return { type: "password", visible: false };
      }
    });
  };


  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" style={{ position: 'relative' }}>
          {hasLabel && <Form.Label>ID<span className='text-danger'>*</span></Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'ID' : 'ID를 입력해 주세요.'}
            value={formData.id}
            name="id"
            onChange={handleFieldChange}
            type="text"
            className={`${formData.isIdEmpty ? 'is-invalid input-hover' : 'input-hover'}`}
            style={{ paddingRight: '30px' }} // 아이콘이 들어갈 공간 확보
          />
          {formData.id && (
            <span
              onClick={() => handleValueDelete('id', formData, setFormData)}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: '10px', // 아이콘 위치 조정
                top: '70%',
                transform: 'translateY(-50%)' // 수직 중앙 정렬
              }}
            >
              <AiFillCloseCircle />
            </span>
          )}
          {formData.isIdEmpty && (
            <span className="text-danger">
              ID 입력이 필요합니다.
            </span>
          )}
        </Form.Group>


        <Form.Group className="mb-3" style={{ position: 'relative' }}>
          {hasLabel && <Form.Label>Password<span className='text-danger'>*</span></Form.Label>}

          <Form.Control
            placeholder={!hasLabel ? 'Password' : '비밀번호를 입력해주세요.'}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type={pwType.type}
            className={`${formData.isPasswordEmpty ? 'is-invalid input-hover' : 'input-hover'}`}
            style={{ paddingRight: '30px' }} // 아이콘이 들어갈 공간 확보
          />
          {formData.password && (
            <span
              onClick={handlePasswordType}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: '40px', // 아이콘 위치 조정
                top: '70%',
                transform: 'translateY(-50%)' // 수직 중앙 정렬
              }}
            >
              {pwType.visible ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>

          )}
          {formData.password && (
            <span
              onClick={() => handleValueDelete('password', formData, setFormData)}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: '10px', // 아이콘 위치 조정
                top: '70%',
                transform: 'translateY(-50%)' // 수직 중앙 정렬
              }}
            >
              <AiFillCloseCircle />
            </span>

          )}
          {formData.isPasswordEmpty && (
            <span className="text-danger">
              비밀번호 입력이 필요합니다.
            </span>
          )}
        </Form.Group>


        <Row className="justify-content-between align-items-center"></Row>

        <Form.Group>
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
          >
            로그인
          </Button>
        </Form.Group>

        {/* <Divider className="mt-4">or log in with</Divider>

        <SocialAuthButtons /> */}
      </Form >

      {/* Modal for messages */}
      < Modal show={showModal} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>알림</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal >

      <FirstChangePasswordModal
        show={showPasswordModal}
        onHide={() => setShowPasswordModal(false)}
        message={PwMessage}
        id={formData.id}
      />

    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

export default LoginForm;
