import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { Card } from 'react-bootstrap';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEchart from 'components/common/ReactEchart';
import { fetchKeyTypeComp } from 'hooks/CallApi';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const EncOptCompare = () => {
  const { getThemeColor } = useAppContext();
  const encryptionChartRef = useRef(null);
  const [apiData, setApiData] = useState({ api_cnt: 0, cf_cnt: 0 });

  const {
    config: { dashboardDate, dashboardDateValue },
  } = useAppContext();

  useEffect(() => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const fetchData = async () => {
      try {
        const response = await fetchKeyTypeComp({ period: dashboardDateValue, timeZone: clientTimeZone });
        const data = response.data[0];
        setApiData({ api_cnt: data.api_cnt, cf_cnt: data.cf_cnt });
      } catch (error) {
        console.error('데이터 조회 에러:', error);
      }
    };
    fetchData();
  }, []);

  const isDataEmpty = apiData.api_cnt === 0 && apiData.cf_cnt === 0;

  const getOption = (titleText, apiValue, cloudValue, apiColor, cloudColor) => {
    return {
      legend: {
        orient: 'horizontal',
        bottom: 0,
        left: 'center',
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          color: getThemeColor('gray-600'),
          fontSize: 12
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '70%'],
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 3,
            borderColor: getThemeColor('gray-100'),
            borderWidth: 2
          },
          label: {
            show: true,
            position: 'center',
            fontSize: 10,
            fontWeight: 'bold',
            color: getThemeColor('gray-700'),
            formatter: `{b|${titleText}}\n{c|${(apiValue + cloudValue).toLocaleString()}건}`,
            rich: {
              b: {
                fontSize: 14,
                lineHeight: 12,
                color: getThemeColor('gray-700')
              },
              c: {
                fontSize: 14,
                lineHeight: 16,
                fontWeight: 'bold',
                color: getThemeColor('gray-900')
              }
            }
          },
          labelLine: { show: false },
          data: isDataEmpty
            ? [
              { value: 1, itemStyle: { color: '#f0f0f0' } },

            ]
            : [
              {
                value: apiValue,
                name: 'API',
                itemStyle: {
                  color: apiColor
                }
              },
              {
                value: cloudValue,
                name: 'CloudFunctions',
                itemStyle: {
                  color: cloudColor
                }
              }
            ]
        }
      ],
      tooltip: isDataEmpty
        ? { show: false } // 빈 데이터일 때는 툴팁 비활성화
        : {
          trigger: 'item',
          padding: [7, 10],
          backgroundColor: getThemeColor('gray-100'),
          borderColor: getThemeColor('gray-300'),
          textStyle: { color: getThemeColor('gray-1100') },
          borderWidth: 1,
          transitionDuration: 0,
          axisPointer: { type: 'none' }
        }
    };
  };

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>인증키 타입별 요청 비교</h5>
        <span
          style={{
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3',
            color: 'white',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
            whiteSpace: 'nowrap'
          }}
        >
          {dashboardDate}
        </span>
      </Card.Header>

      <Card.Body as={Flex} className="pt-0" alignItems="center" justifyContent="space-between">
        <ReactEchart
          echarts={echarts}
          option={getOption(
            '암호화 옵션',
            apiData.api_cnt,
            apiData.cf_cnt,
            '#FFC0CB', // API 색상
            '#FFA500' // CloudFunctions 색상
          )}
          ref={encryptionChartRef}
          style={{ width: '100%', height: '15rem' }}
        />
      </Card.Body>
    </Card>
  );
};

EncOptCompare.propTypes = {
  totalRequests: PropTypes.number,
  encryptionValue: PropTypes.number,
  decryptionValue: PropTypes.number
};

export default EncOptCompare;
