import React, { useState } from 'react';
import { Form, Button, Card, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { AiFillCloseCircle } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import CheckOutModal from 'components/modal/CheckOutModal';
import paths from 'routes/paths';
import "safedb/common/css/IniCustomCss.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const addencryptPublicKey = () => {
    const navigate = useNavigate();
    const {
        config: { checkorg },
    } = useAppContext();

    const [modalShow, setModalShow] = useState(false);
    const [modalConfirmShow, setModalConfirmShow] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const [algorithm, setAlgorithm] = useState("ARIA");
    const [keyLength, setKeyLength] = useState("128");
    const [modeOptions, setModeOptions] = useState(["OFB", "ECB", "CFB", "CBC"]);
    const [activeButton, setActiveButton] = useState("HEXA");
    const [isToggleOn, setIsToggleOn] = useState(false); // Toggle 상태를 관리
    const [keyError, setKeyError] = useState('');
    const [ivError, setIvError] = useState('');


    const [formData, setFormData] = useState({
        publickeyName: '',
        algorithm: '',
        keylength: '',
        mode: '',
        description: '',
    });

    const handleDeleteClick = () => setModalShow(true);

    const handleCheckOutModalConfirm = () => {
        setModalShow(false);
        navigate(-1);
    };

    const handleConfirmClick = () => setModalConfirmShow(true);

    const handleConfirm = () => {
        setModalConfirmShow(false);
        // 등록처리
        navigate(-1);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setIsModified(true);
    };

    const handleComboChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setIsModified(true);

        if (name === "algorithm") {
            setAlgorithm(value);
            setKeyLength("128");
            updateModeOptions(value, "128");
        } else if (name === "keylength") {
            setKeyLength(value);
            updateModeOptions(algorithm, value);
        }
    };

    const updateModeOptions = (algorithm, keyLength) => {
        const modes = algorithm === "AES" ? ["OFB", "ECB", "CBC"] : ["OFB", "ECB", "CFB", "CBC"];
        setModeOptions(modes);
    };


    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };

    const handleToggleChange = () => {
        setIsToggleOn(!isToggleOn);
    };
    const validateAlphanumeric = (str, length) => {
        const regex = new RegExp(`^[0-9A-Za-z]{${length}}$`);
        return regex.test(str);
    };

    const validateHexadecimal = (str, length) => {
        const regex = new RegExp(`^[0-9A-Fa-f]{${length}}$`);
        return regex.test(str);
    };
    const handleKeyChange = (e) => {
        const value = e.target.value;
        setFormData((prevData) => ({ ...prevData, key: value }));

        if (activeButton === "HEXA") {
            if (!validateHexadecimal(value, 16) && !validateHexadecimal(value, 32)) {
                setKeyError("선택하신 키 길이에 맞는 문자열 " + formData.keylength + "자리 16진수 값을 입력해 주세요 (0-9, A-F)");
            } else {
                setKeyError("");
            }
        } else if (activeButton === "문자열") {
            if (!validateAlphanumeric(value, 16) && !validateAlphanumeric(value, 32)) {
                setKeyError("선택하신 키 길이에 맞는 문자열 " + formData.keylength + "자리 숫자와 문자 조합의 값을 입력해 주세요");
            } else {
                setKeyError("");
            }
        }
    };

    const handleIvChange = (e) => {
        const value = e.target.value;
        setFormData((prevData) => ({ ...prevData, iv: value }));

        if (activeButton === "HEXA") {
            if (!validateHexadecimal(value, 16)) {
                setIvError("16진수 형식의 문자열 16자리를 입력해주세요");
            } else {
                setIvError("");
            }
        } else if (activeButton === "문자열") {
            if (!validateAlphanumeric(value, 16)) {
                setIvError("숫자와 문자 조합의 문자열 16자리를 입력해주세요");
            } else {
                setIvError("");
            }
        }
    };

    const isSubmitEnabled = () => {
        const baseConditionsMet = formData.publickeyName && formData.algorithm && formData.keylength && formData.mode;
        const additionalConditionsMet = isToggleOn ? (!keyError && !ivError && formData.key && formData.iv) : true;
        return baseConditionsMet && additionalConditionsMet;
    };

    return (
        <div className="container mt-5">
            <Card className="mypage-card">
                <Link to={paths.encryptPublicKey} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Card.Header className="px-3" as="h4">암호화 공유키 관리</Card.Header>
                </Link>
                <Card.Title className="border border-bottom-0 px-3 pt-3">등록</Card.Title>

                <Card.Body className="border border-top-0">
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>공유키명 <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="publickeyName"
                                        value={formData.publickeyName}
                                        onChange={handleInputChange}
                                        className="input-hover"

                                    />
                                    {formData.publickeyName && (
                                        <span
                                            onClick={() => setFormData((prevData) => ({ ...prevData, publickeyName: '' }))}
                                            style={{
                                                cursor: 'pointer',
                                                position: 'absolute',
                                                right: '52%',
                                                top: '25%',
                                                zIndex: 9
                                            }}
                                        >
                                            <AiFillCloseCircle />
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>알고리즘  <span className='text-danger'>*</span></Form.Label>
                                    <Form.Select
                                        name="algorithm"
                                        onChange={handleComboChange}
                                        value={formData.algorithm}
                                        className="input-hover"

                                    >
                                        <option disabled value="">선택</option>
                                        <option value="ARIA">ARIA</option>
                                        <option value="SEED">SEED</option>
                                        <option value="AES">AES</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>키 길이(bit)  <span className='text-danger'>*</span></Form.Label>
                                    <Form.Select
                                        name="keylength"
                                        onChange={handleComboChange}
                                        value={formData.keylength}
                                        className="input-hover"

                                    >
                                        <option disabled value="">선택</option>
                                        <option value="128">128</option>
                                        <option value="256">256</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>운영모드  <span className='text-danger'>*</span></Form.Label>
                                    <Form.Select
                                        name="mode"
                                        onChange={handleComboChange}
                                        value={formData.mode}
                                        className="input-hover"

                                    >
                                        <option disabled value="">선택</option>
                                        {modeOptions.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <div>
                                <span>주입키(DIK) 설정</span>
                                <OverlayTrigger
                                    placement="top" // 툴팁 위치 (top, bottom, left, right)
                                    overlay={
                                        <Tooltip id="tooltip-info">
                                            주입키를 설정하면 향상된 보안성과 유연한 키 관리가 가능합니다.<br />주입키를 설정하지 않으면 기본 키 형식이 사용됩니다. 보안 강화를 위해 주입키 설정을 권장드립니다

                                        </Tooltip>
                                    }
                                >
                                    <span className="info-icon mx-2" style={{ cursor: 'pointer' }}>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex fs-10">
                                <span className='my-2 mx-3'>사용 안함</span>
                                <Form.Check
                                    type="switch"
                                    id="dik-toggle-switch"
                                    className="my-2 mx-1"
                                    style={{ transform: 'scale(1.5)' }}
                                    checked={isToggleOn}
                                    onChange={handleToggleChange}
                                />
                                <span className='m-2'>주입키 설정</span>
                            </div>
                        </div>

                        {/* Toggle 상태에 따라 배경색과 Form 컨트롤 활성화 상태 변경 */}
                        <div className={`dik p-3 border rounded ${isToggleOn ? '' : 'bg-400'}`}>
                            <Row>
                                <Col md={2}>
                                    <div className="d-flex align-items-center mb-3">
                                        <Button
                                            variant={activeButton === "HEXA" ? "primary" : "outline-secondary"}
                                            className="me-2"
                                            style={{ width: '7rem' }}
                                            active={activeButton === "HEXA"}
                                            onClick={() => handleButtonClick("HEXA")}
                                            disabled={!isToggleOn}
                                        >
                                            HEXA
                                        </Button>
                                        <Button
                                            variant={activeButton === "문자열" ? "primary" : "outline-secondary"}
                                            style={{ width: '7rem' }}
                                            active={activeButton === "문자열"}
                                            onClick={() => handleButtonClick("문자열")}
                                            disabled={!isToggleOn}
                                        >
                                            문자열
                                        </Button>
                                    </div>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>KEY <span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={isToggleOn ? "16진수 형식의 문자열 " + formData.keylength + "자리를 입력해주세요" : ""}
                                            disabled={!isToggleOn}
                                            value={formData.key}
                                            onChange={handleKeyChange}
                                        />
                                        {keyError && <span className="text-danger">{keyError}</span>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}></Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>IV (Initial Vector) <span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={isToggleOn ? "16진수 형식의 문자열 16자리를 입력해주세요" : ""}
                                            disabled={!isToggleOn}
                                            value={formData.iv}
                                            onChange={handleIvChange}
                                        />
                                        {ivError && <span className="text-danger">{ivError}</span>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>

                        <Form.Group className="mb-3" style={{ position: 'relative' }}>
                            <Form.Label>설명</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                rows={2}
                                value={formData.description}
                                onChange={handleInputChange}
                                className="input-hover"
                            />
                            {formData.description && (
                                <span
                                    onClick={() => setFormData((prevData) => ({ ...prevData, description: '' }))}
                                    style={{
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        zIndex: 9
                                    }}
                                >
                                    <AiFillCloseCircle />
                                </span>
                            )}
                        </Form.Group>
                        <div className="d-flex justify-content-end my-3">
                            <Button className="mx-2" variant="outline-danger" style={{ width: '7rem' }} onClick={handleDeleteClick}>
                                취소
                            </Button>
                            <Button variant="primary" style={{ width: '7rem' }} disabled={!isSubmitEnabled()} onClick={handleConfirmClick}>
                                등록
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <CheckOutModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onConfirm={handleCheckOutModalConfirm}
                title="취소"
                body="취소하시겠습니까?"
                // cancelText="취소"
                confirmText="취소"
                variant="warning"
                SubmitButton={true}
            />

            <CheckOutModal
                show={modalConfirmShow}
                onHide={() => setModalConfirmShow(false)}
                onConfirm={handleConfirm}
                title="확인"
                body="등록하시겠습니까?"
                cancelText="취소"
                confirmText="등록"
                variant="info"
                CancelButton={true}
                SubmitButton={true}
            />
        </div>
    );
};

export default addencryptPublicKey;
