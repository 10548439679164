const generateData = (count) => {
    const data = [];

    for (let i = 1; i <= count; i++) {
        data.push({
            num: `${i}`,
            reqdate: `2024-${String((12 - ((i - 1) % 12))).padStart(2, '0')}-10`, // 12개월 주기로 생성 날짜
            resdate: `2024-${String((12 - ((i - 1) % 12))).padStart(2, '0')}-15`, // 가입 날짜도 12개월 주기
            policyid: `1c3e3623-d311-23ca-${i}`,
            algorithm: "ARIA",
            kind: i % 2 === 0 ? '암호화' : '복호화', //
            reqcount: "10,000",
            succ_rescount: "10,000",
            fail_rescount: "0",
            targetid: "app"

        });
    }

    return data;
};

const data = generateData(400); // 데이터 40개 생성

export default data;

