import { Form } from 'react-bootstrap';

const PolicyFormSelect = ({ title, option, value, onChange }) => {
  return (
    <div style={{ marginTop: '1rem' }}>
      <span>{title}</span>
      <Form.Select size="sm" value={value} onChange={onChange}>
        {option.map((item, index) => (
          <option key={index} value={item}>{item}</option>
        ))}
      </Form.Select>
    </div>
  );
};

export default PolicyFormSelect;
