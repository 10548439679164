import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { version } from 'config';


const Footer = () => (

  <footer className="footer">

    <Row className="justify-content-between text-left fs-10 mt-4 mb-3">
      <Col sm="auto">
        <div style={{ float: 'left' }}>
          <img width='100px' style={{ marginRight: '35px' }} src='/img/initech_logo.svg'></img>


          Copyrightⓒ 2024 INITECH CO., Ltd. All RIGHTS RESERVED{' '}
          <span className="d-none d-sm-inline-block">| </span>
          {/* <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '} */}
          <a href="https://www.initech.com/html/sub/inve/pers_info.html"> 개인정보처리방침</a>

        </div>
      </Col>

      <Col sm="auto">
        <p className="mb-0 text-600">v{version}</p>
      </Col>
    </Row>

  </footer >

);

export default Footer;
