import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEchart from 'components/common/ReactEchart';
import { useAppContext } from 'providers/AppProvider';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const dataSets = {

  barData: [
    { name: '고객사 A', process: 30, min: 10, max: 50 },
    { name: '고객사 B', process: 60, min: 20, max: 100 },
    { name: '고객사 C', process: 300, min: 50, max: 550 },
    { name: '고객사 D', process: 600, min: 100, max: 1100 },
    { name: '고객사 E', process: 1200, min: 200, max: 2200 }
  ]


};

export default function PolicyCharts() {
  const { getThemeColor } = useAppContext();

  const barChartRef = useRef(null);

  const getBarOption = () => ({

    color: [getThemeColor('primary'), getThemeColor('info')],
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        const customerName = params[0].name; // 고객사 이름
        const averageProcess = params[0].value; // 처리량 평균
        const minProcess = dataSets.barData[params[0].dataIndex].min; // 최소 처리량
        const maxProcess = dataSets.barData[params[0].dataIndex].max; // 최대 처리량

        return `${customerName}<br/>요청량 평균: ${averageProcess} (건/s)<br/>최소 요청량: ${minProcess} (건/s)<br/>최대 요청량: ${maxProcess} (건/s)<br/>`;
      },
      axisPointer: { type: 'shadow' }
    },
    legend: {
      data: ['요청량 평균', '최소 요청량', '최대 요청량'], // 라벨 수정
      top: 10,
      textStyle: { color: getThemeColor('gray-600') }
    },
    xAxis: {
      type: 'value',
      axisLabel: { color: getThemeColor('gray-500') },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: getThemeColor('gray-200')
        }
      }
    },
    yAxis: {
      type: 'category',
      data: dataSets.barData.map(d => d.name),
      axisLabel: { color: getThemeColor('gray-500') }
    },
    series: [
      {
        name: '요청량 평균', // 시리즈 이름 수정
        type: 'bar',
        data: dataSets.barData.map(d => d.process),
        itemStyle: { borderRadius: [0, 3, 3, 0] }
      }
    ],
    grid: {
      left: '3%',
      right: '10%',
      bottom: '5%',
      top: '20%',
      containLabel: true
    }
  });



  const {
    config: { dashboardDate },
    setConfig
  } = useAppContext();

  return (
    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0, flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>평균 API 요청량 상위</h5>
        < span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
            flexWrap: 'nowrap', // 한 줄로 표시
            whiteSpace: 'nowrap', // 한 줄로 유지
          }}
        >
          {dashboardDate}

        </span>
      </Card.Header>
      <Card.Body>
        <ReactEchart
          ref={barChartRef}
          echarts={echarts}
          option={getBarOption()}
          style={{ magin: '2rem', width: '100%', height: '20rem' }} // 크기 조정
        />
      </Card.Body>

    </Card>
  );
}
