import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import "safedb/common/css/IniCustomCss.css";

export const AdvanceTablePagination = () => {
  const {
    previousPage,
    nextPage,
    getCanNextPage,
    getCanPreviousPage,
    getState,
    getPageCount,
    setPageIndex
  } = useAdvanceTableContext();

  const {
    pagination: { pageIndex }
  } = getState();

  const totalPages = getPageCount();

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pagesPerGroup = 5; // 한 그룹에 표시할 페이지 수
    let startPage, endPage;

    // 선택한 페이지에 따라 시작 페이지와 끝 페이지 계산
    if (pageIndex + 1 <= 1) {
      startPage = 1;
      endPage = Math.min(pagesPerGroup, totalPages);
    } else if (pageIndex + 1 > totalPages - 4) {
      startPage = totalPages - 4;
      if (startPage < 1) startPage = 1;
      endPage = totalPages;
    } else {
      // 중간 페이지인 경우
      startPage = pageIndex + 1;
      endPage = pageIndex + 5;
    }

    if (startPage > 1) {
      pageNumbers.push(
        <li key="dots-left" className="me-2">...</li>
      );
    }

    // 페이지 번호 렌더링
    for (let i = startPage; i <= endPage; i++) {
      if (i <= totalPages) {
        pageNumbers.push(
          <li key={i} className={classNames({ active: pageIndex + 1 === i })}>
            <Button
              size="sm"
              variant="light"

              className={classNames('page', {
                'me-2': i < totalPages
              })}
              onClick={() => setPageIndex(i - 1)}
            >

              {i}
            </Button>
          </li >
        );
      }
    }

    // '...' 표시 추가 (끝 부분)
    if (endPage < totalPages) {
      pageNumbers.push(
        <li key="dots-right" className="me-2">...</li>
      );
    }

    return pageNumbers;
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => previousPage()}
        className={classNames({ disabled: !getCanPreviousPage() })}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button>
      <ul className="pagination mb-0 mx-2" style={{ minWidth: '10px' }}>
        {renderPageNumbers()}
      </ul>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => nextPage()}
        className={classNames({ disabled: !getCanNextPage() })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
    </Flex>
  );
};

export default AdvanceTablePagination;
