
/* eslint-disable react/prop-types */
import React from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel
} from '@tanstack/react-table';
import IndeterminateCheckbox from 'components/common/advance-table/IndeterminateCheckbox';

const selectionColumn = (selectionColumnWidth, selectionHeaderClassname) => {

  return {
    id: 'selection',
    accessorKey: '',
    header: ({ table }) => (
      <IndeterminateCheckbox
        className="form-check mb-0"
        {...{
          checked: table
            .getPaginationRowModel()
            .rows.every(
              row => row.getIsSelected() || row.original.status === '만료'
            )
          ,
          indeterminate:
            table
              .getPaginationRowModel()
              .rows.some(row => row.getIsSelected()) &&
            !table
              .getPaginationRowModel()
              .rows.every(
                row => row.getIsSelected() || row.original.status === '만료'
              ),
          onChange: table.getTogglePageRowsSelectedHandler // 현재 페이지의 행만 선택하거나 선택 해제
        }}
      />
    ),
    cell: ({ row }) => (
      <IndeterminateCheckbox
        className="form-check mb-0"
        {...{
          checked: row.getIsSelected(),
          disabled: row.original.status === '만료', // '만료' 상태에서는 체크박스 비활성화
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler()
        }}
      />
    ),
    meta: {
      headerProps: {
        className: selectionHeaderClassname,
        style: {
          width: selectionColumnWidth
        }
      },
      cellProps: {
        style: {
          width: selectionColumnWidth
        }
      }
    }
  };
};

const useAdvanceTable = ({
  columns,
  data,
  sortable,
  selection,
  selectionColumnWidth,
  selectionHeaderClassname,
  pagination,
  initialState,
  perPage = 10
}) => {
  const state = {
    pagination: { pageSize: pagination ? perPage : data.length },
    ...initialState
  };

  const table = useReactTable({
    data,
    columns: selection
      ? [
        selectionColumn(selectionColumnWidth, selectionHeaderClassname),
        ...columns
      ]
      : columns,
    enableSorting: sortable,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      ...state,
      rowSelection: data.reduce((acc, item, index) => {
        // active 필드가 true인 경우에만 초기 체크
        if (item.active) {
          acc[index] = true;
        }

        return acc;
      }, {})

    }
  });

  // 현재 페이지의 모든 행을 선택하거나 선택 해제하는 핸들러 (만료 상태 제외)
  table.getTogglePageRowsSelectedHandler = () => {
    const currentPageRows = table.getPaginationRowModel().rows;
    const isAllSelected = currentPageRows.every(
      // row => row.getIsSelected())
      row => row.getIsSelected() || row.original.status === '만료'
    );

    currentPageRows.forEach(row => {
      if (row.original.status !== '만료') { // '정상' 상태인 행만 선택/해제
        row.toggleSelected(!isAllSelected);
      }
    });
    // currentPageRows.forEach(row => {
    //   if (row.original.status !== '만료') {
    //     row.toggleSelected(!isAllSelected);
    //   }
    // });
  };

  return table

};

export default useAdvanceTable;
