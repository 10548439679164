// mypage.js
import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Row, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'; // React Dropzone import
import Avatar from 'components/common/Avatar';
import avatarImg from 'assets/img/team/avatar.png';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import ChangePasswordModal from './changePasswordModal';
import AdminPasswordAlertModal from 'components/modal/CheckOutModal';
import CheckOutModal from 'components/modal/CheckOutModal';
import { toast } from 'react-toastify'; // 추가
import { updateAdminInfo, getAdminInfo } from 'hooks/CallApi';

const MyPage = ({ setValue, handleClose, onHide }) => {
  const {
    config: {
      userAuth,
      userId,
      userName,
      userUuid
    }
  } = useAppContext();
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);

  const [showPasswordModal, setShowPasswordModal] = useState(false); // 비밀번호 변경 모달 상태 추가
  const handleClosePasswordModal = () => setShowPasswordModal(false);
  const handleShowPasswordModal = () => setShowPasswordModal(true);

  const [isChanged, setIsChanged] = useState(false);


  const handleCheckOutModal = () => {
    handleClose(); // 창을 닫는 함수 호출
    setModalShow(false); // 관리자 모달 닫기
  };
  const handleCancelClick = () => {
    if (isChanged) {
      setModalShow(true);
    } else {
      setModalShow(false); // 변경 사항이 없을 때 창 닫기
      handleClose();
    }
  };

  const [formData, setFormData] = useState({
    id: userId,
    password: '********',
    name: userName,
    description: '',
  });
  const { user } = useAuthWizardContext();

  const [avatar, setAvatar] = useState([
    ...(user.avatar ? user.avatar : []),
    { src: avatarImg }
  ]);


  const [initialData, setInitialData] = useState({ ...formData });

  const [NameError, setNameError] = useState('');
  const [DescError, setDescError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'name' && value.length >= 30) {
      setNameError(`이름은 최대 30자까지 입력 가능합니다.`);
    } else {
      setNameError('');
    }

    if (name === 'description' && value.length >= 800) {
      setDescError(`설명은 최대 800자까지 입력 가능합니다.`);
    } else {
      setDescError('');
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const dataChanged =
      formData.name !== initialData.name ||
      formData.description !== initialData.description

    setIsChanged(dataChanged);
  }, [formData, initialData]);


  useEffect(() => {
    const fetchAdminInfo = async () => {
      try {
        const adminInfo = await getAdminInfo();
        if (adminInfo && adminInfo.data) {
          const { userId, username, userDescription } = adminInfo.data;
          const updatedData = {
            id: userId,
            name: username,
            description: userDescription,
            password: '********',
          };
          setFormData(updatedData);
          setInitialData(updatedData);
        }
      } catch (error) {
        toast.error('계정 정보를 불러오는데 실패했습니다.');
      }
    };

    fetchAdminInfo();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmModalShow(true);
  };
  const handleConfirmCheck = async () => {
    // 업데이트 처리
    try {
      const response = await updateAdminInfo(userUuid, {
        // imgUrl: formData.imgUrl,
        username: formData.name,
        active: true,
        userDescription: formData.description,
      });
      console.log("API 응답:", response);
      if (response) {
        toast.success("정상 등록되었습니다.");
        setConfirmModalShow(false);
      } else {
        toast.success("매니저 정보 업데이트가 실패했습니다."); // Toast Notification 추가
      }
    } catch (error) {
      console.error('매니저 정보 업데이트 에러:', error);
      toast.error("매니저 정보 업데이트 중 오류가 발생했습니다.");
    }
  }


  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const imageFile = acceptedFiles[0];
      const imageUrl = URL.createObjectURL(imageFile);
      setAvatar([{ src: imageUrl }]);
      setValue('avatar', imageFile);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpeg', '.jpg', '.png', '.gif'] }, // 이미지 파일만 허용
  });

  return (
    <div className="container mt-5">
      <Card className="mypage-card">

        <Card.Body>
          <Form>
            <Row>
              {/* Left Column (Profile Image) */}
              <Col md={4} className="text-center profile-section">
                <div {...getRootProps({ className: 'dropzone' })} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', border: '2px dashed #ccc', borderRadius: '8px' }}>
                  <input {...getInputProps()} />
                  <Flex justifyContent="center" >
                    <img
                      src={avatar.length > 0 ? avatar[0].src : cloudUpload}
                      alt=""
                      style={{
                        borderRadius: '50%', // 동그란 형태
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        objectFit: 'cover', // 이미지 비율 유지

                      }}
                    />
                  </Flex>
                  {/* <p>여기에 이미지를 드래그하거나 클릭하여 업로드하세요.</p> */}
                </div>

              </Col>

              {/* Right Column (ID, Password, Name) */}
              <Col md={8}>
                {/* ID */}
                <Form.Group className="mb-3">
                  <Form.Label>아이디</Form.Label>
                  <Form.Control
                    type="text"
                    name="id"
                    value={formData.id}
                    onChange={handleChange}
                    readOnly
                    disabled
                    className="input-field"
                  />
                </Form.Group>

                {/* Password */}
                <Form.Group className="mb-3">
                  <Form.Label>비밀번호</Form.Label>
                  <InputGroup>
                    <FormControl
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      readOnly
                      disabled
                      className="input-field"
                    />
                    {(userAuth === 'SA' || userAuth === 'ADMIN') && (
                      <Button variant="outline-secondary" onClick={handleShowPasswordModal}>변경</Button>
                    )}
                  </InputGroup>
                </Form.Group>

                {/* Name */}
                <Form.Group className="mb-3">
                  <Form.Label>이름</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="input-field"
                    maxLength='30'
                  />
                  {NameError &&
                    <div style={{ color: 'red' }}>
                      {NameError}
                    </div>} {/* 에러 메시지 표시 */}
                </Form.Group>
              </Col>
            </Row>

            {/* Description (Below Both Columns) */}
            <Row className="mt-4">
              <Col>
                <Form.Group>
                  <Form.Label>설명</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    rows={8}
                    value={formData.description}
                    onChange={handleChange}
                    className="input-field"
                    maxLength='800'
                  />
                  {DescError &&
                    <div style={{ color: 'red' }}>
                      {DescError}
                    </div>} {/* 에러 메시지 표시 */}
                </Form.Group>
              </Col>
            </Row>

            {/* Cancel and Confirm Buttons */}
            <div className="d-flex justify-content-end mt-3">
              <Button variant="secondary" className="me-2" onClick={handleCancelClick}>
                취소
              </Button>
              {isChanged ? (
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={NameError || DescError}
                >
                  변경
                </Button>
              ) : (
                <Button variant="primary" onClick={handleClose}>
                  확인
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleCheckOutModal}
        title="관리자 확인" // 제목
        variant="warning"
        body="창을 닫으면 수정하신 내용은 삭제됩니다. 창을 닫으시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
        onConfirm={handleConfirmCheck}
        title="확인" // 제목
        body="변경하신 사항으로 계정 정보를 수정하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="info"
        CancelButton={true}
        SubmitButton={true}
      />

      <ChangePasswordModal
        show={showPasswordModal}
        onHide={handleClosePasswordModal}
      />

    </div>


  );
};

export default MyPage;
