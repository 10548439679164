import React from 'react';
import { Button, Form, Modal, InputGroup, FormControl } from 'react-bootstrap';

import IconAlert from 'components/common/IconAlert';

const ChangePasswordSuccessModal = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">완료</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {['success'].map(variant => (
          <IconAlert key={variant} variant={variant}>
            비밀번호 변경이 완료되었습니다.
          </IconAlert>
        ))}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordSuccessModal;
