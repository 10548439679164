import React, { useState, useEffect } from 'react';
import { Card, Button, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import CheckOutModal from 'components/modal/CheckOutModal';
import paths from 'routes/paths';
import { fetchAdminList } from 'hooks/CallApi';

const columns = [
  { accessorKey: 'userRole', header: '역할', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'companyid', header: '소속', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'userId', header: '아이디', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'username', header: '이름', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'active', header: '상태', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'createdAt', header: '등록일시', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'updateAt', header: '최근접속일시', meta: { headerProps: { className: 'text-900' } } }
];

function AdvanceTableExample() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]); // 데이터 상태
  const [modalShow, setModalShow] = useState(false);
  const [modalReShow, setModalReShow] = useState(false);
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가

  const pageSize = 10; // 페이지당 항목 수 설정

  // 1. 데이터 로딩 (useEffect로 전체 데이터 로드)
  useEffect(() => {
    const loadAllData = async () => {
      const startTime = performance.now();
      let currentPage = 0;
      let allData = [];

      // 300ms 후에 스피너를 표시하는 타이머 설정
      const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

      try {
        const initialData = await fetchAdminList(0, pageSize);
        const totalPages = initialData.data.page.totalPages;

        for (currentPage = 0; currentPage < totalPages; currentPage++) {
          const pageData = await fetchAdminList(currentPage, pageSize);
          const content = pageData.data.content.map((item, index) => ({
            ...item,
            num: currentPage * pageSize + index + 1,
            userId: item.userId,
            username: item.username,
            userRole: item.userRole,
            // userIds: item.userIds,
            userUuid: item.userIds.userUuid,
            companyid: item.userIds.companyid,
            active: item.active ? '정상' : '비정상',
            activestatus: item.active,
            description: item.userDescription,
            companyName: item.companyName,
            imgUrl: item.imgUrl,
            createdAt: new Date(item.insertTimeUtc).toLocaleString('ko-KR', {
              year: 'numeric', month: '2-digit', day: '2-digit',
              hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
            }),
            updateAt: new Date(item.loginTimeUtc).toLocaleString('ko-KR', {
              year: 'numeric', month: '2-digit', day: '2-digit',
              hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
            })
          }));

          allData = [...allData, ...content];
        }
        console.log("allData : ", allData);
        setTableData(allData);
      } catch (error) {
        console.error('데이터 불러오기 에러:', error);
      } finally {
        clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
        setLoading(false);
        setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
      }
    };
    loadAllData();
  }, [pageSize]);


  // 2. 테이블 설정
  const table = useAdvanceTable({
    data: tableData,
    columns,
    sortable: true,
    selection: true,
    pagination: true,
    perPage: pageSize,
    selectionColumnWidth: 30
  });

  // 3. 행 클릭 이벤트
  const handleRowClick = row => {
    const { userId, username, userRole, userUuid, companyid, activestatus, companyName, createdAt, description, updateAt } = row.original;
    navigate(`/safedb/SA/adminAccount/edit`, {
      state: { createdAt, updateAt, activestatus, userUuid, userId, username, companyName, userRole, description, companyid } // 전달할 데이터
    });
    // navigate(`/safedb/SA/adminAccount/edit?id=${userId}&name=${username}&role=${userRole}&org=${userIds.companyid}&status=${active}`);
  };

  const handleDeleteClick = () => {
    setModalShow(true);
  };

  const selectedRowCount = table.getSelectedRowModel().rows.length;

  const handleDelete = () => {
    setModalShow(false);
    setModalReShow(true);
  };

  const handleDeleteRe = () => {
    const selectedRowIds = table.getSelectedRowModel().rows.map(row => row.original.userId);
    const newData = tableData.filter(row => !selectedRowIds.includes(row.userId));
    setTableData(newData);
    table.resetRowSelection();
    setModalReShow(false);
  };

  return (
    <AdvanceTableProvider {...table}>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }} className="mb-2">

        <AdvanceTableFooter rowsPerPageSelection />
        {selectedRowCount > 0 ? (
          <Button
            variant="outline-danger"
            style={{ position: 'absolute', right: 10, top: -8, width: '100px' }}
            onClick={handleDeleteClick}
          >
            삭제
          </Button>
        ) : (
          <Link to={paths.addadminAccount} style={{ textDecoration: 'none' }}>
            <Button
              variant="outline-success"
              style={{ position: 'absolute', right: 10, top: -8, width: '100px' }}
            >
              등록
            </Button>
          </Link>
        )}
      </div>
      {loading ? (
        showSpinner ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <p></p>
        )
      ) : (
        <AdvanceTable
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{ striped: true, className: 'fs-10 mb-0 overflow-hidden' }}
          onRowClick={handleRowClick}
        />
      )}
      <div className="mt-3">
        <p style={{ margin: 0, fontWeight: 700 }}>총 {tableData.length}건</p>
        <AdvanceTablePagination />
      </div>

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleDelete}
        title="경고"
        body="선택하신 관리자에 대한 모든 설정값 및 권한 정보가 삭제됩니다. 이 작업은 취소할 수 없으며, 삭제된 데이터는 복구할 수 없습니다. 정말 삭제하시겠습니까?"
        cancelText="취소"
        confirmText="삭제"
        variant="warning"
        CancelButton={true}
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalReShow}
        onHide={() => setModalReShow(false)}
        onConfirm={handleDeleteRe}
        title="완료"
        body="삭제 되었습니다."
        confirmText="확인"
        variant="success"
        SubmitButton={true}
      />
    </AdvanceTableProvider>
  );
}

const AdminAccount = () => {
  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header as="h4">관리자 계정</Card.Header>
        <Card.Body>
          <AdvanceTableExample />
        </Card.Body>
      </Card>
    </div>
  );
};

export default AdminAccount;
