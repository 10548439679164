import { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import PolicyFormSelect from './PolicyFormSelect';
import PolicyFormSwitch from './PolicyFormSwitch';
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import CheckOutModal from 'components/modal/CheckOutModal';

const PolicyManager = () => {
  const minLength = [9, 10, 11, 12, 13, 14, 15];
  const includeEnglish = ['3개', '4개', '5개'];
  const specialCharacter = ['1개', '2개', '3개'];
  const includeNumber = ['1개', '2개', '3개'];
  const maxLength = [16, 17, 18, 19, 20, 21, 22];
  const expirationPeriod = ['미사용', '30일', '60일', '90일', '120일', '150일', '180일', '365일'];
  const passwordInputLimit = ['2회', '3회', '4회', '5회'];
  const inactivityTimeLimit = ['10분', '20분', '30분', '60분'];
  const accountLockTime = ['5분', '10분', '15분', '20분', '30분', '60분'];

  // 선택 필드 상태
  const [selectedMinLength, setSelectedMinLength] = useState(minLength[0]);
  const [selectedIncludeEnglish, setSelectedIncludeEnglish] = useState(includeEnglish[0]);
  const [selectedSpecialCharacter, setSelectedSpecialCharacter] = useState(specialCharacter[0]);
  const [selectedIncludeNumber, setSelectedIncludeNumber] = useState(includeNumber[0]);
  const [selectedMaxLength, setSelectedMaxLength] = useState(maxLength[0]);
  const [selectedExpirationPeriod, setSelectedExpirationPeriod] = useState(expirationPeriod[0]);
  const [selectedPasswordInputLimit, setSelectedPasswordInputLimit] = useState(passwordInputLimit[0]);
  const [selectedInactivityTimeLimit, setSelectedInactivityTimeLimit] = useState(inactivityTimeLimit[0]);
  const [selectedAccountLockTime, setSelectedAccountLockTime] = useState(accountLockTime[0]);

  const [isPasswordPart, setIsPasswordPart] = useState(true);
  const [isAccessPart, setIsAccessPart] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [showDefaultModal, setshowDefaultModal] = useState(false);

  const [showChangeConfirmModal, setShowChangeConfirmModal] = useState(false);
  const [showChangeCompleteModal, setShowChangeCompleteModal] = useState(false);

  const handleShowChangeConfirmModal = () => {
    setShowChangeConfirmModal(true);
  };

  const handleConfirmChange = () => {
    setShowChangeConfirmModal(false);
    setShowChangeCompleteModal(true);
  };
  const handleCloseCompleteModal = () => {
    setShowChangeCompleteModal(false);
  };


  const handleDefaultModal = () => {
    setshowDefaultModal(true);
  };

  const handleSelectChange = () => {
    setIsChanged(true);
  };

  // 초기화 핸들러: 모든 필드 초기화
  const handleDefault = () => {
    setshowDefaultModal(false); // 모달 닫기
    setIsChanged(false); // 변경 상태 초기화

    // 모든 선택 필드를 기본값으로 초기화
    setSelectedMinLength(minLength[0]);
    setSelectedIncludeEnglish(includeEnglish[0]);
    setSelectedSpecialCharacter(specialCharacter[0]);
    setSelectedIncludeNumber(includeNumber[0]);
    setSelectedMaxLength(maxLength[0]);
    setSelectedExpirationPeriod(expirationPeriod[0]);
    setSelectedPasswordInputLimit(passwordInputLimit[0]);
    setSelectedInactivityTimeLimit(inactivityTimeLimit[0]);
    setSelectedAccountLockTime(accountLockTime[0]);
  };

  return (
    <Card className="mypage-card">
      <Card.Header className="px-3" as="h4">관리 정책</Card.Header>
      <Card.Body>
        <div>
          <div className='bg-500 text-light' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <span>비밀번호</span>
            <button
              style={{ border: 'none', backgroundColor: 'transparent' }}
              onClick={() => setIsPasswordPart(!isPasswordPart)}
            >
              {isPasswordPart ? <BsChevronUp /> : <BsChevronDown />}
            </button>
          </div>
          {isPasswordPart && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ flexGrow: 1 }} className='mb-4'>
                <PolicyFormSelect
                  title='최소 길이'
                  option={minLength}
                  value={selectedMinLength}
                  onChange={(e) => { setSelectedMinLength(e.target.value); handleSelectChange(); }}
                />
                <PolicyFormSelect
                  title='영문자 포함 갯수'
                  option={includeEnglish}
                  value={selectedIncludeEnglish}
                  onChange={(e) => { setSelectedIncludeEnglish(e.target.value); handleSelectChange(); }}
                />
                <PolicyFormSelect
                  title='특수문자 포함 갯수'
                  option={specialCharacter}
                  value={selectedSpecialCharacter}
                  onChange={(e) => { setSelectedSpecialCharacter(e.target.value); handleSelectChange(); }}
                />
              </div>
              <div style={{ width: '10rem', flexShrink: 0 }}></div>
              <div style={{ flexGrow: 1 }} className='mb-4'>
                <PolicyFormSelect
                  title='최대 길이'
                  option={maxLength}
                  value={selectedMaxLength}
                  onChange={(e) => { setSelectedMaxLength(e.target.value); handleSelectChange(); }}
                />
                <PolicyFormSelect
                  title='숫자 포함 갯수'
                  option={includeNumber}
                  value={selectedIncludeNumber}
                  onChange={(e) => { setSelectedIncludeNumber(e.target.value); handleSelectChange(); }}
                />
                <PolicyFormSelect
                  title='유효기간'
                  option={expirationPeriod}
                  value={selectedExpirationPeriod}
                  onChange={(e) => { setSelectedExpirationPeriod(e.target.value); handleSelectChange(); }}
                />
              </div>
            </div>
          )}
        </div>

        {/* 관리페이지 접속 */}
        <div style={{ visibility: 'hidden' }}>
          <div style={{ width: '100%', backgroundColor: 'lightgray', display: 'flex', justifyContent: 'space-between' }}>
            <span>관리페이지 접속</span>
            <button
              style={{ border: 'none', backgroundColor: 'transparent' }}
              onClick={() => setIsAccessPart(!isAccessPart)}
            >
              {isAccessPart ? <BsChevronUp /> : <BsChevronDown />}
            </button>
          </div>
          {isAccessPart && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ flexGrow: 1 }}>
                <PolicyFormSelect
                  title='비밀번호 입력 제한'
                  option={passwordInputLimit}
                  value={selectedPasswordInputLimit}
                  onChange={(e) => { setSelectedPasswordInputLimit(e.target.value); handleSelectChange(); }}
                />
                <PolicyFormSelect
                  title='비활동 제한시간'
                  option={inactivityTimeLimit}
                  value={selectedInactivityTimeLimit}
                  onChange={(e) => { setSelectedInactivityTimeLimit(e.target.value); handleSelectChange(); }}
                />
                <PolicyFormSwitch
                  title='로그인 정보 출력'
                  off='미출력'
                  on='출력'
                />
                <PolicyFormSwitch
                  title='접속 시간대 제한'
                  off='제한없음'
                  on='제한 시간대'
                >
                  <PolicyFormSelect
                    option={inactivityTimeLimit}
                    value={selectedInactivityTimeLimit}
                    onChange={(e) => { setSelectedInactivityTimeLimit(e.target.value); handleSelectChange(); }}
                  />
                </PolicyFormSwitch>
              </div>
              <div style={{ width: '10rem', flexShrink: 0 }}></div>
              <div style={{ flexGrow: 1 }}>
                <PolicyFormSelect
                  title='비밀번호 오류 시 계정 잠금 시간'
                  option={accountLockTime}
                  value={selectedAccountLockTime}
                  onChange={(e) => { setSelectedAccountLockTime(e.target.value); handleSelectChange(); }}
                />
                <PolicyFormSwitch
                  title='관리자 세션 제한'
                  off='제한없음'
                  on='단일 세션만 허용'
                />
                <PolicyFormSwitch
                  title='2차 인증'
                  off='미사용'
                  on='OTP 사용'
                />
              </div>
            </div>
          )}
        </div>

        {/* 버튼 영역 */}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button className='mx-2' style={{ width: '7rem' }} onClick={handleDefaultModal}>초기화</Button>
          <Button style={{ width: '7rem' }} onClick={handleShowChangeConfirmModal} disabled={!isChanged}>변경</Button>
        </div>
        <CheckOutModal
          show={showDefaultModal}
          onHide={() => setshowDefaultModal(false)}
          onConfirm={handleDefault}
          title="확인"
          body="관리 정책 설정 정보를 초기화하시겠습니까?"
          cancelText="취소"
          confirmText="확인"
          variant="info"
          CancelButton={true}
          SubmitButton={true}
        />

        <CheckOutModal
          show={showChangeConfirmModal}
          onHide={() => setShowChangeConfirmModal(false)}
          onConfirm={handleConfirmChange}
          title="확인"
          body="관리 정책 설정 정보를 변경하시겠습니까?"
          cancelText="취소"
          confirmText="확인"
          variant="info"
          CancelButton={true}
          SubmitButton={true}
        />

        <CheckOutModal
          show={showChangeCompleteModal}
          onHide={handleCloseCompleteModal}
          onConfirm={handleCloseCompleteModal}
          title="완료"
          body="변경이 완료 되었습니다."
          confirmText="확인"
          variant="success"
          CancelButton={false}
          SubmitButton={true}
        />

      </Card.Body>
    </Card>

  );
};

export default PolicyManager;
