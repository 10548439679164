import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { useAppContext } from 'providers/AppProvider';
import { Card } from 'react-bootstrap';
const SiteIdleRate = () => {
  useEffect(() => {
    const idlePolicyChart = echarts.init(document.getElementById('idlePolicyChart'));

    function generateIdlePolicyData() {
      const data = [
        { name: '고객사 A', idle: 30, active: 70 },
        { name: '고객사 B', idle: 20, active: 80 },
        { name: '고객사 C', idle: 25, active: 75 },
        { name: '고객사 D', idle: 15, active: 85 },
        { name: '고객사 E', idle: 40, active: 60 }
      ];

      data.forEach((item) => {
        item.idleRatio = ((item.idle / (item.idle + item.active)) * 100);
      });

      data.sort((a, b) => a.idleRatio - b.idleRatio);
      return data;
    }

    const idlePolicyData = generateIdlePolicyData();
    const customerNames = idlePolicyData.map((item) => item.name);
    const idleRatios = idlePolicyData.map((item) => item.idleRatio);
    const activeRatios = idlePolicyData.map((item) => 100 - item.idleRatio);

    const colorPalette = ['#5cb85c', '#FFA500', '#ff0000', '#0000FF', '#800080'];

    const option = {
      title: {
        left: 'center',
        top: '5%',
        textStyle: {
          fontSize: 20,
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: function (params) {
          let tooltipText = `${params[0].name}<br/>`;
          params.forEach((param) => {
            tooltipText += `${param.marker} ${param.seriesName}: ${param.value} %<br/>`;
          });
          return tooltipText;
        },
      },
      legend: {
        data: ['유휴 정책', '활성 정책'],
        bottom: '5%',
        textStyle: {
          fontSize: 14,
        },
      },
      grid: {
        top: '20%',
        left: '10%',
        right: '10%',
        bottom: '15%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        name: '비율 (%)',
        min: 0,
        max: 100,
        axisLabel: {
          formatter: '{value} %',
        },
        splitLine: {
          show: true,
        },
      },
      yAxis: {
        type: 'category',
        data: customerNames,
        axisLabel: {
          fontSize: 14,
        },
      },
      series: [
        {
          name: '유휴 정책',
          type: 'bar',
          stack: 'total',
          data: idleRatios,
          itemStyle: {
            color: 'lightgrey',
          },
          label: {
            show: true,
            position: 'insideRight',
            formatter: '{c} %',
            fontSize: 12,
            color: '#fff',
          },
        },
        {
          name: '활성 정책',
          type: 'bar',
          stack: 'total',
          data: activeRatios,
          itemStyle: {
            color: colorPalette[4],
          },
          label: {
            show: true,
            position: 'insideLeft',
            formatter: '{c} %',
            fontSize: 12,
            color: '#fff',
          },
        },
      ],
    };

    idlePolicyChart.setOption(option);

    const handleResize = () => {
      idlePolicyChart.resize();
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      idlePolicyChart.dispose();
    };
  }, []);

  return (


    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>고객사별 정책 중 유휴율</h5>
      </Card.Header>
      <Card.Body>
        <div id="idlePolicyChart" style={{ width: '100%', height: '20rem' }}></div>

      </Card.Body>

    </Card>


  );
};


export default SiteIdleRate;
