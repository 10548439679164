const limitStringLength = (str, maxLength) => {
    return str.length > maxLength ? str.slice(0, maxLength) : str;
};


const generateData = (count) => {
    const data = [];

    for (let i = 1; i <= count; i++) {
        data.push({
            num: `${i}`,
            jobdate: limitStringLength(`2024-${String((12 - ((i - 1) % 12))).padStart(2, '0')}-10`, 19), // 12개월 주기로 생성 날짜
            org: limitStringLength(i < 3 ? '이니텍' : 'kakao', 15),
            role: limitStringLength(i < 3 ? '시스템 관리자' : '정책 관리자', 7),
            uid: limitStringLength(i < 3 ? 'Admin_initech' : 'manager_kakao', 20),
            job: limitStringLength(i < 3 ? '로그인' : '사용자 등록', 20),
            result: "성공"


        });
    }

    return data;
};

const data = generateData(400); // 데이터 40개 생성

export default data;

