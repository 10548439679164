import { useState } from "react";
import { FadeLoader } from "react-spinners"
import styled from "styled-components";

const LoadingWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
`;

export const Loading = () => {
    return (
        <>
            <LoadingWrap>
                <FadeLoader
                    width={10}
                    height={30}
                    margin={20}
                    radius={5}
                    color="#ACACAC"
                />
            </LoadingWrap>
        </>
    )
}