import React, { useRef, useState, useEffect } from 'react';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { Card, Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import team1 from 'assets/img/team/1.jpg';
import team2 from 'assets/img/team/2.jpg';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import styled from 'styled-components';
const SiteActiveRank = () => {

  const {
    config: { dashboardDate },
    setConfig
  } = useAppContext();

  const [view, setView] = useState('CF'); // 'top' or 'bottom'
  return (

    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>고객사 활동 순위</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          {dashboardDate}
        </span>
      </Card.Header>
      <Card.Body alignItems="center" justifyContent="space-between">

        <ButtonGroup
          className="mb-3"
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px', // or left: '10px' to align with the left side
            zIndex: 10
          }}
        >
          <Button
            variant={view === 'CF' ? 'primary' : 'outline-primary'}
            onClick={() => setView('CF')}
          >
            CF
          </Button>
          <Button
            variant={view === 'API' ? 'primary' : 'outline-primary'}
            onClick={() => setView('API')}
          >
            API
          </Button>
        </ButtonGroup>
        <RateTop3Section>
          <GridSection>
            동시 최대 요청량
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team1} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 1</strong>
                  <p className="mb-1">요청량 수치</p>
                </div>
              </Flex>
            </Cell>
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team2} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 2</strong>
                  <p className="mb-1">요청량 수치</p>
                </div>
              </Flex>

            </Cell>
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team3} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 3</strong>
                  <p className="mb-1">요청량 수치</p>
                </div>
              </Flex>
            </Cell>
          </GridSection>
          <GridSection>
            전체 처리량
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team1} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 1</strong>
                  <p className="mb-1">처리량</p>
                </div>
              </Flex>
            </Cell>
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team2} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 2</strong>
                  <p className="mb-1">처리량</p>
                </div>
              </Flex>

            </Cell>
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team3} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 3</strong>
                  <p className="mb-1">처리량</p>
                </div>
              </Flex>
            </Cell>
          </GridSection>
          <GridSection>
            평균 처리 시간
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team1} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 1</strong>
                  <p className="mb-1">평균 시간</p>
                </div>
              </Flex>
            </Cell>
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team2} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 2</strong>
                  <p className="mb-1">평균 시간</p>
                </div>
              </Flex>

            </Cell>
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team3} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 3</strong>
                  <p className="mb-1">평균 시간</p>
                </div>
              </Flex>
            </Cell>
          </GridSection>
        </RateTop3Section>


      </Card.Body >
    </Card >

  );
};
const RateTop3Section = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3개의 열 생성 */
  gap: 10px; /* 셀 간 간격 설정 */
  width: 100%;
`;

const GridSection = styled.div`
  display: grid;
  //grid-template-rows: repeat(3, 1fr); /* 세로로 3개 행 생성 */
   gap: 3px; /* 행 간의 간격 설정 */
   font-size: 14px;
  //  display: flex;
  //flex-direction: column;
  //align-items: flex-start;
`;

const Cell = styled.div`
  text-align: left;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
`;
export default SiteActiveRank;
