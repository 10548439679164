import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import IconAlert from 'components/common/IconAlert';

const MainTenancePasswordModal = ({ show, onHide, onConfirm }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">확인</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {['warning'].map(variant => (
          <IconAlert key={variant} variant={variant}>
            비밀번호를 저장하지 않고 N 일 간 이전 암호를 유지하시겠습니까?
          </IconAlert>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="outline-secondary" className="me-2">
          취소
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          확인
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MainTenancePasswordModal;
