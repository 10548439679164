import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { Card } from 'react-bootstrap';
import * as echarts from 'echarts/core';
import { TitleComponent, TooltipComponent, GridComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEchart from 'components/common/ReactEchart';
import { fetchAlgoComp } from 'hooks/CallApi';

echarts.use([TitleComponent, TooltipComponent, GridComponent, PieChart, CanvasRenderer]);

const AlgorithmCompare = () => {
  const { getThemeColor } = useAppContext();
  const encryptionChartRef = useRef(null);
  const {
    config: { dashboardDate, dashboardDateValue },
  } = useAppContext();

  const [chartData, setChartData] = useState({
    totalCnt: 0,
    aesCnt: 0,
    ariaCnt: 0,
    seedCnt: 0,
  });

  useEffect(() => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const fetchData = async () => {
      try {
        const data = await fetchAlgoComp({ period: dashboardDateValue, timeZone: clientTimeZone });
        const responseData = data.data[0] || {};
        setChartData({
          totalCnt: responseData.total_cnt || 0,
          aesCnt: responseData.aes_total_cnt || 0,
          ariaCnt: responseData.aria_total_cnt || 0,
          seedCnt: responseData.seed_total_cnt || 0,
        });
        console.log(responseData);
      } catch (error) {
        console.error('Error fetching algorithm comparison data:', error);
      }
    };

    fetchData();
  }, []);

  const isDataEmpty = chartData.totalCnt === 0;

  const getOption = (titleText, aesValue, ariaValue, seedValue, aesColor, ariaColor, seedColor) => ({
    legend: {
      orient: 'horizontal',
      bottom: 0,
      left: 'center',
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        color: getThemeColor('gray-600'),
        fontSize: 12,
      },
      data: ['AES', 'ARIA', 'SEED'], // Always show legend items
    },
    series: [
      {
        type: 'pie',
        radius: ['50%', '70%'],
        center: ['50%', '45%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 3,
          borderColor: getThemeColor('gray-100'),
          borderWidth: 2,
        },
        label: {
          show: true,
          position: 'center',
          fontSize: 10,
          fontWeight: 'bold',
          color: getThemeColor('gray-700'),
          formatter: isDataEmpty ? '' : `{b|${titleText}}\n{c|${(aesValue + ariaValue + seedValue).toLocaleString()}건}`,
          rich: {
            b: {
              fontSize: 14,
              lineHeight: 12,
              color: getThemeColor('gray-700'),
            },
            c: {
              fontSize: 14,
              lineHeight: 16,
              fontWeight: 'bold',
              color: getThemeColor('gray-900'),
            },
          },
        },
        labelLine: { show: false },
        data: isDataEmpty
          ? [
            { value: 1, itemStyle: { color: '#f0f0f0' } },

          ]
          : [
            { value: aesValue, name: 'AES', itemStyle: { color: aesColor } },
            { value: ariaValue, name: 'ARIA', itemStyle: { color: ariaColor } },
            { value: seedValue, name: 'SEED', itemStyle: { color: seedColor } },
          ],
      },
    ],
    tooltip: isDataEmpty
      ? { show: false } // 빈 데이터일 때는 툴팁 비활성화
      : {
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getThemeColor('gray-100'),
        borderColor: getThemeColor('gray-300'),
        textStyle: { color: getThemeColor('gray-1100') },
        borderWidth: 1,
        transitionDuration: 0,
        axisPointer: { type: 'none' },
      },
  });

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>알고리즘 요청 비교</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          }}
        >
          {dashboardDate}
        </span>
      </Card.Header>
      <Card.Body as={Flex} className="pt-0" alignItems="center" justifyContent="space-between">
        <ReactEchart
          echarts={echarts}
          option={getOption(
            '알고리즘 요청 수',
            chartData.aesCnt,
            chartData.ariaCnt,
            chartData.seedCnt,
            '#FFFEAC',
            '#FA8072',
            '#00FF00'
          )}
          ref={encryptionChartRef}
          style={{ width: '100%', height: '15rem' }}
        />
      </Card.Body>
    </Card>
  );
};

AlgorithmCompare.propTypes = {
  totalRequests: PropTypes.number,
};

export default AlgorithmCompare;
