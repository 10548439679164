import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import paths from 'routes/paths';

const Starter = () => {
  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col lg={6}>
            <img src={editing} className="img-fluid" alt="" />
          </Col>
          <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
            <h3 className="text-primary">화면 준비중입니다.</h3>
            <p className="lead">화면이 아직 완료되지 않았습니다. 곧 개발 예정입니다.</p>
            {/* <Button
              variant="falcon-primary"
              as={Link}
              to={paths.gettingStarted}
            >
              Getting started
            </Button> */}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Starter;
