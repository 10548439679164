import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';

import bgShape from 'assets/img/illustrations/bg-shape.png';
import shape1 from 'assets/img/illustrations/shape-1.png';
import halfCircle from 'assets/img/illustrations/half-circle.png';

const AuthCardLayout = ({ leftSideContent, children, footer = true }) => {
  return (
    <Section fluid className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={8} xxl={5} className="py-3 position-relative" >
          <Card className="overflow-hidden z-1" style={{ height: '25rem' }}>
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                <Col md={5} className="text-dark">
                  <div className="position-relative p-4 ">
                    <h2> Login</h2>
                  </div>
                  <div
                    className="px-md3 text-center "
                    data-bs-theme="light"

                    style={{ height: '12rem', alignContent: 'center' }}
                  >
                    {/* {leftSideContent} */}
                    <img src='/img/INISAFE_SAFEDB.png'></img>
                    {/* {footer && (
                      <div
                        className="z-1 position-relative"
                        data-bs-theme="light"
                      >
                        <Link
                          className="link-light mb-4 font-sans-serif fw-bolder fs-5 d-inline-block"
                          to="/"
                        >

                        </Link>

                      </div>
                    )} */}
                  </div>
                </Col>
                <Col
                  md={7}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                >
                  <div className="p-3 p-md-4 flex-grow-1">{children}</div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};
AuthCardLayout.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool
};

export default AuthCardLayout;
