import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Row, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'; // React Dropzone import 
import avatarImg from 'assets/img/team/avatar.png';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Flex from 'components/common/Flex';
import { ToastContainer, toast } from 'react-toastify'; // 추가
import "react-toastify/dist/ReactToastify.css"; // 추가
import "safedb/common/css/IniCustomCss.css"
import CheckOutModal from 'components/modal/CheckOutModal';
import { AiFillCloseCircle } from 'react-icons/ai';
import { handleValueDelete, goToParentPath } from 'components/common/SunnyUtils';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useAppContext } from 'providers/AppProvider';


const SiteInfo = ({ setValue }) => {

  const [modalConfirmShow, setModalConfirmShow] = useState(false);
  const [modalConfirmCheckShow, setModalConfirmCheckShow] = useState(false);
  const [isChevronUp, setIsChevronUp] = useState(false); // 상태 변수 추가
  const toggleKeyPart = () => {
    setKeyPart(!KeyPart);
    setIsChevronUp(!isChevronUp); // 아이콘 상태 전환
  };
  const [KeyPart, setKeyPart] = useState(true);
  const [NameError, setNameError] = useState('');
  const [NameBlankError, setNameBlankError] = useState(false);
  const [DescError, setDescError] = useState('');

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  const {
    config: { checkorg, userAuth },
    setConfig
  } = useAppContext();

  // 내용 변경 시 변경 버튼 눌렀을때
  const handleSubmitClick = () => {
    setModalConfirmShow(true);
  };

  const handleConfirm = () => {
    setModalConfirmShow(false);
    setModalConfirmCheckShow(true);
    // DB에 사용자 변경처리 추가 필요
    toast.success("정상 등록되었습니다."); // Toast Notification 추가
  };
  const navigate = useNavigate(); // useNavigate 훅 사용
  const handleDeleteRe = () => {
    navigate(`/safedb/SA/adminSites`); // 데이터와 함께 이동

  };

  const initialFormData = {
    createdAt: '',//queryParams.get('createdAt'),
    clientName: '',//queryParams.get('clientName'),
    clientEmail: '',//queryParams.get('clientEmail'),
    clientPhone: '',//queryParams.get('clientPhone'),
    clientAddress: '',//queryParams.get('clientAddress'),
    description: '',
    clientSecret: '',
    clientKey: '',
    clientIV: ''
  };


  const [formData, setFormData] = useState({
    createdAt: '',//queryParams.get('createdAt'),
    clientName: checkorg,
    clientEmail: '샘플고객사 이메일',//queryParams.get('clientEmail'),
    clientPhone: '샘플고객사 번호',//queryParams.get('clientPhone'),
    clientAddress: '샘플고객사 주소',//queryParams.get('clientAddress'),
    description: '',
    clientSecret: '',
    clientKey: '',
    clientIV: ''
  });
  const { user } = useAuthWizardContext();

  const [avatar, setAvatar] = useState([
    ...(user.avatar ? user.avatar : []),
    { src: avatarImg }
  ]);


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'clientName' && value.length === 0) {
      setNameError(`고객사명을 입력해주세요.`);
    }

    else if (name === 'clientName' && value.length >= 15) {
      setNameError(`고객사명은 최대 15자까지 입력 가능합니다.`);
    } else {
      setNameError('');
    }

    if (name === 'description' && value.length >= 800) {
      setDescError(`고객사 설명란 입력은 최대 800자까지 입력 가능합니다.`);
    } else {
      setDescError('');
    }


    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleComboChange = ({ target }) => {
    let name = target.name;
    let value = name === 'allDay' ? target.checked : target.value;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const imageFile = acceptedFiles[0];
      const imageUrl = URL.createObjectURL(imageFile);
      setAvatar([{ src: imageUrl }]);
      setValue('avatar', imageFile);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpeg', '.jpg', '.png', '.gif'] }, // 이미지 파일만 허용
  });

  const isFormValid = () => {
    // clientName 값이 비어있으면 false
    if (!formData.clientName) return false;

    // initialFormData와 formData가 동일한 경우 false (변경 사항 없음)
    return Object.keys(formData).some((key) => formData[key] !== initialFormData[key]);
  };
  return (
    <div className="container mt-5">
      <Card className="mypage-card" >
        <Link to={goToParentPath()} style={{ textDecoration: 'none', color: 'inherit', }}>
          <Card.Header className="px-3" as="h4">고객사 관리</Card.Header>
        </Link>

        <Card.Title className="border border-bottom-0 px-3 pt-3">  고객사 상세 정보</Card.Title>
        <Card.Body className="border border-top-0" >
          <Form onSubmit={handleSubmit}>
            <Row>

              {/* Left Column (Profile Image) */}
              <Col md={4} className="text-center profile-section">
                <div {...getRootProps({ className: 'dropzone' })}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '150px',
                    height: '150px',
                    position: 'relative',
                    margin: '0 auto',
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <input {...getInputProps()} />
                  <Flex justifyContent="center" >
                    <img
                      src={avatar.length > 0 ? avatar[0].src : cloudUpload}
                      alt=""
                      style={{
                        borderRadius: '50%', // 동그란 형태
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        objectFit: 'cover', // 이미지 비율 유지

                      }}
                    />
                    {isHovered && ( // hover 시 텍스트 표시
                      <div style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(0, 0, 0, 1)',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '50%', // 동그란 형태
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        pointerEvents: 'none', // 클릭 방지
                        objectFit: 'cover', // 이미지 비율 유지
                        display: 'flex', // Flexbox 사용
                        justifyContent: 'center', // 수평 중앙 정렬
                        alignItems: 'center', // 수직 중앙 정렬
                      }}>
                        이미지 변경
                      </div>
                    )}
                  </Flex>
                  {/* <p>여기에 이미지를 드래그하거나 클릭하여 업로드하세요.</p> */}
                </div>
                {formData.clientName.length > 0 ? (
                  <p className="mt-3">[{formData.clientName}]</p>
                ) : (
                  <p className="mt-3">&nbsp;</p>
                )}

              </Col>

              {/* Right Column (ID, Password, Name) */}
              <Col md={8}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>고객사명<span className='text-danger'>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="clientName"
                        value={formData.clientName}
                        onChange={handleChange}
                        disabled={userAuth === 'manager'}
                        className="input-hover"
                        maxLength='15'
                      // placeholder="아이디를 입력해주세요."
                      />
                      {formData.clientName && userAuth !== 'manager' && (
                        <span
                          onClick={(e) => {
                            handleValueDelete('clientName', formData, setFormData);
                            handleChange({ target: { name: 'clientName', value: '' } }); // handleChange를 직접 호출
                          }} style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                    {NameError &&
                      <span className='text-danger'>
                        {NameError}
                      </span>} {/* 에러 메시지 표시 */}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>고객사 연락처</Form.Label>
                      <InputGroup>
                        <FormControl
                          type="phone"
                          name="clientPhone"
                          value={formData.clientPhone}
                          onChange={handleChange}
                          className="input-hover"
                          disabled={userAuth === 'manager'}
                          maxLength='20'
                        // placeholder="비밀번호를 입력해주세요."
                        />

                      </InputGroup>
                      {formData.clientPhone && userAuth !== 'manager' && (
                        <span
                          onClick={(e) => handleValueDelete('clientPhone', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>고객사 메일</Form.Label>
                      <Form.Control
                        type="text"
                        name="clientEmail"
                        value={formData.clientEmail}
                        onChange={handleChange}
                        className="input-hover"
                        disabled={userAuth === 'manager'}
                      // placeholder="이름을 입력해주세요."
                      />

                      {formData.clientEmail && userAuth !== 'manager' && (
                        <span
                          onClick={(e) => handleValueDelete('clientEmail', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>

                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>고객사 주소</Form.Label>
                      <Form.Control
                        type="text"
                        name="clientAddress"
                        value={formData.clientAddress}
                        onChange={handleChange}
                        className="input-hover"
                        disabled={userAuth === 'manager'}
                      // placeholder="이름을 입력해주세요."
                      />

                      {formData.clientAddress && userAuth !== 'manager' && (
                        <span
                          onClick={(e) => handleValueDelete('clientAddress', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>등록 일시</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        name="addDate"
                        value={formData.createdAt}
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>수정 일시</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        name="editDate"
                        value="DB 값 가져와야함."
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div style={{ display: 'none' }}>
                  < div style={{ width: '100%', backgroundColor: 'lightgray', display: 'flex', justifyContent: 'space-between' }}>
                    <span><h5>[{formData.clientName}] KEY 관리</h5></span>
                    <button
                      style={{ border: 'none', backgroundColor: 'transparent' }}
                      onClick={toggleKeyPart} // 상태 변경 함수 호출
                    >
                      {isChevronUp ? <BsChevronDown /> : <BsChevronUp />} {/* 아이콘 상태에 따라 표시 */}
                    </button>
                  </div>
                  {KeyPart && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', border: '2px', borderStyle: 'dashed' }} className='p-2'>
                      <div style={{ flexGrow: 1 }} className='mb-4'>
                        <Row className="align-items-center">
                          <Col md={9}>
                            <Form.Group className="mb-3" style={{ position: 'relative' }}>
                              <Form.Label>[{formData.clientName}] SECRET</Form.Label>
                              <Form.Control
                                type="text"
                                name="clientSecret"
                                value={formData.clientSecret}
                                onChange={handleChange}
                                readOnly
                              />

                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-3" >
                              <Form.Label style={{ opacity: 0 }}>버튼</Form.Label>
                              <Button variant="secondary " className="w-100" >
                                SECRET 재발급
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col md={9}>
                            <Form.Group className="mb-3" style={{ position: 'relative' }}>
                              <Form.Label>암호용 KEY</Form.Label>
                              <Form.Control
                                type="text"
                                name="clientKey"
                                value={formData.clientKey}
                                onChange={handleChange}
                                readOnly
                              />

                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-3" style={{ position: 'relative' }}>
                              <Form.Label style={{ opacity: 0 }}>버튼</Form.Label>
                              <Button variant="secondary " className="w-100 p-0" style={{ height: 36, fontSize: 16, }} >
                                암호용KEY/Ⅳ 재발급
                              </Button>

                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={9}>
                            <Form.Group className="mb-3" style={{ position: 'relative' }}>
                              <Form.Label>암호용 Ⅳ</Form.Label>
                              <Form.Control
                                type="text"
                                name="clientIV"
                                value={formData.clientIV}
                                onChange={handleChange}
                                readOnly
                              />

                            </Form.Group>
                          </Col>

                        </Row>
                      </div>
                    </div>
                  )}
                </div>

                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                  <Form.Label>설명</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    rows={3}
                    value={formData.description}
                    onChange={handleChange}
                    className="input-hover"
                    maxLength='800'
                    disabled={userAuth === 'manager'}
                  />
                  {DescError &&
                    <div style={{ color: 'red' }}>
                      {DescError}
                    </div>} {/* 에러 메시지 표시 */}
                  {formData.description && userAuth !== 'manager' && (
                    <span
                      onClick={(e) => handleValueDelete('description', formData, setFormData)}
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'transformY(-50%)',
                        zIndex: 9
                      }}
                    >
                      <AiFillCloseCircle />
                    </span>
                  )}
                </Form.Group>

              </Col>




            </Row>

            {/* 키영역 복구 시 pt 빼도됨*/}
            <div className="d-flex justify-content-end mt-3 pt-8">
              <Button variant="secondary" className='mx-3' style={{ width: '8rem' }}>
                관리 키 설정
              </Button>
              <Button variant="primary" type="submit" onClick={handleSubmitClick} disabled={!isFormValid() || userAuth === 'manager'} style={{ width: '8rem' }}>
                변경
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card >



      <CheckOutModal
        show={modalConfirmShow}
        onHide={() => setModalConfirmShow(false)}
        onConfirm={handleConfirm}
        title="확인" // 제목
        body="변경하신 사항으로 계정 정보를 수정하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalConfirmCheckShow}
        onHide={() => setModalConfirmCheckShow(false)}
        variant="success"
        onConfirm={handleConfirm}
        title="확인" // 제목
        body="변경하신 사항이 해당 계정 정보에 저장되었습니다." // 본문 내용
        SubmitButton={false}
      />
    </div >


  );
};

export default SiteInfo;
