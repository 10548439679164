export const bgWhiteIcons = [

  { icon: ['fas', 'home'], color: 'black', bg: 'white', href: 'https://www.initech.com/html/index.html' },
  // { icon: ['fab', 'youtube'], color: 'youtube', bg: 'white', href: 'https://www.youtube.com/@initech1705' },

];
export default [
  { icon: ['fab', 'facebook-f'], color: 'facebook', href: '#!' },
  { icon: ['fab', 'twitter'], color: 'twitter', href: '#!' },
  {
    icon: ['fab', 'google-plus-g'],
    color: 'google-plus',
    href: '#!'
  },
  { icon: ['fab', 'linkedin-in'], color: 'linkedin', href: '#!' },
  { icon: ['fab', 'medium-m'], color: 'gray-700', href: '#!' }
];
