import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { Card } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';

const customers = ['고객사 A', '고객사 B', '고객사 C', '고객사 D', '고객사 E'];
const processingTimeThreshold = 0.4;
const colorPalette = ['#5cb85c', '#B0B57E', '#F6C6B1', '#800080'];

function generateSampleData() {
  const apiDataDaily = [];
  var cfDataDaily = [];
  for (let i = 0; i < 24; i++) {
    const hour = `${i.toString().padStart(2, '0')}시`;
    const isBusinessHour = i >= 9 && i <= 19;
    apiDataDaily.push({
      time: hour,
      customers: [
        { name: '고객사 A', processingTime: isBusinessHour ? 0.35 + Math.random() * 0.05 : 0.25 + Math.random() * 0.05, requestSize: isBusinessHour ? 70 + Math.random() * 10 : 40 + Math.random() * 10, failCount: Math.random() > 0.9 ? 1 : 0 },
        { name: '고객사 B', processingTime: isBusinessHour ? 0.30 + Math.random() * 0.05 : 0.20 + Math.random() * 0.05, requestSize: isBusinessHour ? 95 + Math.random() * 5 : 50 + Math.random() * 10, failCount: Math.random() > 0.85 ? 1 : 0 },
        { name: '고객사 C', processingTime: isBusinessHour ? 0.35 + Math.random() * 0.05 : 0.25 + Math.random() * 0.05, requestSize: isBusinessHour ? 85 + Math.random() * 10 : 45 + Math.random() * 5, failCount: Math.random() > 0.88 ? 1 : 0 },
        { name: '고객사 D', processingTime: isBusinessHour ? 0.40 + Math.random() * 0.05 : 0.30 + Math.random() * 0.05, requestSize: isBusinessHour ? 100 + Math.random() * 20 : 60 + Math.random() * 10, failCount: Math.random() > 0.8 ? 1 : 0 },
        { name: '고객사 E', processingTime: isBusinessHour ? 0.25 + Math.random() * 0.05 : 0.15 + Math.random() * 0.05, requestSize: isBusinessHour ? 60 + Math.random() * 10 : 30 + Math.random() * 5, failCount: Math.random() > 0.95 ? 1 : 0 }
      ]
    });

    // CF 요청 데이터 (콜드 스타트 포함)
    var cfColdStart = false;
    if (isBusinessHour && (i === 9 || i === 18)) { // 업무 시작 전후에 콜드 스타트 발생
      cfColdStart = true;
    }

    cfDataDaily.push({
      time: hour,
      customers: [
        { name: '고객사 A', processingTime: isBusinessHour ? 0.25 + Math.random() * 0.05 : 0.15 + Math.random() * 0.05, requestSize: isBusinessHour ? 50 + Math.random() * 10 : 20 + Math.random() * 5, failCount: Math.random() > 0.95 ? 1 : 0, coldStart: cfColdStart && Math.random() > 0.5 },
        { name: '고객사 B', processingTime: isBusinessHour ? 0.28 + Math.random() * 0.05 : 0.18 + Math.random() * 0.05, requestSize: isBusinessHour ? 80 + Math.random() * 10 : 30 + Math.random() * 5, failCount: Math.random() > 0.9 ? 1 : 0, coldStart: cfColdStart && Math.random() > 0.5 },
        { name: '고객사 C', processingTime: isBusinessHour ? 0.30 + Math.random() * 0.05 : 0.20 + Math.random() * 0.05, requestSize: isBusinessHour ? 75 + Math.random() * 5 : 25 + Math.random() * 5, failCount: Math.random() > 0.92 ? 1 : 0, coldStart: 0 },
        { name: '고객사 D', processingTime: isBusinessHour ? 0.35 + Math.random() * 0.05 : 0.25 + Math.random() * 0.05, requestSize: isBusinessHour ? 90 + Math.random() * 10 : 35 + Math.random() * 5, failCount: Math.random() > 0.9 ? 1 : 0, coldStart: 0 },
        { name: '고객사 E', processingTime: isBusinessHour ? 0.20 + Math.random() * 0.05 : 0.10 + Math.random() * 0.05, requestSize: isBusinessHour ? 40 + Math.random() * 5 : 15 + Math.random() * 5, failCount: Math.random() > 0.97 ? 1 : 0, coldStart: cfColdStart && Math.random() > 0.5 }
      ]
    });
  }
  // 주별 (일별) 데이터 생성
  var apiDataWeekly = [];
  var cfDataWeekly = [];
  const a = ['일', '월', '화', '수', '목', '금', '토'];
  for (let w of a) {
    // for (var w = 1; w <= 7; w++) {
    apiDataWeekly.push({
      time: w + '요일',
      customers: [
        { name: '고객사 A', processingTime: 0.38 + Math.random() * 0.05, requestSize: 75 + Math.random() * 5, failCount: Math.random() > 0.8 ? 1 : 0 },
        { name: '고객사 B', processingTime: 0.48 + Math.random() * 0.05, requestSize: 105 + Math.random() * 5, failCount: Math.random() > 0.75 ? 1 : 0 },
        { name: '고객사 C', processingTime: 0.53 + Math.random() * 0.05, requestSize: 95 + Math.random() * 5, failCount: Math.random() > 0.85 ? 1 : 0 },
        { name: '고객사 D', processingTime: 0.63 + Math.random() * 0.05, requestSize: 115 + Math.random() * 10, failCount: Math.random() > 0.7 ? 1 : 0 },
        { name: '고객사 E', processingTime: 0.33 + Math.random() * 0.05, requestSize: 68 + Math.random() * 5, failCount: Math.random() > 0.9 ? 1 : 0 }
      ]
    });

    cfDataWeekly.push({
      time: w + '요일',
      customers: [
        { name: '고객사 A', processingTime: 0.28 + Math.random() * 0.05, requestSize: 58 + Math.random() * 5, failCount: Math.random() > 0.85 ? 1 : 0, coldStart: 0 },
        { name: '고객사 B', processingTime: 0.38 + Math.random() * 0.05, requestSize: 90 + Math.random() * 5, failCount: Math.random() > 0.75 ? 1 : 0, coldStart: Math.random() > 0.9 },
        { name: '고객사 C', processingTime: 0.43 + Math.random() * 0.05, requestSize: 85 + Math.random() * 5, failCount: Math.random() > 0.8 ? 1 : 0, coldStart: Math.random() > 0.9 },
        { name: '고객사 D', processingTime: 0.58 + Math.random() * 0.05, requestSize: 100 + Math.random() * 10, failCount: Math.random() > 0.7 ? 1 : 0, coldStart: Math.random() > 0.9 },
        { name: '고객사 E', processingTime: 0.22 + Math.random() * 0.05, requestSize: 48 + Math.random() * 5, failCount: Math.random() > 0.95 ? 1 : 0, coldStart: Math.random() > 0.9 }
      ]
    });
  }

  // 월별 (주별) 데이터 생성
  var apiDataMonthly = [];
  var cfDataMonthly = [];
  for (var m = 1; m <= 4; m++) {
    apiDataMonthly.push({
      time: m + '주차',
      customers: [
        { name: '고객사 A', processingTime: 0.40 + Math.random() * 0.05, requestSize: 80 + Math.random() * 5, failCount: Math.random() > 0.8 ? 1 : 0 },
        { name: '고객사 B', processingTime: 0.50 + Math.random() * 0.05, requestSize: 120 + Math.random() * 5, failCount: Math.random() > 0.75 ? 1 : 0 },
        { name: '고객사 C', processingTime: 0.55 + Math.random() * 0.05, requestSize: 100 + Math.random() * 5, failCount: Math.random() > 0.85 ? 1 : 0 },
        { name: '고객사 D', processingTime: 0.70 + Math.random() * 0.05, requestSize: 130 + Math.random() * 10, failCount: Math.random() > 0.7 ? 1 : 0 },
        { name: '고객사 E', processingTime: 0.38 + Math.random() * 0.05, requestSize: 75 + Math.random() * 5, failCount: Math.random() > 0.9 ? 1 : 0 }
      ]
    });

    cfDataMonthly.push({
      time: m + '주차',
      customers: [
        { name: '고객사 A', processingTime: 0.30 + Math.random() * 0.05, requestSize: 60 + Math.random() * 5, failCount: Math.random() > 0.85 ? 1 : 0, coldStart: Math.random() > 0.9 },
        { name: '고객사 B', processingTime: 0.40 + Math.random() * 0.05, requestSize: 95 + Math.random() * 5, failCount: Math.random() > 0.75 ? 1 : 0, coldStart: Math.random() > 0.9 },
        { name: '고객사 C', processingTime: 0.48 + Math.random() * 0.05, requestSize: 90 + Math.random() * 5, failCount: Math.random() > 0.8 ? 1 : 0, coldStart: Math.random() > 0.9 },
        { name: '고객사 D', processingTime: 0.63 + Math.random() * 0.05, requestSize: 105 + Math.random() * 10, failCount: Math.random() > 0.7 ? 1 : 0, coldStart: Math.random() > 0.9 },
        { name: '고객사 E', processingTime: 0.25 + Math.random() * 0.05, requestSize: 52 + Math.random() * 5, failCount: Math.random() > 0.95 ? 1 : 0, coldStart: Math.random() > 0.9 }
      ]
    });
  }

  return {
    apiDataDaily,
    cfDataDaily,
    apiDataWeekly,
    cfDataWeekly,
    apiDataMonthly,
    cfDataMonthly
  };
}

const SiteAverageApiProcess = () => {
  const [chart, setChart] = useState(null);
  const [timeScale, setTimeScale] = useState('daily');
  const [data, setData] = useState(generateSampleData().apiDataDaily);
  const {
    config: { dashboardDate },
    setConfig
  } = useAppContext();


  useEffect(() => {
    if (!chart) {
      const newChart = echarts.init(document.getElementById('apiChart'));
      setChart(newChart);
      window.addEventListener('resize', () => newChart.resize());
    }
    updateCharts(timeScale);
  }, [chart]);

  useEffect(() => {
    // dashboardDate가 변경될 때마다 차트를 업데이트
    updateCharts(timeScale);
  }, [dashboardDate]);

  const generateSeries = () => {
    return customers.map((customer, index) => ({
      name: customer,
      type: 'line',
      smooth: true,
      data: data.map(entry => entry.customers.find(c => c.name === customer)?.processingTime || null),
      lineStyle: {
        color: colorPalette[index % colorPalette.length]
      },
      markPoint: {
        data: data
          .map((entry, i) => {
            const processingTime = entry.customers.find(c => c.name === customer)?.processingTime || null;
            return processingTime > processingTimeThreshold
              ? { value: processingTime, xAxis: i, yAxis: processingTime }
              : null;
          })
          .filter(Boolean), // 기준선을 초과한 값만 표시
        symbol: 'circle',
        symbolSize: 7,
        itemStyle: {
          color: 'yellow'
        },
        label: {
          show: false // 라벨을 숨겨 노란색 동그라미만 표시
        }
      }
    })).concat({
      name: '지연 기준선',
      type: 'line',
      data: data.map(() => processingTimeThreshold),
      symbol: 'none',
      lineStyle: {
        color: '#0000FF',
        type: 'dashed',
        width: 2
      }
    });
  };

  const createChartOption = () => ({
    tooltip: { trigger: 'axis', valueFormatter: data => `${data.toFixed(2)} 초` },
    legend: { data: customers.concat(['지연 기준선']), bottom: '5%' },
    xAxis: { type: 'category', data: data.map(entry => entry.time) },
    yAxis: { type: 'value', min: 0, axisLabel: { formatter: '{value} 초' } },
    series: generateSeries(),
    grid: {
      left: '5%',
      right: '5%',
      bottom: '25%',
      top: '5%',
      // containLabel: true
    }
  });

  const updateCharts = (newTimeScale) => {
    setTimeScale(newTimeScale);
    const newData = generateSampleData().apiDataDaily; // 시간별 데이터를 선택적으로 불러오는 로직 추가 가능
    setData(newData);
    if (chart) {
      chart.setOption(createChartOption(), true);
    }
  };


  return (
    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>시간별 평균 API 처리속도</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          {dashboardDate}

        </span>
      </Card.Header>
      <Card.Body>
        <div id="apiChart" style={{ width: '100%', height: '20rem' }} />
        {/* <div>
          <div className="dropdown">
            <label htmlFor="timeScale">시간 단위 선택: </label>
            <select id="timeScale" value={timeScale} onChange={(e) => updateCharts(e.target.value)}>
              <option value="daily">일</option>
              <option value="weekly">주</option>
              <option value="monthly">월</option>
            </select>
          </div>
          
        </div> */}
      </Card.Body>

    </Card>
  );
}

export default SiteAverageApiProcess;
