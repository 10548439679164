// import React, { useEffect } from 'react';
// import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { useAppContext } from 'providers/AppProvider';

// const renderTooltip = props => (
//   <Tooltip style={{ position: 'fixed' }} id="button-tooltip" {...props}>
//     Toggle Navigation
//   </Tooltip>
// );

// const ToggleButton = () => {
//   const {
//     config: { isNavbarVerticalCollapsed, isFluid, isRTL, checkorg, navbarPosition },
//     setConfig
//   } = useAppContext();

//   const handleClick = () => {
//     // document
//     //   .getElementsByTagName('html')[0]
//     //   .classList.toggle('navbar-vertical-collapsed');
//     // setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed);

//     if (checkorg) {
//       document
//         .getElementsByTagName('html')[0]
//         .classList.toggle('navbar-vertical-collapsed');
//       setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed);
//     } else {
//       alert('고객사 선택이 선행되어야 합니다!');
//     }

//   };

//   return (
//     <OverlayTrigger
//       placement={
//         isFluid ? (isRTL ? 'bottom' : 'right') : isRTL ? 'bottom' : 'left'
//       }
//     // overlay={renderTooltip}
//     >
//       <div className="toggle-icon-wrapper">
//         <Button
//           variant="link"
//           className="navbar-toggler-humburger-icon navbar-vertical-toggle"
//           id="toggleNavigationTooltip"
//           onClick={handleClick}
//         >
//           <span className="navbar-toggle-icon">
//             <span className="toggle-line" />
//           </span>
//         </Button>
//       </div>
//     </OverlayTrigger>
//   );
// };

// export default ToggleButton;
import React from 'react';
import { Button } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';

const ToggleButton = () => {
  const {
    config: { isNavbarVerticalCollapsed, checkorg },
    setConfig
  } = useAppContext();

  const handleClick = () => {
    console.log(checkorg)
    if (checkorg) {
      document
        .getElementsByTagName('html')[0]
        .classList.toggle('navbar-vertical-collapsed');
      setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed);
    } else {
      // alert('고객사 선택이 선행되어야 합니다!');
    }
  };

  return (
    <div className="toggle-icon-wrapper">
      <Button
        variant="link"
        className="navbar-toggler-humburger-icon navbar-vertical-toggle"
        disabled={!checkorg}
        onClick={handleClick}
      >
        <span className="navbar-toggle-icon">
          <span className="toggle-line" />
        </span>
      </Button>
    </div>
  );
};

export default ToggleButton;
