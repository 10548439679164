import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Korean } from 'flatpickr/dist/l10n/ko.js'; // 한국어 로케일 가져오기
import SummaryChart from './SummaryChart'
import SiteIssueRank from './SiteIssueRank'
import SiteActiveRank from './SiteActiveRank'
import AverageApiRank from './AverageApiRank'
import SiteApiProcessSpeed from './SiteApiProcessSpeed'
import SiteCfProcessSpeed from './SiteCfProcessSpeed'
import SiteAverageApiProcess from './SiteAverageApiProcess'
import AverageCfRank from './AverageCfRank'
import SiteAverageCfProcess from './SiteAverageCfProcess'
import SiteIdleRate from './SiteIdleRate'
import SiteIdleAverage from './SiteIdleAverage'
import AlgorithmCompare from './AlgorithmCompare';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAppContext } from 'providers/AppProvider';

const Crm = () => {
  const [activeButton, setActiveButton] = useState('now'); // 초기값: 어제 버튼 활성화
  const [rows, setRows] = useState([
    [

      { id: 'Summary', component: <SummaryChart />, size: { xl: 12, xxl: 4 } },
      { id: 'SiteIssueRank', component: <SiteIssueRank />, size: { xl: 6, xxl: 4 } },
      { id: 'SiteActiveRank', component: <SiteActiveRank />, size: { xl: 6, xxl: 4 } },
    ],
    [
      { id: 'AverageApiRank', component: <AverageApiRank />, size: { xl: 12, xxl: 2 } },
      { id: 'SiteAverageApiProcess', component: <SiteAverageApiProcess />, size: { xl: 6, xxl: 5 } },
      { id: 'SiteApiProcessSpeed', component: <SiteApiProcessSpeed />, size: { xl: 6, xxl: 5 } },

      //{ id: 'AverageApiSpeed', component: <AverageApiSpeed />, size: { sm: 12, md: 12, lg: 8, xl: 4, xxl: 5 } },//
    ],
    [
      { id: 'AverageCfRank', component: <AverageCfRank />, size: { xl: 12, xxl: 2 } },

      { id: 'SiteAverageCfProcess', component: <SiteAverageCfProcess />, size: { xl: 12, xxl: 5 } },
      { id: 'SiteCfProcessSpeed', component: <SiteCfProcessSpeed />, size: { xl: 12, xxl: 5 } },
    ],
    [
      { id: 'SiteIdleRate', component: <SiteIdleRate />, size: { xl: 6, xxl: 5 } },
      { id: 'SiteIdleAverage', component: <SiteIdleAverage />, size: { xl: 6, xxl: 5 } },
      { id: 'AlgorithmCompare', component: <AlgorithmCompare />, size: { xl: 12, xxl: 2 } }
    ]

  ]);
  const {
    config: { dashboardDate },
    setConfig
  } = useAppContext();

  useEffect(() => {
    let dateLabel;
    let dateValue;

    switch (activeButton) {
      case 'now':
        dateLabel = '최근 1시간';
        dateValue = 'now';
        break;
      case 'week':
        dateLabel = '최근 7일';
        dateValue = 'week';
        break;
      case 'month':
        dateLabel = '최근 30일';
        dateValue = 'month';
        break;
      default:
        dateLabel = '기본값'; // 기본값 또는 처리 필요 시 추가
        break;
    }

    setConfig('dashboardDate', dateLabel);
    setConfig('dashboardDateValue', dateValue);
  }, [activeButton]);


  const handleButtonClick = (button) => {
    const today = new Date();
    let startDate, endDate;

    switch (button) {
      case 'now':
        startDate = new Date();
        startDate.setDate(today.getDate() - 1);
        endDate = today;

        break;
      case 'week':
        startDate = new Date();
        startDate.setDate(today.getDate() - 7);
        endDate = today;

        break;
      case 'month':
        startDate = new Date();
        startDate.setMonth(today.getMonth() - 1);
        startDate.setDate(1); // 이전 월의 첫날
        endDate = new Date(today.getFullYear(), today.getMonth(), 0); // 이전 월의 마지막 날

        break;
      default:
        return;

    }

    flatpickr('#timepicker3', {
      mode: 'range',
      dateFormat: 'Y-m-d', // 올바른 날짜 포맷 설정
      locale: Korean,
      defaultDate: [startDate, endDate],

    });

    setActiveButton(button);
  };

  const getButtonVariant = (button) => {
    return activeButton === button ? 'primary' : 'outline-primary'; // 활성화된 버튼에 따라 스타일 적용
  };
  useEffect(() => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    flatpickr('#timepicker3', {
      mode: 'range',
      dateFormat: 'Y-m-d', // 올바른 날짜 포맷 설정
      locale: Korean,
      defaultDate: [yesterday, today],

    });
  }, []);
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    // 영역 외에 드롭이면 무반응
    if (!destination) return;

    const sourceRowIndex = parseInt(source.droppableId);
    const destinationRowIndex = parseInt(destination.droppableId);

    const sourceRow = Array.from(rows[sourceRowIndex]);
    const destinationRow = Array.from(rows[destinationRowIndex]);

    if (destination.index >= destinationRow.length) return;

    // 동일한 Row
    if (sourceRowIndex === destinationRowIndex) {
      // 위치 스왑
      const temp = sourceRow[source.index];
      sourceRow[source.index] = sourceRow[destination.index];
      sourceRow[destination.index] = temp;

      const newRows = [...rows];
      newRows[sourceRowIndex] = sourceRow;
      setRows(newRows);
    }
    // 서로 다른 Row
    else {
      const [movedItem] = sourceRow.splice(source.index, 1);
      destinationRow.splice(destination.index, 0, movedItem);

      // 위치 스왑
      // const temp = sourceRow[source.index];
      // sourceRow[source.index] = destinationRow[destination.index];
      // destinationRow[destination.index] = temp;

      const newRows = [...rows];
      newRows[sourceRowIndex] = sourceRow;
      newRows[destinationRowIndex] = destinationRow;
      setRows(newRows);
    }
  };

  return (
    <>
      <div className="my-2 d-flex justify-content-between align-items-center">

        <h4>SafeDB 대시보드</h4>
        <div className="d-flex align-items-center">

          <input
            style={{ height: 29, fontSize: 12, width: '12rem' }} // width를 100%로 설정
            className="form-control datetimepicker"
            id="timepicker3"
            type="text"
            disabled

          />
          <Button
            variant={getButtonVariant('now')}
            onClick={() => handleButtonClick('now')}
            style={{ height: 29, fontSize: 12, whiteSpace: 'nowrap', marginLeft: '1rem' }}>
            어제
          </Button>
          <Button
            variant={getButtonVariant('week')}
            onClick={() => handleButtonClick('week')}
            style={{ height: 29, fontSize: 12, whiteSpace: 'nowrap', marginLeft: '1rem', marginRight: '1rem' }}
            disabled
          >
            지난주
          </Button>
          <Button
            variant={getButtonVariant('month')}
            onClick={() => handleButtonClick('month')}
            style={{ height: 29, fontSize: 12, whiteSpace: 'nowrap' }}
            disabled
          >
            지난달
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {rows.map((row, rowIndex) => (
          <Droppable droppableId={`${rowIndex}`} key={rowIndex} direction="horizontal">
            {(provided) => (
              <Row className="g-3 mb-3" ref={provided.innerRef} {...provided.droppableProps}>
                {row.map((comp, colIndex) => (
                  <Draggable key={comp.id} draggableId={comp.id} index={colIndex}>
                    {(provided) => (
                      <Col
                        md={comp.size.md}
                        lg={comp.size.lg}
                        xl={comp.size.xl}
                        xxl={comp.size.xxl}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {comp.component}
                      </Col>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Row>
            )}
          </Droppable>
        ))}
      </DragDropContext>
      {/* <Greetings />
      <Row className="g-3 mb-3">
        <Col xxl={9}>
          <CrmStats />
          <Revenue />
        </Col>
        <Col xxl={3}>
          <MostLeads />
        </Col>
        <Col md={12} xxl={8}>
          <DealForecastBar />
        </Col>
        <Col xxl={4}>
          <DealStorageFunnel />
        </Col>
        <Col xxl={6}>
          <DealVsGoal />
        </Col>
        <Col xxl={6}>
          <DealForeCast />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={7}>
          <LocationBySession />
        </Col>
        <Col lg={5}>
          <Row className="g-3">
            <Col xs={12}>
              <AvgCallDuration />
            </Col>
            <Col xs={12}>
              <LeadConversation />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="g-3">
        <Col lg={5}>
          <ToDoList />
        </Col>
        <Col lg={7}>
          <RecentLeads />
        </Col>
      </Row> */}
    </>
  );
};

export default Crm;
