import React from 'react';
import PropTypes from 'prop-types';
import createMarkup from 'helpers/createMarkup';
import Section from 'components/common/Section';
import IconGroup from 'components/common/icon/IconGroup';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { bgWhiteIcons } from 'data/socialIcons';
import { version } from 'config';

const FooterTitle = ({ children }) => (
  <h5 className="text-uppercase text-white opacity-85 mb-3">{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map(({ title, to }, index) => (
      <li className="mb-1" key={index}>
        <Link className="link-600" to={to}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterBlogList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map((blog, index) => (
      <li key={index}>
        <h5 className="fs-9 mb-0">
          <Link className="link-600" to="#!">
            {blog.title}
          </Link>
        </h5>
        <p className="text-600 opacity-50">
          {blog.date} &bull; {blog.read} read{' '}
          {blog.star && (
            <span dangerouslySetInnerHTML={createMarkup('&starf;')} />
          )}
        </p>
      </li>
    ))}
  </ul>
);

FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

const FooterStandard = () => {

  return (
    <>
      <Section bg="dark" className="pt-8 pb-4" data-bs-theme="light">

        <Row>
          <Col lg={7}>
            <img width='120px' style={{ paddingBottom: '10px' }} src='/img/initech_logo.svg'></img>

            <FooterTitle>이니텍(주) | <a href='https://www.initech.com/html/sub/inve/pers_info.html'>개인정보처리방침</a></FooterTitle>
            <p className="text-600">
              이니텍(주) | 대표이사 : 옥성환 | 사업자등록번호 : 314-81-19071
              ADD : (08389)서울특별시 구로구 디지털로 26길 61, 11층(구로동, 에이스하이엔드타워2차)
              <br></br>
              TEL : (대표) 02-6445-7000 | FAX : 02-6445-7020
            </p>


          </Col>
          <Col className="ps-lg-6 ps-xl-8">
            <IconGroup className="mt-4" icons={bgWhiteIcons} />
          </Col>
        </Row>
      </Section >

      <section className="bg-dark py-0 text-center fs-10" data-bs-theme="light">
        <hr className="my-0 border-600 opacity-25" />
        <div className="container py-3">
          <Row className="justify-content-between">
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">
                COPYRIGHT © 2023 INITECH Co., Ltd. All RIGHTS RESERVED
              </p>
            </Col>
            <Col xs={12} sm="auto">
              {/* <p className="mb-0 text-600">v{version}</p> */}
              <p className="mb-0 text-600">v{version}</p>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default FooterStandard;
