import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col } from 'react-bootstrap';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Korean } from 'flatpickr/dist/l10n/ko.js';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import data from 'safedb/common/sampledata/sampledataLogEncDec';
import CheckOutModal from 'components/modal/CheckOutModal';

const columns = [
  { accessorKey: 'num', header: '순번', meta: { headerProps: { className: 'text-900' } }, enableSorting: false },
  { accessorKey: 'reqdate', header: '요청일시', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'resdate', header: '처리일시', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'policyid', header: '정책아이디', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'algorithm', header: '알고리즘', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'kind', header: '제어유형', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'reqcount', header: '요청수', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'succ_rescount', header: '성공 처리 수', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'fail_rescount', header: '실패 처리 수', meta: { headerProps: { className: 'text-900' } } }
];

function AdvanceTableExample() {
  const [tableData, setTableData] = useState(data);
  const [filteredData, setFilteredData] = useState(data.reverse());
  const [requestDateRange, setRequestDateRange] = useState([]);
  const [policyId, setPolicyId] = useState('');
  const [algorithm, setAlgorithm] = useState('');
  const [controlType, setControlType] = useState('');
  const [targetId, setTargetId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalReShow, setModalReShow] = useState(false);


  // useEffect(() => {
  //   const loadData = async () => {
  //     try {
  //       const response = await fetchEncDecLog();
  //       const logData = response.data.content.map((item, index) => ({
  //         num: index + 1,
  //         jobdate: new Date(item.insertTimeUtc).toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' }),
  //         role: item.userRole,
  //         uid: item.userId,
  //         job: item.taskHistory,
  //         result: ['성공', '클라이언트 에러', '서버 에러', '기타'][item.result] || '기타'
  //       }));
  //       setTotalElements(response.data.page.totalElements);
  //       const reversedData = logData.reverse();
  //       setTableData(reversedData);
  //       setFilteredData(reversedData);
  //     } catch (error) {
  //       console.error('API 데이터 로드 에러:', error);
  //     }
  //   };

  //   loadData();
  // }, []);


  const table = useAdvanceTable({
    data: filteredData,
    columns,
    sortable: true,
    selection: false,
    pagination: true,
    perPage: 10,
    selectionColumnWidth: 30
  });

  const handleSearch = () => {
    const [startDate, endDate] = requestDateRange;
    const newData = data.filter(row => {
      const reqDate = new Date(row.reqdate);
      const matchesDate = requestDateRange.length === 2 ? reqDate >= startDate && reqDate <= endDate : true;
      // const matchesPolicyId = policyId ? row.policyid === policyId : true;
      const matchesPolicyId = policyId ? row.policyid.includes(policyId) : true;
      const matchesAlgorithm = algorithm ? row.algorithm === algorithm : true;
      const matchesControlType = controlType ? row.kind === controlType : true;
      const matchesTargetId = targetId ? row.targetid === targetId : true;
      return matchesDate && matchesPolicyId && matchesAlgorithm && matchesControlType && matchesTargetId;
    });
    setFilteredData(newData.reverse()); // 항상 역순으로 설정
  };

  const handleDefault = () => {
    setRequestDateRange([]);
    setPolicyId('');
    setAlgorithm('');
    setControlType('');
    setTargetId('');
    // setFilteredData(tableData);
    // flatpickr('#timepicker3').clear();
    const datepicker = document.querySelector('#timepicker3')._flatpickr;
    if (datepicker) {
      datepicker.clear();  // 기존 날짜 선택 제거
      datepicker.setDate([]);  // 날짜 범위 초기화
    }
  };
  useEffect(() => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0); // 오늘 00시 00분 00초

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999); // 오늘 23시 59분 59초

    flatpickr('#timepicker3', {
      mode: 'range',
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      time_24hr: true,
      disableMobile: true,
      position: 'below',
      locale: Korean,
      minDate: new Date().fp_incr(-180),
      defaultDate: [todayStart, todayEnd], // 기본 날짜 범위 설정 (오늘 00시부터 23시 59분까지)
      onChange: (selectedDates) => setRequestDateRange(selectedDates)
    });
  }, []);
  return (
    <AdvanceTableProvider {...table}>
      <div className="d-flex flex-column flex-md-row align-items-center mb-1" style={{ gap: '15px' }}>
        {/* 전체 레코드 수와 페이지네이션 셀렉트 */}
        <div className="d-flex align-items-center">
          <AdvanceTableFooter rowsPerPageSelection />
        </div>

        {/* 검색 필터 */}
        <Row className="w-100 align-items-center">
          <Col xxl={4} xl={4} className="mb-4 ps-4 fs-10">
            <label htmlFor="timepicker3" className="form-label">&nbsp;</label>
            <input
              id="timepicker3"
              className="form-control datetimepicker"
              type="text"
              title="요청일시"
              placeholder="2024-01-01 00:00 ~ 2024-12-31 00:00"
              style={{ height: '29px', fontSize: '1rem' }}
            />
          </Col>
          <Col xxl={2} xl={2} className="mb-4 fs-10">
            <Form.Label>&nbsp;</Form.Label>
            {/* <Form.Select size="sm" value={policyId} onChange={e => setPolicyId(e.target.value)} style={{ height: '29px' }}>
              <option value="" ></option>
              {[...new Set(data.map(item => item.policyid))].map((policyid, index) => (
                <option key={index} value={policyid}>{policyid}</option>
              ))}
            </Form.Select> */}
            <Form.Control
              size="sm"
              type="text"
              value={policyId}
              placeholder="암호키 아이디"
              onChange={e => setPolicyId(e.target.value)}
              style={{ height: '29px' }}
            />
          </Col>
          <Col xxl={2} xl={2} className="mb-4 fs-10">
            <Form.Label>&nbsp;</Form.Label>
            <Form.Select size="sm" value={algorithm} onChange={e => setAlgorithm(e.target.value)} style={{ height: '29px' }}>
              <option value="" disabled hidden >알고리즘</option>

              {[...new Set(data.map(item => item.algorithm))].map((algorithm, index) => (
                <option key={index} value={algorithm}>{algorithm}</option>
              ))}
            </Form.Select>
          </Col>
          <Col xxl={2} xl={2} className="mb-4 fs-10 pe-4">
            <Form.Label>&nbsp;</Form.Label>
            <Form.Select size="sm" value={controlType} onChange={e => setControlType(e.target.value)} style={{ height: '29px' }}>
              <option value="" disabled hidden >처리유형</option>
              {[...new Set(data.map(item => item.kind))].map((kind, index) => (
                <option key={index} value={kind}>{kind}</option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        {/* 구분선 */}


        <div className="d-flex justify-content-end align-items-center" style={{ gap: '0.2rem' }}>

          <Button variant="outline-secondary" onClick={handleDefault} className="me-2" style={{ whiteSpace: 'nowrap', width: '5rem' }} title="초기화" >초기화</Button>
          <Button variant="outline-secondary" onClick={handleSearch} className="me-2" style={{ whiteSpace: 'nowrap', width: '4rem' }}>검색</Button>
          <Button variant="outline-secondary" style={{ whiteSpace: 'nowrap', width: '4rem' }}>CSV</Button>
        </div>
      </div>

      <AdvanceTable
        headerClassName="bg-200 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{ striped: true, className: 'fs-10 mb-0 overflow-hidden' }}
      />
      <p className="mb-0" style={{ fontWeight: 700, lineHeight: '29px' }}>총 {data.length}건</p>
      <AdvanceTablePagination />

    </AdvanceTableProvider>
  );
}

const logEncDec = () => (
  <div className="container mt-5">
    <Card className="mypage-card">
      <Card.Header as="h4">암복호화 로그</Card.Header>
      <Card.Body>
        <AdvanceTableExample />
      </Card.Body>
    </Card>
  </div>
);

export default logEncDec;
