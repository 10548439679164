export const version = '1.0.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const themeVariants = ['light', 'dark', 'auto'];

// 설정 함수
export const settings = (navvalue) => {
  const userAuth = navvalue === 'SA' ? 'SA' : (navvalue === 'ADMIN' ? 'ADMIN' : 'MANAGER');
  const userId = userAuth === 'SA' ? 'INITECH_MANGER' : (userAuth === 'ADMIN' ? 'ADMIN' : 'MANAGER');
  const userAuth_K = userAuth === 'SA' ? '총괄 관리자' : (userAuth === 'ADMIN' ? '시스템 관리자' : '매니저');
  //const checkorg = userAuth === 'SA' ? '' : (userAuth === 'ADMIN' ? '샘플고객사' : '샘플고객사');

  return {
    isFluid: true,
    isRTL: false,
    isDark: false,
    isLoading: false, // 스피너 관련
    theme: 'light',
    navvalue: navvalue,
    userAuth: userAuth,
    userAuth_K: userAuth_K,
    userId: userId,
    userUuid: '',
    firstLogin: 'valid',
    checkorg: '',
    userName: '',
    navbarPosition: chkNavValue(navvalue),
    hiddenMenuActive: true,
    showBurgerMenu: false, // 모바일에서 수직 네비게이션 표시 제어
    currency: '$',
    dashboardDate: 'now',
    dashboardDateValue: 'now',
    isNavbarVerticalCollapsed: true, // 수직 네비게이션 축소 토글
    navbarStyle: 'transparent'
  };
}

// 관리자/사용자에 따라 왼쪽 메뉴/상단 메뉴 관련, 데이터 받아서 바뀌어야 함.
function chkNavValue(navvalue) {
  return navvalue === 'SA' ? 'combo' : 'vertical';
}


// // 초기값 설정
// const defaultNavValue = 'SA'; // 기본값
// const initialSettings = settings(defaultNavValue); // 기본값으로 초기화
// // 초기값을 내보내기
// export const getInitialSettings = () => initialSettings;

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
