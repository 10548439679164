import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import { TooltipComponent, GridComponent, LegendComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { useAppContext } from 'providers/AppProvider';
import { Card } from 'react-bootstrap';
import { fetchRecentlyEncDec } from 'hooks/CallApi';

function ChartOptions() {
  const chartRef = useRef(null);
  const {
    config: { dashboardDate, dashboardDateValue },
  } = useAppContext();

  const [chartData, setChartData] = useState({ timeData: [], encryptData: [], decryptData: [], failData: [] });

  useEffect(() => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const fetchData = async () => {
      try {
        const response = await fetchRecentlyEncDec({ period: dashboardDateValue, timeZone: clientTimeZone });
        const data = response.data;

        const timeData = data.map((item) => item.periodKst);
        const encryptData = data.map((item) => parseInt(item.encrypt_cnt) || 0);
        const decryptData = data.map((item) => parseInt(item.decrypt_cnt) || 0);
        const failData = data.map((item) => (item.fail_mark ? Math.max(parseInt(item.enc_fail_cnt), parseInt(item.dec_fail_cnt)) : null));

        setChartData({ timeData, encryptData, decryptData, failData });
      } catch (error) {
        console.error('데이터 조회 에러:', error);
      }
    };

    fetchData();
  }, [dashboardDate, dashboardDateValue]);

  useEffect(() => {
    const minFailHeight = 2;
    const failDataAdjusted = chartData.failData.map(value =>
      value !== null && value < minFailHeight ? minFailHeight : value
    );

    const isDataEmpty = chartData.encryptData.every((value) => value === 0) &&
      chartData.decryptData.every((value) => value === 0) &&
      chartData.failData.every((value) => value === null);

    const formatNumber = (value) => {
      if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + 'M';
      } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + 'K';
      } else {
        return value;
      }
    };

    const option = {
      title: isDataEmpty
        ? {
          text: '데이터 없음',
          left: 'center',
          top: 'middle',
          textStyle: {
            color: '#aaa',
            fontSize: 16,
          },
        }
        : null,
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'line' },
        formatter: (params) => {
          if (!params.length) return '';
          let tooltipText = `${params[0].name}<br/>`;
          params.forEach(item => {
            tooltipText += `${item.marker} ${item.seriesName}: ${formatNumber(item.value)} 건<br/>`;
          });
          return tooltipText;
        }
      },
      legend: {
        data: ['암호화', '복호화', '실패'],
        bottom: '5%',
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '15%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: chartData.timeData,
        name: '시간',
        // axisLabel: { formatter: '{HH}:{mm}' },
        axisLabel: {
          // formatter: (value) => (value ? `{HH}:{mm}` : ''), // 값이 없으면 레이블 표시 안함
          formatter: (value) => (value ? new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''),

          show: !isDataEmpty, // 데이터가 없으면 axisLabel 숨김
        },



        splitLine: { show: false }
      },
      yAxis: {
        type: 'value',
        name: '요청량',
        splitLine: { show: true },
        axisLabel: { formatter: (value) => formatNumber(value) + ' 건' },
        scale: true
        // max: (value) => Math.ceil(value.max / 1000) * 1000
      },
      series: [
        {
          name: '암호화',
          type: 'line',
          data: isDataEmpty ? [] : chartData.encryptData,
          itemStyle: { color: '#2f7ed8' },
          smooth: false,
        },
        {
          name: '복호화',
          type: 'line',
          data: isDataEmpty ? [] : chartData.decryptData,
          itemStyle: { color: '#8bbc21' },
          smooth: false,
        },
        {
          name: '실패',
          type: 'scatter',
          symbol: 'pin',
          symbolSize: 40,
          label: {
            show: true,
            formatter: '실패',
            position: 'inside',
            color: '#fff',
            fontSize: 10
          },
          data: isDataEmpty ? [] : failDataAdjusted,
          itemStyle: { color: '#ff4d4d' }
        }
      ]
    };

    const chart = echarts.init(chartRef.current);
    chart.setOption(option);

    const handleResize = () => {
      chart.resize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.dispose();
    };
  }, [chartData]);

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>지난 암호화·복호화 요청량</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3',
            color: 'white',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          {dashboardDate}
        </span>
      </Card.Header>
      <div ref={chartRef} style={{ width: '100%', maxWidth: '1200px', height: '15rem', margin: '0 auto' }} />
    </Card>
  );
}

export default ChartOptions;
