
const limitStringLength = (str, maxLength) => {
    return str.length > maxLength ? str.slice(0, maxLength) : str;
};

const generateData = (count) => {
    const data = [];

    for (let i = 1; i <= count; i++) {
        data.push({
            id: `${i}`,
            publickeyName: limitStringLength(`SK-AES128CBC-${i}`, 40),
            algorithm: limitStringLength("ARIA", 10),
            keylength: "128",
            mode: "CBC",
            desc: limitStringLength("", 50)

        });
    }
    return data;
};

const data = generateData(100); // 데이터 40개 생성

export default data;
