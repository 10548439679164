import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Row, Col } from 'react-bootstrap';
import avatarImg from 'assets/img/team/avatar.png';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Flex from 'components/common/Flex';

import "safedb/common/css/IniCustomCss.css"
import CheckOutModal from 'components/modal/CheckOutModal';
import { AiFillEye, AiFillEyeInvisible, AiFillCloseCircle } from 'react-icons/ai';
import { handleValueDelete, goToParentPath } from 'components/common/SunnyUtils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import GroupManagementModal from 'components/modal/GroupManagementModal';
import GroupAddModal from 'components/modal/GroupAddModal'; // 경로에 맞게 수정
const editencryptPublicKey = ({ setValue, handleClose }) => {

    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const {
        config: { userAuth_K, userId, checkorg },
        setConfig
    } = useAppContext();
    const [modalShow, setModalShow] = useState(false); // 관리자 비밀번호 모달 상태 
    const [isRemakeSelected, setIsRemakeSelected] = useState(false);
    const [isModified, setIsModified] = useState(false); // 변경 상태 추적
    const [showToast, setShowToast] = useState(false); // 토스트 상태
    const [showGroupModal, setShowGroupModal] = useState(false); // 모달 상태 추가
    const [showGroupAddModal, setShowGroupAddModal] = useState(false); // 모달 상태 추가

    const handleGroupModalShow = () => {
        setShowGroupModal(true); // 모달 열기
    };

    const handleGroupModalHide = () => {
        setShowGroupModal(false); // 모달 닫기
    };

    const handleCheckOutModal = () => {
        setModalShow(false); // 관리자 모달 닫기  
    };
    const handleGroupModalConfirm = () => {
        setShowGroupModal(false);
        setShowGroupAddModal(true);

    };
    const handleGroupAddModalShow = () => {
        setShowGroupAddModal(true); // 모달 열기
    };

    const handleGroupAddModalHide = () => {
        setShowGroupAddModal(false); // 모달 닫기
    };
    const handleGroupAddModalConfirm = () => {
        setShowGroupAddModal(false);

    };
    const handleDeleteClick = () => {
        setModalShow(true); // 모달 열기
    };

    const handleCheckOutModalConfirm = () => {
        setModalShow(false);
        // toast.error(`[ ${formData.keyname} ] Access Key 삭제가 완료되었습니다.`, {
        //     theme: 'colored'
        // });
        navigate(-1);
    };

    const handleRemakeClick = () => {
        setIsRemakeSelected((prev) => !prev); // Toggle selected state

    };
    const [pwType, setpwType] = useState({
        type: "password",
        visible: false,
    });
    const handlePasswordType = (e) => {
        setpwType(() => {
            // 만약 현재 pwType.visible이 false 라면
            if (!pwType.visible) {
                return { type: "text", visible: true };

                //현재 pwType.visible이 true 라면
            } else {
                return { type: "password", visible: false };
            }
        });
    };
    const [formData, setFormData] = useState({
        publickeyName: queryParams.get('publickeyName'),
        algorithm: queryParams.get('algorithm'),
        keylength: queryParams.get('keylength'),
        mode: queryParams.get('mode'),
        description: '',
    });

    const { user } = useAuthWizardContext();

    const [avatar, setAvatar] = useState([
        ...(user.avatar ? user.avatar : []),
        { src: avatarImg }
    ]);
    useEffect(() => {
        // 컴포넌트가 처음 렌더링될 때 formData 초기화
        setFormData((prev) => ({
            ...prev,
            adderRole: userAuth_K, // config에서 가져온 값
            adderId: userId, // config에서 가져온 값
        }));
    }, [userAuth_K, userId]); // 의존성 배열에 userAuth와 userId 추가

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
        setIsModified(true); // 필드가 변경되면 상태 업데이트
    };

    const handleComboChange = ({ target }) => {
        let name = target.name;
        let value = name === 'allDay' ? target.checked : target.value;
        setFormData(prev => ({ ...prev, [name]: value }));
        setIsModified(true); // 필드가 변경되면 상태 업데이트
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // 등록 과정 추가 필요

        //
        navigate(-1);

    };

    const isFormValid = () => {
        const { group, keyname, accesskey, description } = formData;
        return isModified && (group || keyname || accesskey || description);
    };

    return (
        <div className="container mt-5">
            <Card className="mypage-card" >
                <Link to={goToParentPath()} style={{ textDecoration: 'none', color: 'inherit', }}>

                    <Card.Header className="px-3" as="h4">암호화 공유키 관리</Card.Header>
                </Link>

                <Card.Title className="border border-bottom-0 px-3 pt-3">  상세 정보</Card.Title>
                <div style={{ position: 'relative' }} className='mb-2'>

                    <Link to={paths.encryptPublicKey}>
                        <Button
                            variant="outline-secondary"
                            style={{
                                position: 'absolute',
                                right: 25,
                                top: -8,
                                width: '100px'
                            }}
                        >
                            목록
                        </Button>
                    </Link>
                </div>
                <Card.Body className="border border-top-0">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>공유키명</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="publickeyName"
                                                value={formData.publickeyName}
                                                disabled
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>

                                        <Form.Group className="mb-3">
                                            <Form.Label>알고리즘</Form.Label>
                                            <Form.Select
                                                type="text"
                                                name="algorithm"
                                                value={formData.algorithm}
                                                readOnly
                                                disabled
                                            >
                                                <option value="mode">{formData.algorithm}</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>

                                        <Form.Group className="mb-3">
                                            <Form.Label>키 길이(bit)</Form.Label>
                                            <Form.Select
                                                type="text"
                                                name="keylength"
                                                value={formData.keylength}
                                                readOnly
                                                disabled
                                            >
                                                <option value="mode">{formData.keylength}</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>

                                        <Form.Group className="mb-3">
                                            <Form.Label>운영모드</Form.Label>
                                            <Form.Select
                                                type="text"
                                                name="mode"
                                                value={formData.mode}
                                                readOnly
                                                disabled
                                            >
                                                <option value="mode">{formData.mode}</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                                    <Form.Label>설명</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="description"
                                        rows={10}
                                        value={formData.description}
                                        onChange={handleChange}
                                        className="input-hover"
                                    // placeholder="키의 용도, 사용할 부서 또는 시스템을 입력할 수 있습니다. (예: 마케팅 부서, pod-001 복호화 전용)"
                                    />

                                    {formData.description && (
                                        <span
                                            onClick={(e) => handleValueDelete('description', formData, setFormData)}
                                            style={{
                                                cursor: 'pointer',
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'transformY(-50%)',
                                                zIndex: 9
                                            }}
                                        >
                                            <AiFillCloseCircle />
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* Cancel and Confirm Buttons */}
                        <div className="d-flex justify-content-between mt-3">
                            <Button variant="outline-danger" style={{ width: '100px' }} onClick={handleDeleteClick}>
                                삭제
                            </Button>
                            {/* <Button variant="primary" type="submit" style={{ width: '100px' }} disabled={!isFormValid()}>
                                변경
                            </Button> */}
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <CheckOutModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onConfirm={handleCheckOutModalConfirm}
                title="확인" // 제목
                body={"삭제하시겠습니까?"} // 템플릿 리터럴 사용
                cancelText="취소" // 취소 버튼 텍스트
                confirmText="삭제" // 확인 버튼 텍스트
                variant="warning" // 원하는 variant
                SubmitButton={true}
            />
            <GroupManagementModal
                show={showGroupModal}
                onHide={handleGroupModalHide}
                onConfirm={handleGroupModalConfirm}
            />
            <GroupAddModal
                show={showGroupAddModal}
                onHide={handleGroupAddModalHide}
                onConfirm={handleGroupAddModalConfirm}
            // formData={FormData}
            // setFormData={setFormData}
            />

        </div >


    );
};

export default editencryptPublicKey;
