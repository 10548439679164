import React from 'react';
import { Button, Form, Modal, InputGroup, FormControl } from 'react-bootstrap';
import IconAlert from 'components/common/IconAlert';

const NewPasswordModal = ({ show, onHide, onConfirm }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">확인</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {['warning'].map(variant => (
          <IconAlert key={variant} variant={variant}>
            새로운 비밀번호로 변경하시겠습니까?
          </IconAlert>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="outline-secondary" className="me-2">
          취소
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          확인
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewPasswordModal;
