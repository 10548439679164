import React from 'react';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  ToolboxComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEchart from 'components/common/ReactEchart';
import { useAppContext } from 'providers/AppProvider';
import { Card } from 'react-bootstrap';

echarts.use([
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
  ToolboxComponent
]);

function ChartOptions() {
  const { getThemeColor } = useAppContext();
  const {
    config: { dashboardDate },
    setConfig
  } = useAppContext();
  const times = Array.from({ length: 24 }, (_, i) => `${i}시`);
  const successData = [
    0, 0, 0, 0, 10, 140, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260,
    270, 280, 290, 300, 310, 320, 330
  ];
  const failureData = [
    0, 0, 0, 0, 1, 2, 1, 1, 1, 1, 2, 1, 1, 2, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1
  ];

  const adjustedFailureData = failureData.map(value =>
    value > 0 ? value : Math.max(...failureData) * 0.05
  );
  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          show: true,
          backgroundColor: '#6E7079', // 회색 배경
          color: '#fff', // 텍스트 색상
          padding: [3, 5], // 네모 모양 크기 조정
          formatter: '{value}', // 현재 위치의 값 표시
          borderRadius: 4 // 네모 모양의 테두리 둥글게
        },
        lineStyle: {
          color: '#6E7079', // 회색 점선
          type: 'dashed'
        }
      },
      formatter: params => {
        const total = params.reduce((sum, item) => sum + item.value, 0);
        return `
          ${params[0].name} <br/>
          <span style="display: inline-block; width: 10px; height: 10px; background-color: ${getThemeColor(
          'success'
        )}; border-radius: 50%; margin-right: 5px;"></span>
          성공: ${params[0].value} 건 <br/>
          <span style="display: inline-block; width: 10px; height: 10px; background-color: ${getThemeColor(
          'danger'
        )}; border-radius: 50%; margin-right: 5px;"></span>
          실패: ${params[1].value} 건 <br/>
          총 처리 수: ${total} 건
        `;
      }
    },
    toolbox: {
      feature: {
        saveAsImage: { show: false },
        restore: { show: false },
        dataZoom: { show: false }
      },
      iconStyle: {
        borderColor: getThemeColor('gray-700')
      }
    },
    legend: {
      data: ['성공', '실패'],
      textStyle: {
        color: getThemeColor('gray-600')
      },
      bottom: 0
    },
    xAxis: {
      type: 'category',
      data: times,
      axisLine: {
        lineStyle: {
          color: getThemeColor('gray-300')
        }
      },
      axisLabel: {
        color: getThemeColor('gray-600')
      }
    },
    yAxis: {
      type: 'value',
      name: '처리 수',
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: {
        color: getThemeColor('gray-600')
      },
      splitLine: {
        lineStyle: {
          color: getThemeColor('gray-200')
        }
      }
    },
    series: [
      {
        name: '성공',
        type: 'bar',
        stack: 'total',
        data: successData,
        itemStyle: {
          color: getThemeColor('success')
        }
      },
      {
        name: '실패',
        type: 'bar',
        stack: 'total',
        // data: failureData,
        data: adjustedFailureData,

        itemStyle: {
          color: getThemeColor('danger')
        },
        emphasis: {
          label: {
            show: true,
            position: 'top',
            formatter: '{c} 건',
            color: getThemeColor('white'),
            backgroundColor: getThemeColor('danger'),
            borderRadius: 50,
            padding: [6, 8],
            offset: [0, -15]
          }
        }
      }
    ],
    grid: {
      top: '15%',
      left: '3%',
      right: '10%',
      bottom: '10%',
      containLabel: true
    }
  });

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>암호화옵션 요청 비교</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          {dashboardDate}

        </span>
      </Card.Header>
      <ReactEchart
        echarts={echarts}
        option={getOption()}
        style={{ height: '15rem' }}
      />
    </Card>
  );
}

export default ChartOptions;
