import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Row, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'; // React Dropzone import 
import avatarImg from 'assets/img/team/avatar.png';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Flex from 'components/common/Flex';
import { ToastContainer, toast } from 'react-toastify'; // 추가
import "react-toastify/dist/ReactToastify.css"; // 추가
import "safedb/common/css/IniCustomCss.css"
import CheckOutModal from 'components/modal/CheckOutModal';
import { AiFillCloseCircle } from 'react-icons/ai';
import { handleValueDelete } from 'components/common/SunnyUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import { PiWarningCircle } from "react-icons/pi";
import { createCompany } from 'hooks/CallApi'; // API 함수 임포트

const AddAdmimSites = ({ setValue }) => {

  const [modalConfirmShow, setModalConfirmShow] = useState(false);
  const [modalConfirmCheckShow, setModalConfirmCheckShow] = useState(false);
  const [isChevronUp, setIsChevronUp] = useState(false); // 상태 변수 추가
  const toggleKeyPart = () => {
    setKeyPart(!KeyPart);
    setIsChevronUp(!isChevronUp); // 아이콘 상태 전환
  };
  const [KeyPart, setKeyPart] = useState(true);
  const [NameError, setNameError] = useState('');
  const [DescError, setDescError] = useState('');

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  // 내용 변경 시 변경 버튼 눌렀을때
  const handleSubmitClick = () => {
    e.preventDefault();
    setModalConfirmShow(true);
  };

  const handleConfirm = async () => {
    try {
      const response = await createCompany({
        imgUrl: formData.imgUrl,
        name: formData.clientName,
        tel: formData.clientPhone,
        email: formData.clientEmail,
        address: formData.clientAddress,
        description: formData.description
      });
      console.log("response : ", response);
      if (response) {
        setModalConfirmShow(false);
        setModalConfirmCheckShow(true);
        toast.success('정상 등록되었습니다.');
      }
    } catch (error) {
      console.error('회사 생성 에러:', error);
      toast.error('회사 생성 중 오류가 발생했습니다.');
    }
    navigate(-1);
  };



  const navigate = useNavigate(); // useNavigate 훅 사용
  const handleDeleteRe = () => {
    navigate(`/safedb/SA/adminSites`); // 데이터와 함께 이동

  };
  const [clientPhoneError, setClientPhoneError] = useState(false); // 추가된 상태 변수


  const [formData, setFormData] = useState({
    createdAt: '',
    updateAt: '',
    description: '',
    imgUrl: '',
    active: '',
    clientName: '',
    clientEmail: '',
    clientPhone: '',
    clientAddress: '',
    clientSecret: '',
    clientKey: '',
    clientIV: ''
  });
  const { user } = useAuthWizardContext();

  const [avatar, setAvatar] = useState([
    ...(user.avatar ? user.avatar : []),
    { src: avatarImg }
  ]);


  const handleChange = (e) => {
    const { name, value } = e.target;


    if (name === 'clientName' && value.length >= 15) {
      setNameError(`고객사명은 최대 15자까지 입력 가능합니다.`);
    } else {
      setNameError('');
    }

    if (name === 'description' && value.length >= 800) {
      setDescError(`고객사 설명란 입력은 최대 800자까지 입력 가능합니다.`);
    } else {
      setDescError('');
    }



    if (name === 'clientPhone') {
      // 숫자가 아닌 경우 오류 상태 변경
      const isValidPhone = /^\d*$/.test(value); // 숫자만 허용
      setClientPhoneError(!isValidPhone);
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();

  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const imageFile = acceptedFiles[0];
      const imageUrl = URL.createObjectURL(imageFile);
      setAvatar([{ src: imageUrl }]);
      setValue('avatar', imageFile);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpeg', '.jpg', '.png', '.gif'] }, // 이미지 파일만 허용
  });

  const isFormValid = () => {
    const { clientName } = formData;
    return clientName;
  };

  const handleDateChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value // 이미 'YYYY-MM-DDTHH:MM' 형식으로 제공됨
    });
  };


  return (
    <div className="container mt-5">
      <Card className="mypage-card" >
        <Card.Header className="px-3" as="h4">고객사 관리</Card.Header>
        <Card.Title className="border border-bottom-0 px-3 pt-3">  고객사 정보 등록</Card.Title>
        <Card.Body className="border border-top-0">
          <Form onSubmit={(e) => { e.preventDefault(); setModalConfirmShow(true); }}>
            <Row>

              {/* Left Column (Profile Image) */}
              <Col md={4} className="text-center profile-section">
                <div {...getRootProps({ className: 'dropzone' })}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '150px',
                    height: '150px',
                    position: 'relative',
                    margin: '0 auto',
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <input {...getInputProps()} />
                  <Flex justifyContent="center" >
                    <img
                      src={avatar.length > 0 ? avatar[0].src : cloudUpload}
                      alt=""
                      style={{
                        borderRadius: '50%', // 동그란 형태
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        objectFit: 'cover', // 이미지 비율 유지

                      }}
                    />
                    {isHovered && ( // hover 시 텍스트 표시
                      <div style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(0, 0, 0, 1)',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '50%', // 동그란 형태
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        pointerEvents: 'none', // 클릭 방지
                        objectFit: 'cover', // 이미지 비율 유지
                        display: 'flex', // Flexbox 사용
                        justifyContent: 'center', // 수평 중앙 정렬
                        alignItems: 'center', // 수직 중앙 정렬
                      }}>
                        이미지 변경
                      </div>
                    )}
                  </Flex>
                  {/* <p>여기에 이미지를 드래그하거나 클릭하여 업로드하세요.</p> */}
                </div>
                <p className="mt-3">[{formData.clientName}]</p> {/* @@ 여기 @@*/}


              </Col>

              {/* Right Column (ID, Password, Name) */}
              <Col md={8}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>고객사명<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="clientName"
                        value={formData.clientName}
                        onChange={handleChange}
                        className="input-hover"
                        maxLength='15'
                      //  placeholder="아이디를 입력해주세요."
                      />

                      {formData.clientName && (
                        <span
                          onClick={(e) => handleValueDelete('clientName', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                    {NameError &&
                      <span style={{ color: 'red' }}>
                        {NameError}
                      </span>} {/* 에러 메시지 표시 */}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>고객사 연락처</Form.Label>
                      <InputGroup>
                        <FormControl
                          type="phone"
                          name="clientPhone"
                          value={formData.clientPhone}
                          onChange={handleChange}
                          className="input-hover"
                          maxLength='20'
                        // placeholder="비밀번호를 입력해주세요."
                        />

                      </InputGroup>
                      {formData.clientPhone && (
                        <span
                          onClick={(e) => handleValueDelete('clientPhone', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                      {clientPhoneError &&
                        <span
                          style={{
                            color: 'red',
                            position: 'absolute',
                            right: '30px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9,
                          }}
                        >
                          <PiWarningCircle />
                        </span>} {/* 에러 메시지 표시 */}

                    </Form.Group>

                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>고객사 메일</Form.Label>
                      <Form.Control
                        type="text"
                        name="clientEmail"
                        value={formData.clientEmail}
                        onChange={handleChange}
                        className="input-hover"

                      // placeholder="이름을 입력해주세요."
                      />

                      {formData.clientEmail && (
                        <span
                          onClick={(e) => handleValueDelete('clientEmail', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>

                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>고객사 주소</Form.Label>
                      <Form.Control
                        type="text"
                        name="clientAddress"
                        value={formData.clientAddress}
                        onChange={handleChange}
                        className="input-hover"

                      // placeholder="이름을 입력해주세요."
                      />

                      {formData.clientAddress && (
                        <span
                          onClick={(e) => handleValueDelete('clientAddress', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>등록 일시</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        name="addDate"
                        // value={formData.createdAt}
                        value={formData.createdAt} // 저장된 형식 그대로 사용
                        disabled
                      // onChange={handleDateChange}


                      />
                    </Form.Group>
                  </Col><Col>

                    <Form.Group className="mb-3">
                      <Form.Label>수정 일시</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        name="editDate"
                        value={formData.updateAt}
                        disabled
                      // onChange={handleDateChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>


                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                  <Form.Label>설명</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    rows={1}
                    value={formData.description}
                    onChange={handleChange}
                    className="input-hover"
                    maxLength='800'
                  />
                  {DescError &&
                    <div style={{ color: 'red' }}>
                      {DescError}
                    </div>} {/* 에러 메시지 표시 */}
                  {formData.description && (
                    <span
                      onClick={(e) => handleValueDelete('description', formData, setFormData)}
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'transformY(-50%)',
                        zIndex: 9
                      }}
                    >
                      <AiFillCloseCircle />
                    </span>
                  )}
                </Form.Group>

              </Col>




            </Row>

            {/* Cancel and Confirm Buttons */}
            <div className="d-flex justify-content-end mt-3">
              <Button variant="secondary" className="mx-3" onClick={handleDeleteRe} style={{ width: '7rem', whiteSpace: 'nowrap' }}>
                관리 키 설정
              </Button>
              <Button variant="primary" type="submit" disabled={!isFormValid()} style={{ width: '7rem' }}>
                등록
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>



      <CheckOutModal
        show={modalConfirmShow}
        onHide={() => setModalConfirmShow(false)}
        onConfirm={handleConfirm}
        title="확인" // 제목
        body="변경하신 사항으로 계정 정보를 수정하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalConfirmCheckShow}
        onHide={() => setModalConfirmCheckShow(false)}
        variant="success"
        onConfirm={handleConfirm}
        title="확인" // 제목
        body="변경하신 사항이 해당 계정 정보에 저장되었습니다." // 본문 내용
        SubmitButton={false}
      />
    </div >


  );
};

export default AddAdmimSites;
