const limitStringLength = (str, maxLength) => {
    return str.length > maxLength ? str.slice(0, maxLength) : str;
};

const generateData = (count) => {
    const data = [];

    for (let i = 1; i <= count; i++) {
        data.push({
            role: i % 2 === 0 ? '개발자' : '시스템관리자', // 짝수는 개발자, 홀수는 시스템관리자
            id: `Admin_customer${i}`,
            name: i < 1 ? '고객사 관리자' : '홍길동',
            status: i % 2 === 0 ? '차단' : '정상', // 짝수는 Inactive, 홀수는 Active
            createdAt: `2024-${String((12 - ((i - 1) % 12))).padStart(2, '0')}-10`, // 12개월 주기로 생성 날짜
            lastLoginAt: `2024-${String((13 - ((i - 1) % 12))).padStart(2, '0')}-15` // 가입 날짜도 12개월 주기
        });
    }

    return data;
};

const data = generateData(400); // 데이터 40개 생성

export default data;
