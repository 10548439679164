import React, { useState, useEffect } from 'react';
import { ToggleButton, ButtonGroup, Button, Form, Modal, Table, Row, Col } from 'react-bootstrap';


import CheckOutModal from 'components/modal/CheckOutModal';
import { toast } from 'react-toastify';
import Borders from 'components/utilities/Borders';


const PolicyControlModal = ({ show, onHide, selecteValues, onConfirm, policyId, keyname }) => {
  const [modalShow, setModalShow] = useState(false);

  const initialEncryptActive = true;
  const initialDecryptActive = true;
  const initialStartNumber = '1';
  const initialStartNumber2 = '0';
  const initialMaskingChar = '*';
  const initialIsMaskingEnabled = false;



  const [encryptActive, setEncryptActive] = useState(initialEncryptActive);
  const [decryptActive, setDecryptActive] = useState(initialDecryptActive);
  const [startNumber, setStartNumber] = useState(initialStartNumber);
  const [startNumber2, setStartNumber2] = useState(initialStartNumber2);
  const [maskingChar, setMaskingChar] = useState(initialMaskingChar);
  const [isMaskingEnabled, setIsMaskingEnabled] = useState(initialIsMaskingEnabled);
  const [isValidNumber, setIsValidStartNumber] = useState(true);
  const [isValidSize, setIsValidSize] = useState(true);

  // 모달이 열릴 때마다 상태 초기화
  useEffect(() => {
    if (show) {
      setEncryptActive(initialEncryptActive);
      setDecryptActive(initialDecryptActive);
      setStartNumber(initialStartNumber);
      setStartNumber2(initialStartNumber2);
      setMaskingChar(initialMaskingChar);
      setIsMaskingEnabled(initialIsMaskingEnabled);
      setIsValidStartNumber(true);
      setIsValidSize(true);
    }
  }, [show]);
  // 암호화 권한 버튼 클릭 시 상태 토글
  const handleEncryptToggle = () => {
    setEncryptActive(!encryptActive); // 현재 상태 반전
  };

  // 복호화 권한 버튼 클릭 시 상태 토글
  const handleDecryptToggle = () => {
    setDecryptActive(!decryptActive); // 현재 상태 반전
  };



  const handleStartNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setStartNumber(value); // 숫자만 허용
      setIsValidStartNumber(true); // 유효성 통과
    } else {
      setIsValidStartNumber(false); // 유효성 실패
    }
  };

  const handleMaskingCharChange = (e) => {
    setMaskingChar(e.target.value); // 마스킹 문자 변경
  };
  // 크기에 대한 유효성 검사 핸들러
  const handleSizeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setStartNumber2(value); // 숫자만 허용
      setIsValidSize(true); // 유효성 통과
    } else {
      setIsValidSize(false); // 유효성 실패
    }
  };

  // 포커스 아웃 핸들러
  const handleStartNumberBlur = () => {
    if (startNumber === '') {
      setStartNumber('1'); // 빈 값이면 1로 설정
    }
  };

  const handleSizeBlur = () => {
    if (startNumber2 === '') {
      setStartNumber2('0'); // 빈 값이면 0으로 설정
    }
  };

  const handleConfirm = () => {

    const selectedOptions = {
      encryptActive,
      decryptActive,
      isMaskingEnabled,
      startNumber,
      size: startNumber2,
      maskingChar: maskingChar // maskingChar 변수 추가 필요
    };
    toast.success("제어 정책이 저장되었습니다", {
      theme: 'colored'
    });
    onConfirm(selectedOptions);
    onHide();


  };
  const handleDefault = () => {
    toast.success("제어 정책이 초기화었습니다", {
      theme: 'colored'
    });
    onHide();
    setModalShow(false)
  };
  const handledefaultClick = () => {
    setModalShow(true); // 모달 열기
  };

  const handleMaskingToggle = () => {
    setIsMaskingEnabled(prevState => !prevState);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: 1100 }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">권한 제어 설정</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label style={{ backgroundColor: '#ffdfa6', width: '100%', fontSize: 'large' }}>대상 정보</Form.Label>
            <Table bordered responsive style={{ borderWidth: '2px' }}>
              <thead>
                <tr>
                  <th style={{ width: '65%' }}>암호키 아이디</th>
                  <th>암호키 별칭</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {policyId}
                  </td>
                  <td>
                    {keyname}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Form.Label>선택 대상</Form.Label>
            <div className='p-1' style={{
              display: 'flex',
              flexWrap: 'nowrap', // 한 줄로 표시
              overflow: 'hidden', // 넘치는 내용 숨김
              whiteSpace: 'nowrap', // 한 줄로 유지
              width: '100%', // 부모 요소의 너비를 채우도록 설정
              border: 'solid 1px',
            }}>
              {selecteValues.length > 0 ? (
                selecteValues.slice(0, 4).map((item, index) => ( // 4개만 표시
                  <div className=" react-select__control" key={index} style={{
                    padding: '5px 10px',
                    // border: '1px solid #007bff',
                    // borderRadius: '5px',
                    // backgroundColor: '#c7f1ff',
                    // color: '#007bff',
                    marginRight: '5px',
                    flex: '0 0 auto', // 항목 너비 자동 조정
                  }}>
                    <span style={{
                      display: 'inline-block', // span을 inline-block으로 설정
                      maxWidth: '100%', // 부모의 최대 너비에 맞춤
                      whiteSpace: 'nowrap', // 한 줄로 유지
                      textOverflow: 'ellipsis', // 넘치는 부분에 '...' 표시
                    }}>
                      {`${item.group}:${item.keyname}`} {/* group:keyname 형식으로 표시 */}

                    </span>

                  </div>
                ))
              ) : (
                <p>선택된 대상이 없습니다.</p>
              )}
              {selecteValues.length > 4 && <div style={{
                padding: '5px 10px',
                color: '#007bff'
              }}>...</div>} {/* 5개 이상의 경우 '...' 표시 */}
            </div>
          </Form.Group>




          <Form.Group className="mb-3">
            <Form.Label style={{ backgroundColor: '#ffdfa6', width: '100%', fontSize: 'large' }}>제약 설정</Form.Label>
            <Row className="align-items-center mt-2" >
              <Col>
                암·복호화 권한 설정
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-start gap-2" style={{ whiteSpace: 'nowrap' }}>
                {/* < Button active={encryptActive} onClick={handleEncryptToggle}>
                  암호화 권한
                </Button>
                <Button active={decryptActive} onClick={handleDecryptToggle}>
                  복호화 권한
                </Button> */}

                <div className="p-2 mx-3 border border-secondary rounded  d-flex justify-content-between">
                  암호화 권한
                  <Form.Check
                    className='mx-1 hover'
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={encryptActive}
                    onChange={handleEncryptToggle}
                  />
                </div>
                <div className="p-2 mx-3 border border-secondary rounded  d-flex justify-content-between">
                  복호화 권한
                  <Form.Check
                    className='mx-1 hover'
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={decryptActive}
                    onChange={handleDecryptToggle}
                  />
                </div>

              </Col>

            </Row>
            <Row className="align-items-center mt-2" >
              <Col>
                복호화 마스킹 설정
              </Col>
              <Col>
                <div style={{
                  display: 'flex', alignItems: 'center',
                }}>
                  < span > 미설정 </span>
                  <Form.Check
                    className='mx-1 hover'
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={isMaskingEnabled}
                    onChange={handleMaskingToggle}
                  />
                  <span> 설정</span>

                </div>
              </Col>
              <Col>

              </Col>
              {isMaskingEnabled && (

                <span className='text-danger fs-11'> [주의] 해당 인증키로 암호화한 데이터를 복호화 시 설정된 데이터가 마스킹됩니다 . 마스킹 처리된 데이터는 복구할 수 없습니다.</span>

              )}
            </Row>

            <Row className="align-items-center mt-2" >
              <Col>
                <Form.Label>시작 순번</Form.Label>
                <Form.Control
                  type="text"
                  name="startnum"
                  defaultValue="1"
                  value={startNumber}
                  onChange={handleStartNumberChange} // 첫 번째 상태 업데이트
                  onBlur={handleStartNumberBlur} // 포커스 아웃 시 기본값 설정
                  disabled={!isMaskingEnabled}
                />
                <div style={{ minHeight: '40px' }}> {/* 고정된 공간을 확보 */}
                  {!isValidNumber && (
                    <span style={{ color: 'red' }}>
                      시작 최소값은 1입니다. <br /> 숫자만 입력 가능합니다.
                    </span>
                  )}
                </div>
              </Col>
              <Col>
                <Form.Label>크기</Form.Label>
                <Form.Control
                  type="text"
                  name="size"
                  defaultValue="0"
                  value={startNumber2}
                  onChange={handleSizeChange} // 상태 업데이트
                  onBlur={handleSizeBlur} // 포커스 아웃 시 기본값 설정
                  disabled={!isMaskingEnabled}
                />
                <div style={{ minHeight: '40px' }}> {/* 고정된 공간을 확보 */}
                  {!isValidSize && (
                    <span style={{ color: 'red' }}>
                      숫자만 입력 가능합니다.
                    </span>
                  )}
                </div>
              </Col>
              <Col>
                <Form.Label>마스킹 문자</Form.Label>
                <Form.Select
                  name="masking"
                  onChange={handleMaskingCharChange} // 마스킹 문자 변경 핸들러 추가
                  defaultValue="*"
                  aria-readonly
                  disabled={!isMaskingEnabled}
                ><option value="*">*</option>
                  <option value="#">#</option>
                  <option value="X">X</option>
                  <option value="O">●</option>
                </Form.Select>
                <div style={{ minHeight: '40px' }}> {/* 고정된 공간을 확보 */}

                </div>
              </Col>
            </Row>

          </Form.Group>
        </Modal.Body >
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="outline-danger" onClick={handledefaultClick}>
            초기화
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            적용
          </Button>
        </Modal.Footer>
      </Modal >

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleDefault}
        title="경고"
        body="모든 제어 정책 사항이 초기화되어 해당 대상의 제약 사항을 해제합니다. 정말 초기화 하시겠습니까?"
        variant="warning"
        cancelText="취소"
        confirmText="초기화"
        CancelButton={true}
        DefaultButton={true}
      />
    </>
  );
};

export default PolicyControlModal;
