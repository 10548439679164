import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { fetchEncPolicyList, updateEncryptKeyPolicy } from 'hooks/CallApi';
import { toast } from 'react-toastify';

const encryptKeyPolicyModal = ({ show, onHide, onConfirm, policyUuid }) => {
    // Table columns 설정
    const columns = [
        { accessorKey: 'status', header: '상태' },
        { accessorKey: 'group', header: '그룹명' },
        { accessorKey: 'keyname', header: '인증키 이름' },
        { accessorKey: 'role', header: '용도' },
        { accessorKey: 'aboutEnc', header: '암호화 권한' },
        { accessorKey: 'aboutDec', header: '복호화 권한' },
        { accessorKey: 'masking', header: '마스킹' },
        { accessorKey: 'createdAt', header: '등록일시' },
        { accessorKey: 'invalidAt', header: '만료일시' },
        { accessorKey: 'lastUsedAt', header: '최근 사용 일시' }
    ];

    // 적용 가능한 인증키 목록 데이터와 적용 대상 인증키 목록 데이터
    const [availableKeys, setAvailableKeys] = useState([]); // 서버에서 불러온 데이터 사용
    const [targetKeys, setTargetKeys] = useState([]);
    const [loading, setLoading] = useState(true); // 로딩 상태
    const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
    const [selectedAvailableRows, setSelectedAvailableRows] = useState([]);
    const [selectedTargetRows, setSelectedTargetRows] = useState([]);
    const [isDragging, setIsDragging] = useState(false); // 드래그 상태 
    const [movedToTargetKeys, setMovedToTargetKeys] = useState([]); // 새로 추가된 상태

    const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
    const [pageSize, setPageSize] = useState(10); // 페이지 크기
    const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수
    const [isChanged, setIsChanged] = useState(false); // 변경 상태를 관리하는 새로운 상태

    useEffect(() => {
        const loadAllData = async () => {
            let allData = [];

            setLoading(true);

            // 300ms 후에 스피너를 표시하는 타이머 설정
            const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

            try {
                // const data = await fetchEncPolicyList(policyUuid);
                const initialData = await fetchEncPolicyList(policyUuid, 0, 1);
                console.log("11", initialData);

                const totalElements = initialData.data.page.totalElements;
                console.log("22", totalElements);
                // totalElements를 사용하여 전체 데이터 불러오기
                const fullData = await fetchEncPolicyList(policyUuid, 0, totalElements);



                const content = fullData.data.content.map((item, index) => ({
                    ...item,
                    num: index + 1, // 순번 추가
                    status: item.state ? "정상" : "만료",
                    state: item.state,
                    group: item.groupName,
                    keyname: item.apiKeyName,
                    apikeyuuid: item.apiKeyUuid,
                    role: item.apiKeyType,
                    aboutEnc: item.encPermission,
                    aboutDec: item.decPermission,
                    masking: item.maskingEnabled,
                    createdAt: item.inserTimeUtc ? new Date(item.inserTimeUtc).toLocaleString('ko-KR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                    }) : '-',
                    invalidAt: item.expiredTimeUtc ? new Date(item.expiredTimeUtc).toLocaleString('ko-KR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                    }) : '-',
                    lastUsedAt: item.lastUsedTimeUtc ? new Date(item.lastUsedTimeUtc).toLocaleString('ko-KR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                    }) : '-',
                    selected: item.selected
                }));

                const removeDuplicates = (data, key) => {
                    return data.filter(
                        (item, index, self) => index === self.findIndex((t) => t[key] === item[key])
                    );
                };

                // 고유 데이터 필터링
                const uniqueAvailableKeys = removeDuplicates(
                    content.filter((item) => item.selected === "0"),
                    "apikeyuuid"
                );
                const uniqueTargetKeys = removeDuplicates(
                    content.filter((item) => item.selected === "1"),
                    "apikeyuuid"
                );

                setAvailableKeys(uniqueAvailableKeys);
                setTargetKeys(uniqueTargetKeys);
            } catch (error) {
                console.error('데이터 불러오기 에러:', error);
            } finally {
                clearTimeout(spinnerTimer);
                setLoading(false);
                setShowSpinner(false);

            }
        };

        if (show) { // 모달이 열릴 때만 데이터 로드
            loadAllData();
        }
    }, [show, policyUuid]);




    const availableTable = useAdvanceTable({
        data: availableKeys,
        columns,
        selection: true,
        perPage: 5
    });

    const targetTable = useAdvanceTable({
        data: targetKeys,
        columns,
        selection: true,
        perPage: 5
    });


    const handleRowSelect = (index, isAvailableTable) => {
        if (isAvailableTable) {
            setSelectedAvailableRows(prev =>
                prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
            );
        } else {
            setSelectedTargetRows(prev =>
                prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
            );
        }
    };
    const handleDragSelect = (index, isAvailableTable) => {
        if (!isDragging) return;

        if (isAvailableTable) {
            setSelectedAvailableRows(prev =>
                prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
            );
        } else {
            setSelectedTargetRows(prev =>
                prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
            );
        }
    };


    const handleMoveToTarget = () => {
        const itemsToMove = selectedAvailableRows.map(index => availableKeys[index]);
        setAvailableKeys(availableKeys.filter((_, index) => !selectedAvailableRows.includes(index)));
        setTargetKeys([...targetKeys, ...itemsToMove]);
        setSelectedAvailableRows([]);
        setIsChanged(true); // 이동이 발생하면 변경 상태 업데이트
    };

    const handleMoveToAvailable = () => {
        const itemsToMove = selectedTargetRows.map(index => targetKeys[index]);
        setTargetKeys(targetKeys.filter((_, index) => !selectedTargetRows.includes(index)));
        setAvailableKeys([...availableKeys, ...itemsToMove]);
        setSelectedTargetRows([]);
        setIsChanged(true); // 이동이 발생하면 변경 상태 업데이트
    };
    const handleConfirm = () => {
        const apiKeyPolicies = targetKeys.map((key) => ({
            apikeyUuid: key.apikeyuuid, // apikeyuuid 추가
            status: key.status,
            group: key.group,
            keyname: key.keyname,
            role: key.role,
            aboutEnc: key.aboutEnc ? 'O' : 'X',
            aboutDec: key.aboutDec ? 'O' : 'X',
            masking: key.masking ? '설정' : '미설정',
            createdAt: key.createdAt,
            invalidAt: key.invalidAt,
            lastUsedAt: key.lastUsedAt
        }));
        onConfirm(apiKeyPolicies);
        setIsChanged(false); // 변경 적용 후 비활성화

    };

    return (
        <Modal show={show} onHide={onHide} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>인증키 설정</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ userSelect: 'none' }}>
                <Row>
                    <Col>
                        <h5>적용 가능한 인증키 목록</h5>
                        <div
                            style={{
                                maxHeight: '250px', // 원하는 최대 높이 설정
                                overflowY: 'auto'   // 스크롤이 필요할 경우만 스크롤바 표시
                            }}
                        >
                            <table className="table table-responsive fs--1 mb-0 overflow-hidden"
                                onMouseDown={() => setIsDragging(true)}
                                onMouseUp={() => setIsDragging(false)}
                                style={{ maxHeight: '100%' }} // 높이를 고정하고 스크롤 활성화

                            >
                                <thead className="bg-200 text-nowrap align-middle">
                                    <tr>
                                        {columns.map(col => (
                                            <th key={col.accessorKey} className="fs-10">{col.header}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className='fs-11'>
                                    {availableKeys.map((item, index) => (
                                        <tr
                                            key={index}
                                            className={`align-middle ${item.status === '만료' ? 'text-muted' : ''} ${selectedAvailableRows.includes(index) ? 'bg-primary-subtle' : ''}`}
                                            onClick={() => handleRowSelect(index, true)}
                                            onMouseEnter={() => handleDragSelect(index, true)}
                                            style={{
                                                opacity: item.status === '만료' ? 0.5 : 1, // 상태에 따라 글씨 흐리게 적용
                                            }}
                                        >
                                            <td>{item.status}</td>
                                            <td>{item.group}</td>
                                            <td>{item.keyname}</td>
                                            <td>{item.role}</td>
                                            <td>{item.aboutEnc ? "O" : "X"}</td>
                                            <td>{item.aboutDec ? "O" : "X"}</td>
                                            <td>{item.masking ? "설정" : "미설정"}</td>
                                            <td>{item.createdAt}</td>
                                            <td>{item.invalidAt}</td>
                                            <td>{item.lastUsedAt}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <Col>
                            <Button variant="primary" onClick={handleMoveToTarget} className="mx-2">∨</Button>

                            <Button variant="primary" onClick={handleMoveToAvailable}>∧</Button>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col>
                        <h5>적용 대상 인증키 목록</h5>
                        <div
                            style={{
                                maxHeight: '250px', // 원하는 최대 높이 설정
                                overflowY: 'auto'   // 스크롤이 필요할 경우만 스크롤바 표시
                            }}
                        >
                            <table className="table table-responsive fs--1 mb-0 overflow-hidden"
                                onMouseDown={() => setIsDragging(true)}
                                onMouseUp={() => setIsDragging(false)}
                                style={{ maxHeight: '100%' }} // 높이를 고정하고 스크롤 활성화

                            >
                                <thead className="bg-200 text-nowrap align-middle">
                                    <tr>
                                        {columns.map(col => (
                                            <th key={col.accessorKey} className="fs-10">{col.header}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className='fs-11'>
                                    {targetKeys.map((item, index) => (
                                        <tr
                                            key={index}
                                            className={`align-middle ${item.status === '만료' ? 'text-muted' : ''} ${selectedTargetRows.includes(index) ? 'bg-primary-subtle' : ''}`}
                                            onClick={() => handleRowSelect(index, false)}
                                            onMouseEnter={() => handleDragSelect(index, false)}
                                            style={{
                                                opacity: item.status === '만료' ? 0.5 : 1, // 상태에 따라 글씨 흐리게 적용
                                            }}
                                        >
                                            <td>{item.status}</td>
                                            <td>{item.group}</td>
                                            <td>{item.keyname}</td>
                                            <td>{item.role}</td>
                                            <td>{item.aboutEnc ? "O" : "X"}</td>
                                            <td>{item.aboutDec ? "O" : "X"}</td>
                                            <td>{item.masking ? "설정" : "미설정"}</td>
                                            <td>{item.createdAt}</td>
                                            <td>{item.invalidAt}</td>
                                            <td>{item.lastUsedAt}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>취소</Button>
                <Button variant="primary" onClick={handleConfirm} disabled={!isChanged}>적용</Button>

            </Modal.Footer>
        </Modal >
    );
};

export default encryptKeyPolicyModal;
