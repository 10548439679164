import React from 'react';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from './lottie/celebration.json';

const Success = ({ reset, email }) => {
  const { setStep, setUser } = useAuthWizardContext();

  const emptyData = () => {
    setStep(1);
    setUser({});
    reset();
  };


  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="mx-auto pt-5">
              {/* <Lottie animationData={celebration} loop={true} /> */}
              <img className="me-2" src='/img/INISAFE_SAFEDB.png' alt="Logo" width='260px' />
            </div>
          </div>
          <h4 className="my-4">SafeDB 사용 신청이 완료되었습니다!</h4>
          <p className="fs-9">발송된 이메일 ( <strong>{email}</strong> ) 로 서비스 연동 신청에 대한 안내 사항을 발송해드렸습니다.
            <br></br>
            <br></br>
            감사합니다.
          </p>
          {/* <a href="/">
          <Button color="primary" className="px-5 my-3" >
            홈으로
          </Button>
          </a> */}
        </Col>
      </Row >
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
