// CheckOutModal.js
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import IconAlert from 'components/common/IconAlert';

const CheckOutModal = ({ show, onHide, onConfirm, title, body, variant, cancelText, confirmText, CancelButton, SubmitButton, DeleteButton, ContinueButton, DefaultButton }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 1100 }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title} {/* Props로 받은 제목 */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <IconAlert variant={variant}>
          {body} {/* Props로 받은 본문 내용 */}
        </IconAlert>
      </Modal.Body>
      <Modal.Footer>
        {CancelButton && (
          <Button onClick={onHide} variant="outline-secondary" className="me-2">
            {cancelText}
          </Button>
        )}
        {DeleteButton && (
          <Button onClick={onConfirm} variant="outline-secondary" className="me-2">
            {cancelText}
          </Button>
        )}
        {ContinueButton && (
          <Button onClick={onHide} variant="primary" className="me-2">
            {confirmText}
          </Button>
        )}
        {SubmitButton && (
          <Button variant="primary" onClick={onConfirm}>
            {confirmText}
          </Button>
        )}
        {DefaultButton && (
          <Button variant="danger" onClick={onConfirm}>
            {confirmText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CheckOutModal;
