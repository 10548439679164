import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { useAppContext } from 'providers/AppProvider';
import { Card } from 'react-bootstrap';
import { fetchDataSize } from 'hooks/CallApi';

const ChartOptions = () => {
  const {
    config: { dashboardDate, dashboardDateValue },
  } = useAppContext();

  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ timeData: [], successData: [] });

  useEffect(() => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const fetchData = async () => {
      try {
        const data = await fetchDataSize({ period: dashboardDateValue, timeZone: clientTimeZone });

        const timeData = data.data.map((item) => item.periodKst);
        const successData = data.data.map((item) => parseInt(item.size, 10) || 0);
        setChartData({ timeData, successData });
      } catch (error) {
        console.error('데이터 조회 에러:', error);
      }
    };

    fetchData();
  }, [dashboardDate, dashboardDateValue]);

  useEffect(() => {
    const isDataEmpty = chartData.successData.every((value) => value === 0);

    const option = {
      title: isDataEmpty
        ? {
          text: '데이터 없음',
          left: 'center',
          top: 'middle',
          textStyle: {
            color: '#aaa',
            fontSize: 16,
          },
        }
        : null,
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: (params) => {
          let tooltipText = `${params[0].name}<br/>`;
          params.forEach((item) => {
            if (item.seriesName === '성공') {
              tooltipText += `${item.marker} 성공: ${item.value}<br/>`;
            }
          });
          return tooltipText;
        },
      },
      legend: {
        data: ['성공'],
        bottom: 10,
      },
      grid: { left: '10%', right: '5%', bottom: '25%' },
      xAxis: {
        type: 'category',
        data: chartData.timeData.length > 0 ? chartData.timeData : [''],
        axisLabel: {
          formatter: (value) => (value ? new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''),
          show: !isDataEmpty, // 데이터가 없으면 axisLabel 숨김
        },
        axisLine: { show: true }, // x축 선은 항상 표시
        splitLine: { show: false },
      },
      yAxis: {
        type: 'value',
        min: 0,
        splitLine: { show: true },
        axisLabel: {
          formatter: function (value) {
            if (value >= 1e9) {
              return value / 1e9 + 'GB';
            } else if (value >= 1e6) {
              return value / 1e6 + 'MB';
            } else if (value >= 1e3) {
              return value / 1e3 + 'kB';
            } else {
              return value + 'B';
            }
          }
        }
      },
      series: [
        {
          name: '성공',
          type: 'bar',
          data: isDataEmpty ? [] : chartData.successData,
          itemStyle: { color: '#6abf40' },
        },
      ],
    };

    const chart = echarts.init(chartRef.current);
    chart.setOption(option);

    const handleResize = () => chart.resize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.dispose();
    };
  }, [chartData]);

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>지난 처리량</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          {dashboardDate}
        </span>
      </Card.Header>
      <div
        id="main"
        ref={chartRef}
        style={{
          width: '100%',
          maxWidth: '1200px',
          height: '15rem',
          margin: '0 auto',
        }}
      />
    </Card>
  );
};

export default ChartOptions;
