import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

const GroupAdvanceTable = ({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  onRowClick
}) => {
  const table = useAdvanceTableContext();
  const { getRowModel, getFlatHeaders } = table;

  const handleRowClick = (event, row) => {
    if (event.target.type !== 'checkbox' && onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <div className="table-responsive scrollbar">
      <Table {...tableProps}>
        <thead className={headerClassName}>
          <tr>
            {getFlatHeaders().map(header => (
              <th
                key={header.id}
                {...header.column.columnDef.meta?.headerProps}
                className={classNames(
                  'fs-10',
                  header.column.columnDef.meta?.headerProps?.className,
                  {
                    sort:
                      header.column.getCanSort() &&
                      header.column.columnDef.enableSorting,
                    desc: header.column.getIsSorted() === 'desc',
                    asc: header.column.getIsSorted() === 'asc'
                  }
                )}
                onClick={
                  header.column.getCanSort() &&
                  header.column.columnDef.enableSorting
                    ? header.column.getToggleSortingHandler()
                    : undefined
                }
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={bodyClassName}>
          {getRowModel().rows.map(row => (
            <tr
              key={row.id}
              className={rowClassName}
              onClick={event => handleRowClick(event, row)}
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} {...cell.column.columnDef.meta?.cellProps}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

GroupAdvanceTable.propTypes = {
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  onRowClick: PropTypes.func // 추가된 부분
};

export default GroupAdvanceTable;
