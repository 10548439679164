// mypage.js
import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Row, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'; // React Dropzone import 
import avatarImg from 'assets/img/team/avatar.png';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Flex from 'components/common/Flex';
import { ToastContainer, toast } from 'react-toastify'; // 추가
import "react-toastify/dist/ReactToastify.css"; // 추가
import "safedb/common/css/IniCustomCss.css"
import CheckOutModal from 'components/modal/CheckOutModal';
import { AiFillCloseCircle } from 'react-icons/ai';
import { handleValueDelete, goToParentPath } from 'components/common/SunnyUtils';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import ChangePasswordModal from 'safedb/common/changePasswordModal'; // ChangePasswordModal 임포트
import { updateAdminInfo } from 'hooks/CallApi'; // API 함수 임포트


const EditAdminAccount = () => {

  const [modalConfirmShow, setModalConfirmShow] = useState(false);
  const [modalConfirmCheckShow, setModalConfirmCheckShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false); // 비밀번호 변경 모달 상태 추가
  const handleClosePasswordModal = () => setShowPasswordModal(false);
  const handleShowPasswordModal = () => setShowPasswordModal(true);


  const [NameError, setNameError] = useState('');
  const [DescError, setDescError] = useState('');

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const location = useLocation();
  const { createdAt, updateAt, active, userId, username, userRole, imgUrl, userUuid, activestatus, companyid, companyName } = location.state || {}; // 추가 데이터 포함

  const handleDeleteModalClick = () => {
    setModalDeleteShow(true);
  };


  const navigate = useNavigate(); // useNavigate 훅 사용
  const handleDeleteRe = () => {
    navigate(`/safedb/SA/adminAccount`); // 데이터와 함께 이동

  };


  const initialFormData = {
    id: userId,
    password: '*****',
    name: username,
    role: userRole,
    org: companyid,
    status: active,
    createdAt: createdAt,
    updateAt: updateAt,
    imgUrl: imgUrl,
    description: '',
    userUuid: userUuid,
    companyName: companyName,
    activestatus: activestatus
  };


  const [formData, setFormData] = useState({
    id: userId,
    password: '*****',
    name: username,
    role: userRole,
    org: companyid,
    status: active,
    createdAt: createdAt,
    updateAt: updateAt,
    imgUrl: imgUrl,
    description: '',
    userUuid: userUuid,
    companyName: companyName,
    activestatus: activestatus
  });

  // 내용 변경 시 변경 버튼 눌렀을때
  const handleSubmitClick = () => {
    setModalConfirmShow(true);
  };

  useEffect(() => {
    console.log("초기 formData:", formData);
  }, []);
  const handleConfirm = async () => {

    try {
      const response = await updateAdminInfo(formData.userUuid, {
        imgUrl: formData.imgUrl,
        username: formData.name,
        active: formData.status === 'true', // 문자열을 Boolean으로 변환=
        userDescription: formData.description,
      });
      console.log("API 응답:", response);
      if (response) {
        setModalConfirmShow(false);
        setModalConfirmCheckShow(true);
        toast.success("정상 등록되었습니다.");
        navigate(`/safedb/SA/adminAccount`);
      } else {
        toast.success("매니저 정보 업데이트가 실패했습니다."); // Toast Notification 추가
      }
    } catch (error) {
      console.error('매니저 정보 업데이트 에러:', error);
      toast.error("매니저 정보 업데이트 중 오류가 발생했습니다.");
    }

  };


  const { user } = useAuthWizardContext();

  const [avatar, setAvatar] = useState([{ src: imgUrl || avatarImg }]);

  const handleChange = (e) => {
    const { name, value } = e.target;


    if (name === 'name' && value.length >= 30) {
      setNameError(`이름은 최대 30자까지 입력 가능합니다.`);
    } else {
      setNameError('');
    }

    if (name === 'description' && value.length >= 800) {
      setDescError(`관리자 계정 설명란 입력은 최대 800자까지 입력 가능합니다.`);
    } else {
      setDescError('');
    }


    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleComboChange = ({ target }) => {
    let name = target.name;
    let value = name === 'allDay' ? target.checked : target.value;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const imageFile = acceptedFiles[0];
      const imageUrl = URL.createObjectURL(imageFile);
      setAvatar([{ src: imageUrl }]);
      setFormData(prevData => ({
        ...prevData,
        imgUrl: imageUrl
      }));
    }
  };


  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpeg', '.jpg', '.png', '.gif'] }, // 이미지 파일만 허용
  });

  const isFormValid = () => {
    const hasChanged =
      formData.name !== initialFormData.name ||
      formData.status !== initialFormData.status ||
      formData.description !== initialFormData.description;

    return hasChanged;
  };

  const formatDateTimeForInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  return (
    <div className="container mt-5">
      <Card className="mypage-card" >
        <Link to={goToParentPath()} style={{ textDecoration: 'none', color: 'inherit', }}>

          <Card.Header className="px-3" as="h4">관리자 계정</Card.Header>
        </Link>
        <Card.Title className="border border-bottom-0 px-3 pt-3">  계정 상세 정보</Card.Title>
        <Card.Body className="border border-top-0">
          <Form onSubmit={handleSubmit}>
            <Row>
              {/* Left Column (Profile Image) */}
              <Col md={4} className="text-center profile-section">
                <div {...getRootProps({ className: 'dropzone' })}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '150px',
                    height: '150px',
                    position: 'relative',
                    margin: '0 auto',
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <input {...getInputProps()} />
                  <Flex justifyContent="center" >
                    <img
                      src={avatar.length > 0 ? avatar[0].src : cloudUpload}
                      alt=""
                      style={{
                        borderRadius: '50%', // 동그란 형태
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        objectFit: 'cover', // 이미지 비율 유지

                      }}
                    />
                    {isHovered && ( // hover 시 텍스트 표시
                      <div style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(0, 0, 0, 1)',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '50%', // 동그란 형태
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        pointerEvents: 'none', // 클릭 방지
                        objectFit: 'cover', // 이미지 비율 유지
                        display: 'flex', // Flexbox 사용
                        justifyContent: 'center', // 수평 중앙 정렬
                        alignItems: 'center', // 수직 중앙 정렬
                      }}>
                        이미지 변경
                      </div>
                    )}
                  </Flex>
                  {/* <p>여기에 이미지를 드래그하거나 클릭하여 업로드하세요.</p> */}
                </div>
                <p className="mt-3">[{formData.companyName}]</p> {/* Placeholder for company logo */}
              </Col>



              {/* Right Column (ID, Password, Name) */}
              <Col md={8}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>아이디 </Form.Label>
                      <Form.Control
                        type="text"
                        name="id"
                        value={formData.id}
                        onChange={handleChange}
                        className="input-hover"
                        placeholder="아이디를 입력해주세요."
                        disabled
                      />

                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>비밀번호 </Form.Label>
                      <InputGroup>
                        <FormControl
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          className="input-hover"
                          placeholder="비밀번호를 입력해주세요."
                          disabled
                        />

                      </InputGroup>
                      <Button variant="outline-info"
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '10px',
                          top: '55%',
                          transform: 'transformY(-50%)',
                          zIndex: 9,
                          padding: '5px 10px',
                          fontSize: '0.6rem',
                        }}
                        onClick={handleShowPasswordModal}
                      >
                        변경
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>

                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>이름 </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="input-hover"
                        maxLength='30'
                        placeholder="이름을 입력해주세요."
                      />
                      {NameError &&
                        <div style={{ color: 'red' }}>
                          {NameError}
                        </div>} {/* 에러 메시지 표시 */}
                      {formData.name && (
                        <span
                          onClick={(e) => handleValueDelete('name', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>역할</Form.Label>
                      <Form.Select
                        name="role"
                        onChange={handleComboChange}
                        value={formData.role}
                        disabled
                      >
                        <option value="SA">총괄 관리자</option>
                        <option value="ADMIN">시스템 관리자</option>
                        <option value="MANAGER">매니저</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>상태</Form.Label>
                      <Form.Select
                        name="status"
                        onChange={handleComboChange}
                        value={formData.activestatus ? "true" : "false"} // boolean을 string으로 변환
                      >
                        <option value="true">정상</option>
                        <option value="false">비정상</option>

                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>등록 일시</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        name="addDate"
                        value={formatDateTimeForInput(formData.createdAt)}

                        className="input-hover"
                        disabled

                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>최근 접속 일시</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        name="lastLogin"
                        value={formatDateTimeForInput(formData.updateAt)}
                        className="input-hover"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                  <Form.Label>설명</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    rows={3}
                    value={formData.description}
                    onChange={handleChange}
                    className="input-hover"
                    maxLength='800'
                  />
                  {DescError &&
                    <div style={{ color: 'red' }}>
                      {DescError}
                    </div>} {/* 에러 메시지 표시 */}
                  {formData.description && (
                    <span
                      onClick={(e) => handleValueDelete('description', formData, setFormData)}
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'transformY(-50%)',
                        zIndex: 9
                      }}
                    >
                      <AiFillCloseCircle />
                    </span>
                  )}
                </Form.Group>

              </Col>




            </Row>

            {/* Cancel and Confirm Buttons */}
            <div className="d-flex justify-content-between mt-3">
              <Button variant="outline-danger" onClick={handleDeleteModalClick} style={{ width: '100px' }}>
                삭제
              </Button>
              <Button variant="primary" type="submit" onClick={handleSubmitClick} disabled={!isFormValid()} style={{ width: '100px' }}>
                변경
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <CheckOutModal
        show={modalDeleteShow}
        onHide={() => setModalDelte(false)}
        onConfirm={handleDeleteRe}
        title="경고" // 제목
        variant="warning"
        body="선택하신 관리자에 대한 모든 설정값 및 권한 정보가 삭제됩니다. 이 작업은 취소할 수 없으며, 삭제된 데이터는 복구할 수 없습니다. 정말 삭제하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="삭제" // 확인 버튼 텍스트
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalConfirmShow}
        onHide={() => setModalConfirmShow(false)}
        onConfirm={handleConfirm}
        title="확인" // 제목
        body="변경하신 사항으로 계정 정보를 수정하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalConfirmCheckShow}
        onHide={() => setModalConfirmCheckShow(false)}
        variant="success"
        onConfirm={handleConfirm}
        title="확인" // 제목
        body="변경하신 사항이 해당 계정 정보에 저장되었습니다." // 본문 내용
        SubmitButton={false}
      />
      <ChangePasswordModal
        show={showPasswordModal}
        onHide={handleClosePasswordModal}
        id={formData.id}
        name={formData.name}
      />
    </div >


  );
};

export default EditAdminAccount;
