import NavbarVertical from 'components/navbar/vertical/NavbarVertical';

import React from 'react';
import { ComboLayoutNavbarTop } from 'components/navbar/top/ComboLayoutNavbarTop';
import { Outlet } from 'react-router-dom';

const ComboNavLayout = () => {

  return (
    <div className="container">
      <NavbarVertical />
      {/* <ProductProvider> */}
      <div className="content">
        <ComboLayoutNavbarTop />
        <Outlet />
      </div>
      {/* </ProductProvider> */}

    </div>
  );
};

export default ComboNavLayout;
