import React, { useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';

import * as echarts from 'echarts';
import { useAppContext } from 'providers/AppProvider';




const SiteAverageCfProcess = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    const customerData = [
      {
        name: '고객사 A',
        concurrentCalls: 34000,
        avgRequestSize: 40000,
        avgProcessingTime: 0.8,
        coldStarts: 1,
        assignedCapacity: 30000,
        normalProcessing: 2000,
        delayedProcessing: 30000,
        excessSpeed: 0.4
      },
      {
        name: '고객사 B',
        concurrentCalls: 45000,
        avgRequestSize: 60000,
        avgProcessingTime: 0.5,
        coldStarts: 0,
        assignedCapacity: 30000,
        normalProcessing: 2500,
        delayedProcessing: 15000,
        excessSpeed: 0.4
      },
      {
        name: '고객사 C',
        concurrentCalls: 50000,
        avgRequestSize: 55000,
        avgProcessingTime: 0.6,
        coldStarts: 2,
        assignedCapacity: 30000,
        normalProcessing: 3000,
        delayedProcessing: 20000,
        excessSpeed: 0.4
      },
      {
        name: '고객사 D',
        concurrentCalls: 60000,
        avgRequestSize: 70000,
        avgProcessingTime: 0.9,
        coldStarts: 3,
        assignedCapacity: 30000,
        normalProcessing: 3500,
        delayedProcessing: 25000,
        excessSpeed: 0.4
      },
      {
        name: '고객사 E',
        concurrentCalls: 28000,
        avgRequestSize: 40000,
        avgProcessingTime: 0.3,
        coldStarts: 0,
        assignedCapacity: 30000,
        normalProcessing: 1000,
        delayedProcessing: 5000,
        excessSpeed: 0.4
      },
    ];

    const coldStartColorMap = {
      0: '#5cb85c',
      1: '#0167a3',
      2: '#0f4a8b',
      3: '#003366'
    };
    const formatNumber = (value) => {
      if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + 'M';
      } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + 'K';
      } else {
        return value;
      }
    };

    const option = {
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          const data = params.data;
          let tooltipText = `<strong>${data[3]}</strong><br/>
                            동시 요청 건수: ${formatNumber(data[0])} 건<br/>
                            평균 요청 용량: ${(data[2] / 1000).toFixed(1)} MB<br/>
                            평균 처리 속도: ${data[1]}(s)<br/>
                            정상 처리: ${formatNumber(data[5])} 건<br/>`;

          if (data[5] !== undefined && data[6] !== undefined) {
            tooltipText += `지연 처리: ${formatNumber(data[6])} 건`;
            if (data[4] > 0) {
              tooltipText += `, ${data[7]}초 초과<br/>(콜드 스타트 포함)<br><br/>`;
            } else {
              tooltipText += `<br/>`;
            }
          }

          tooltipText += `적용 기준: 평균 처리 ${0.4}초`;

          return tooltipText;
        }
      },
      legend: {
        data: ['콜드 스타트 0건', '콜드 스타트 1건', '콜드 스타트 2건', '콜드 스타트 3건 이상'],
        bottom: '5%'
      },
      grid: {
        left: '10%',
        right: '15%',
        bottom: '15%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        name: '동시 요청 건 수',
        scale: true,
        splitLine: { show: true },
        axisLabel: {
          formatter: function (value) {
            return formatNumber(value) + ' 건';
          }
        }
      },
      yAxis: {
        type: 'value',
        name: '평균 처리 속도',
        splitLine: { show: true },
        axisLabel: { formatter: '{value} 초' }
      },
      series: Object.keys(coldStartColorMap).map((coldStart) => ({
        name: `콜드 스타트 ${coldStart}건`,
        type: 'scatter',
        data: customerData
          .filter(c => c.coldStarts === parseInt(coldStart))
          .map(c => [
            c.concurrentCalls,
            c.avgProcessingTime,
            c.avgRequestSize,
            c.name,
            c.coldStarts,
            c.normalProcessing,
            c.delayedProcessing,
            c.excessSpeed
          ]),
        symbolSize: function (data) {
          return Math.sqrt(data[2] / 10);
        },
        label: {
          emphasis: { show: true, formatter: '{b}', position: 'right' }
        },
        itemStyle: { color: coldStartColorMap[coldStart] }
      }))
    };

    chartInstance.setOption(option);
    const handleResize = () => chartInstance.resize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chartInstance.dispose();
    };
  }, []);

  const {
    config: { dashboardDate },
    setConfig
  } = useAppContext();

  return (
    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0, flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>고객별 평균 CF 동시 처리량</h5>
        <span
          style={{
            marginLeft: '6px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 6px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
            flexWrap: 'nowrap', // 한 줄로 표시
            whiteSpace: 'nowrap', // 한 줄로 유지
          }}
        >
          {dashboardDate}

        </span>
      </Card.Header>
      <Card.Body>
        <div ref={chartRef} style={{ width: '100%', height: '20rem' }} />

      </Card.Body>

    </Card>
  );
}
export default SiteAverageCfProcess;
