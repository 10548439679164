import React, { useState, useEffect } from 'react';

import { Card, Button, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { useNavigate } from 'react-router-dom';
// import data from './sampledata/sampledataClient';
import CheckOutModal from 'components/modal/CheckOutModal';
import paths from 'routes/paths';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faChartBar } from '@fortawesome/free-solid-svg-icons';
import AdminPasswordAlertModal from 'components/modal/AdminPasswordAlertModal';
import { useAppContext } from 'providers/AppProvider';
import { requestSiteToken, fetchCompanyList } from 'hooks/CallApi'; // API 함수 가져오기



function AdvanceTableExample() {

  // const initialData = data.map(item => ({ ...item, status: false }));

  const navigate = useNavigate();


  // const [tableData, setTableData] = useState(initialData);
  const [tableData, setTableData] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [passwordModalShow, setPasswordModalShow] = useState(false);
  const [modalReShow, setModalReShow] = useState(false);

  const [totalElements, setTotalElements] = useState(0);
  const [pageSize, setPageSize] = useState(10); // 초기 페이지 사이즈 설정
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가


  const {
    config: { checkorg, siteToken, userId },
    setConfig
  } = useAppContext();


  const columns = [
    {
      accessorKey: 'num',
      header: '순번',
      meta: { headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'createdAt',
      header: '등록일시',
      meta: { headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'clientName',
      header: '고객사명',
      meta: { headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'clientEmail',
      header: '고객사이메일',
      meta: { headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'clientPhone',
      header: '고객사연락처',
      meta: { headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'clientAddress',
      header: '고객사주소',
      meta: { headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'dashboard',
      header: '관리',
      cell: ({ row }) => (
        <Button
          variant="link"
          id={`status-switch-${row.id}`}
          // checked={row.original.status}
          onClick={event => {
            event.stopPropagation(); // RowClick 이벤트 전파 방지
            handleStatusChange(row);
          }}>
          <FontAwesomeIcon icon={faChartBar} />
        </Button>
      ),
      meta: { headerProps: { className: 'text-900' } }
    },
    // {
    //   accessorKey: 'status',
    //   header: '활성화',
    //   cell: ({ row }) => (
    //     <Form.Check
    //       type="switch"
    //       id={`status-switch-${row.id}`}
    //       checked={row.original.status}
    //       onChange={e => handleStatusChange(row)}
    //       onClick={e => e.stopPropagation()}
    //     />
    //   ),
    //   meta: { headerProps: { className: 'text-900' } }
    // }
  ];


  const table = useAdvanceTable({
    data: tableData,
    columns,
    sortable: true,
    selection: true,
    pagination: true,
    // perPage: 10,
    perPage: pageSize,
    selectionColumnWidth: 30
  });

  // API 호출 
  useEffect(() => {
    // 전체 데이터 로드를 위한 함수
    const loadAllData = async () => {
      let currentPage = 0;
      let allData = [];

      // 300ms 후에 스피너를 표시하는 타이머 설정
      const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

      try {
        const totalData = await fetchCompanyList(0, pageSize);
        const totalPages = totalData.data.page.totalPages; // totalPages를 통해 페이지 수 계산

        for (currentPage = 0; currentPage < totalPages; currentPage++) {
          const data = await fetchCompanyList(currentPage, pageSize);
          const content = data.data.content.map((item, index) => ({
            ...item,
            num: currentPage * pageSize + index + 1, // 순번 추가 (전체 순번 기준)
            createdAt: new Date(item.insertTimeUtc).toLocaleString('ko-KR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false, // 24시간제
            }), // 등록일시를 형식 변경
            updateAt: new Date(item.updateTimeUtc).toLocaleString('ko-KR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false, // 24시간제
            }),
            imgUrl: item.imgUrl,
            active: item.active,
            description: item.description,
            clientName: item.name, // 고객사명
            clientEmail: item.email, // 고객사 이메일
            clientPhone: item.tel, // 고객사 연락처
            clientAddress: item.address, // 고객사 주소
            companyId: item.companyId
          }));

          allData = [...allData, ...content];
        }
        setTableData(allData);
        setTotalElements(totalData.data.page.totalElements); // 총 요소 수 설정
      } catch (error) {
        console.error('데이터 불러오기 에러:', error);
      } finally {
        clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
        setLoading(false);
        setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
      }
    };

    loadAllData();
  }, [pageSize]); // pageSize가 변경될 때마다 loadAllData 실행


  const handleRowClick = row => {
    const { createdAt, updateAt, imgUrl, active, description, clientName, clientEmail, clientPhone, clientAddress, companyId } = row.original; // 필요한 데이터 추출
    navigate(`/safedb/SA/adminSites/edit`, {
      state: { createdAt, updateAt, imgUrl, active, description, clientName, clientEmail, clientPhone, clientAddress, companyId } // 전달할 데이터
    });
  };

  const handleDeleteClick = () => {
    setModalShow(true);
  };

  const handleConfirmDelete = () => {
    setModalShow(false);
    // setPasswordModalShow(true);
    const selectedRowIds = table
      .getSelectedRowModel()
      .rows.map(row => row.original.id);
    const newData = tableData.filter(row => !selectedRowIds.includes(row.id)); // 선택된 행 제외
    setTableData(newData); // 데이터 업데이트
    table.resetRowSelection();

    toast.error(`삭제가 완료되었습니다.`, {
      theme: 'colored'
    });
  };

  const handleConfirmPassword = () => {
    setPasswordModalShow(false);
    setModalReShow(true);
  };


  const selectedRowCount = table.getSelectedRowModel().rows.length;

  const handleDeleteRe = () => {
    const selectedRowIds = table
      .getSelectedRowModel()
      .rows.map(row => row.original.id);
    const newData = tableData.filter(row => !selectedRowIds.includes(row.id));
    setTableData(newData);
    table.resetRowSelection();
    setModalReShow(false);
  };

  // const handleStatusChange = row => {
  //   const updatedData = tableData.map(item => {
  //     // ...item,
  //     // status: item.id === row.original.id, // 선택된 항목만 활성화, 나머지는 비활성화
  //     const isActive = item.id === row.original.id; // 선택된 항목만 활성화
  //     if (isActive) {
  //       // 선택된 항목의 '고객사명'을 설정
  //       setConfig('checkorg', row.original.clientName);
  //     }
  //     return { ...item, status: isActive };

  //   });
  //   setTableData(updatedData);
  // };
  const handleStatusChange = async (row) => {
    const { companyId, clientName } = row.original;
    try {
      const refreshToken = sessionStorage.getItem('refreshToken');
      const impersonationData = {
        username: userId,
        refreshToken,
        companyId
      };
      const { token } = await requestSiteToken(impersonationData);

      sessionStorage.setItem('siteToken', token); // 새로 발급받은 토큰을 세션에 저장
      setConfig('checkorg', clientName); // 선택된 고객사 이름 설정

      navigate('/');
    } catch (error) {
      console.error('관리 모드로 전환하는데 실패했습니다:', error);
    }
  };
  return (
    <AdvanceTableProvider {...table}>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }} className="mb-2">

        {/* <p style={{ margin: 0, fontWeight: 700 }}>{data.length}</p> */}
        <AdvanceTableFooter rowsPerPageSelection />
        <Button
          variant="success"
          style={{
            position: 'absolute',
            right: 120,
            top: -8,
            width: '100px'
          }}
          disabled={selectedRowCount === 0}
        >
          <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '5px' }} />
          Excel
        </Button>
        {selectedRowCount > 0 ? (
          <Button
            variant="outline-danger"
            style={{
              position: 'absolute',
              right: 10,
              top: -8,
              width: '100px'
            }}
            onClick={handleDeleteClick}
          >
            삭제
          </Button>
        ) : (
          <Link to={paths.addadminSites} style={{ textDecoration: 'none' }}>
            <Button
              variant="outline-success"
              style={{
                position: 'absolute',
                right: 10,
                top: -8,
                width: '100px'

              }}
            // disabled
            >
              등록
            </Button>
          </Link>
        )}
      </div>
      {loading ? (
        showSpinner ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <p></p>
        )
      ) : (
        <AdvanceTable
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            striped: true,
            className: 'fs-10 mb-0 overflow-hidden'
          }}
          onRowClick={handleRowClick}
        />
      )}
      <div className="mt-3">
        <p style={{ margin: 0, fontWeight: 700 }}>총 {tableData.length}건</p>
        <AdvanceTablePagination />
      </div>

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleConfirmDelete}
        title="경고"
        body="선택하신 관리자에 대한 모든 설정값 및 권한 정보가 삭제됩니다. 이 작업은 취소할 수 없으며, 삭제된 데이터는 복구할 수 없습니다. 정말 삭제하시겠습니까?"
        cancelText="취소"
        confirmText="삭제"
        variant="warning"
        CancelButton={true}
        SubmitButton={true}
      />
      <AdminPasswordAlertModal
        show={passwordModalShow}
        onHide={() => setPasswordModalShow(false)}
        onConfirm={handleConfirmPassword}
      />
      <CheckOutModal
        show={modalReShow}
        onHide={() => setModalReShow(false)}
        onConfirm={handleDeleteRe}
        title="완료"
        body="삭제 되었습니다."
        confirmText="확인"
        variant="success"
        singleConfirm={true}
      />
    </AdvanceTableProvider>
  );
}

const adminSites = () => {
  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header as="h4">고객사 관리</Card.Header>
        <Card.Body>
          <AdvanceTableExample />
        </Card.Body>
      </Card>
    </div>
  );
};

export default adminSites;
