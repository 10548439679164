import React, { useState } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Row, Col } from 'react-bootstrap';
import avatarImg from 'assets/img/team/avatar.png';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Flex from 'components/common/Flex';

import "safedb/common/css/IniCustomCss.css"
import CheckOutModal from 'components/modal/CheckOutModal';
import { AiFillCloseCircle } from 'react-icons/ai';
import { handleValueDelete } from 'components/common/SunnyUtils';


const AddSiteAdminAccount = ({ setValue, handleClose }) => {

  const [modalShow, setModalShow] = useState(false); // 관리자 비밀번호 모달 상태

  const [NameError, setNameError] = useState('');
  const [DescError, setDescError] = useState('');

  const [passwordError, setPasswordError] = useState('');  // 비번관련 에러 처리용
  const [showpasswordError, setShowPasswordError] = useState('');  // 비번관련 에러 처리용
  const [showError, setShowError] = useState(false); // 에러 메시지 표시 상태 및 서버 에러 상태 처리용


  const handleCheckOutModal = () => {
    setModalShow(false); // 관리자 모달 닫기  
    // 비밀번호 유효성 검사
    const passwordPattern = /^(?=.*[a-z]{1,})(?=.*[A-Z]{1,})(?=.*\d{1,})(?=.*[!@#$%^_\-\+=\{\}\[\]:;"'?.,/~]{1,})[a-zA-Z\d!@#$%^_\-\+=\{\}\[\]:;"'?.,/~]+$/;
    const allowedSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/; // 허용하는 특수문자

    // 비밀번호에 허용되지 않는 특수문자가 포함되어 있는지 체크
    const hasInvalidSpecialChar = /[^a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    if (hasInvalidSpecialChar.test(formData.password)) {
      alert('비밀번호에는 허용되지 않는 특수문자가 포함되어 있습니다.');
      return;
    }
    if (!passwordPattern.test(formData.password)) {
      alert('비밀번호는 대문자, 소문자, 숫자 및 특수 문자를 포함해야 합니다.');
      return;
    }

  };

  const handleCancelClick = () => {
    setModalShow(true); // 관리자 비밀번호 모달 열기
  };

  const [formData, setFormData] = useState({
    id: '',
    password: '',
    passwordConfirm: '',
    name: '',
    role: '',
    description: '',
  });
  const { user } = useAuthWizardContext();

  const [avatar, setAvatar] = useState([
    ...(user.avatar ? user.avatar : []),
    { src: avatarImg }
  ]);


  const handleChange = (e) => {
    const { name, value } = e.target;


    if (name === 'name' && value.length >= 30) {
      setNameError(`이름은 최대 30자까지 입력 가능합니다.`);
    } else {
      setNameError('');
    }

    if (name === 'description' && value.length >= 800) {
      setDescError(`관리자 계정 설명란 입력은 최대 800자까지 입력 가능합니다.`);
    } else {
      setDescError('');
    }



    // 비밀번호 일치 검증
    if (name === 'passwordConfirm') {
      if (value !== formData.password) {
        setPasswordError('비밀번호가 일치하지 않습니다.');
      } else {
        setPasswordError('');
      }
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleComboChange = ({ target }) => {
    let name = target.name;
    let value = name === 'allDay' ? target.checked : target.value;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowError(false); // 이전 에러 메시지 초기화

    if (passwordError) {
      setErrorMsg('비밀번호가 비밀번호 정책에 위반됩니다.')
      setShowError(true);
      return;
    }

    if (formData.password != formData.passwordConfirm) {
      setErrorMsg('입력하신 비밀번호와 다릅니다.')
      setShowError(true);
      return;
    }
  };


  const isFormValid = () => {
    const { id, name, password, passwordConfirm } = formData;
    return id && name && password && passwordConfirm;
  };

  return (
    <div className="container mt-5">
      <Card className="mypage-card" >
        <Card.Header className="px-3" as="h4">관리자 계정</Card.Header>
        <Card.Title className="border border-bottom-0 px-3 pt-3">  관리자 계정 등록</Card.Title>
        <Card.Body className="border border-top-0">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={4} className="text-center profile-section">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '150px',
                    height: '150px',
                    position: 'relative',
                    margin: '0 auto',
                  }}
                >
                  <Flex justifyContent="center" >
                    <img
                      src={avatar.length > 0 ? avatar[0].src : avatar[0].src}
                      alt=""
                      style={{
                        borderRadius: '50%', // 동그란 형태
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        objectFit: 'cover', // 이미지 비율 유지 
                      }}
                    />
                  </Flex>
                </div>

              </Col>



              {/* Right Column (ID, Password, Name) */}
              <Col md={8}>


                <Row>

                  <Col>

                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>아이디<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="id"
                        value={formData.id}
                        onChange={handleChange}
                        className="input-hover"
                        placeholder="아이디를 입력해주세요."
                      />
                      {formData.id && (
                        <span
                          onClick={(e) => handleValueDelete('id', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>


                {/* Password */}

                <Row>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>비밀번호<span style={{ color: 'red' }}>*</span></Form.Label>
                      <InputGroup>
                        <FormControl
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          className="input-hover"
                          placeholder="비밀번호를 입력해주세요."
                        />

                      </InputGroup>
                      {formData.password && (
                        <span
                          onClick={(e) => handleValueDelete('password', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>비밀번호 확인<span style={{ color: 'red' }}>*</span></Form.Label>
                      <InputGroup>
                        <FormControl
                          type="password"
                          name="passwordConfirm"
                          value={formData.passwordConfirm}
                          onChange={handleChange}
                          className="input-hover"
                          placeholder="확인을 위해 비밀번호를 다시 입력해주세요."
                        />

                      </InputGroup>
                      {formData.passwordConfirm && (
                        <span
                          onClick={(e) => handleValueDelete('passwordConfirm', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  {passwordError && (
                    <p style={{ color: 'red', fontSize: '0.8rem' }}>{passwordError}</p>
                  )}
                  <p style={{ fontSize: '0.8rem' }}>
                    비밀번호는 최소 [n]자 이상 ~ [N]자 이하의 영문 문자 [x]개와
                    숫자[a], 다음 특수문자 중 [s]자 이상을 포함해야 합니다.
                  </p>
                </Row>

                {/* Name */}
                <Row>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>이름<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="input-hover"
                        maxLength='30'
                        placeholder="이름을 입력해주세요."
                      />
                      {NameError &&
                        <div style={{ color: 'red' }}>
                          {NameError}
                        </div>} {/* 에러 메시지 표시 */}
                      {formData.name && (
                        <span
                          onClick={(e) => handleValueDelete('name', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>역할</Form.Label>
                      <Form.Select
                        name="role"
                        onChange={handleComboChange}
                        defaultValue="SA"
                      >

                        <option value="Admin">시스템 관리자</option>
                        <option value="User">매니저</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                  <Form.Label>설명</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    rows={3}
                    value={formData.description}
                    onChange={handleChange}
                    className="input-hover"
                    maxLength='800'
                  />
                  {DescError &&
                    <div style={{ color: 'red' }}>
                      {DescError}
                    </div>} {/* 에러 메시지 표시 */}
                  {formData.description && (
                    <span
                      onClick={(e) => handleValueDelete('description', formData, setFormData)}
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'transformY(-50%)',
                        zIndex: 9
                      }}
                    >
                      <AiFillCloseCircle />
                    </span>
                  )}
                </Form.Group>

              </Col>
            </Row>

            {/* Cancel and Confirm Buttons */}
            <div className="d-flex justify-content-end mt-3">
              <Button variant="secondary" className="me-2" onClick={() => window.history.back()}>
                취소
              </Button>
              <Button variant="primary" type="submit" disabled={!isFormValid()} onClick={handleCancelClick}>
                등록
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleCheckOutModal}
        title="확인" // 제목
        body="작성하신 계정으로 관리자 계정을 등록하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="warning" // 원하는 variant
        SubmitButton={true}
      />
    </div >


  );
};

export default AddSiteAdminAccount;
