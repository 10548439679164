import React, { useState } from 'react';
import { Button, Form, Modal, InputGroup, FormControl } from 'react-bootstrap';
import IconAlert from 'components/common/IconAlert';

const AdminPasswordAlertModal = ({ show, onHide, onConfirm }) => {

  const [password, setPassword] = useState(''); // 비밀번호 상태 추가

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 1100 }}
      backdrop="static" // 추가: 외부 클릭 시 모달이 닫히지 않도록 설정

    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          관리자 확인
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {['warning'].map(variant => (
          <IconAlert key={variant} variant={variant}>
            관리자 확인이 필요한 작업입니다. 관리자 비밀번호를 입력하십시오.
          </IconAlert>
        ))}
        <Form.Group className="mb-3">
          <Form.Label>
            관리자 비밀번호<span style={{ color: 'red' }}>*</span>
          </Form.Label>
          <InputGroup>
            <FormControl
              placeholder="관리자 비밀번호를 입력해 주세요."
              type="password"
              name="password"
              className="input-field"
              value={password}
              onChange={handlePasswordChange}
            />
          </InputGroup>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="outline-secondary" className="me-2">
          취소
        </Button>
        <Button variant="primary" onClick={onConfirm} disabled={!password}>
          확인
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdminPasswordAlertModal;
