import React, { useState, useEffect } from 'react';

import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { useNavigate } from 'react-router-dom'; // useNavigate 추가
import data from 'safedb/common/sampledata/sampledataPubKey'; // data.js 파일에서 데이터 가져오기
import CheckOutModal from 'components/modal/CheckOutModal';
import paths from 'routes/paths';
import { fetchPubKeyList } from 'hooks/CallApi';

const columns = [
  {
    accessorKey: 'id',
    header: '순번',
    meta: { headerProps: { className: 'text-900' } }
  },
  {
    accessorKey: 'publickeyName',
    header: '공유키',
    meta: { headerProps: { className: 'text-900' } }
  },
  {
    accessorKey: 'algorithm',
    header: '알고리즘',
    meta: { headerProps: { className: 'text-900' } }
  },
  {
    accessorKey: 'keylength',
    header: '키 길이(bit)',
    meta: { headerProps: { className: 'text-900' } }
  },

  {
    accessorKey: 'mode',
    header: '운영모드',
    meta: { headerProps: { className: 'text-900' } }
  },
  {
    accessorKey: 'desc',
    header: '설명',
    meta: { headerProps: { className: 'text-900' } }
  }
];

function AdvanceTableExample() {
  const navigate = useNavigate(); // useNavigate 훅 사용

  const [tableData, setTableData] = useState([]); // 데이터 상태 추가
  const [modalShow, setModalShow] = useState(false); // 모달 상태
  const [modalReShow, setModalReShow] = useState(false); // 모달 상태



  // // API 호출 
  // useEffect(() => {
  //   const fetchGroups = async () => {
  //     try {
  //       const response = await fetchPubKeyList();
  //       console.log(response); // API 응답 데이터 확인
  //       const PubData = response.data.content.map(item => ({
  //         algorithm: item.algo,
  //         insertTime: item.insertTimeUtc,
  //         keyLength: item.keyLen,
  //         mode: item.mode,
  //         sharedkeyUuid: item.sharedKeyId.sharedkeyUuid,
  //         publickeyName: item.sharedkeyName,
  //       }));
  //     } catch (error) {
  //       console.error('그룹 목록 불러오기 실패:', error);
  //     }
  //   };
  //   fetchGroups();
  // }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        // 첫 번째 호출: totalElements 구하기 위해 page=0, size=1로 호출
        const initialResponse = await fetchPubKeyList(0, 1);
        const totalElements = initialResponse.data.totalElements;

        // 두 번째 호출: 모든 데이터를 가져오기 위해 totalElements 사용
        const fullResponse = await fetchPubKeyList(0, totalElements);
        const PubData = fullResponse.data.content.map((item, index) => ({
          id: index + 1,
          algorithm: item.algo,
          keylength: item.keyLen,
          mode: item.mode,
          publickeyName: item.sharedkeyName, // 공유키 이름
          sharedkeyUuid: item.sharedkeyId.sharedkeyUuid, // 공유키 UUID
          insertTime: item.insertTimeUtc, // 등록 일시
          description: item.description, // 설명
        }));

        setTableData(PubData);
      } catch (error) {
        console.error('그룹 목록 불러오기 실패:', error);
      }
    };
    fetchAllData();
  }, []);

  const handleRowClick = (row) => {
    const { publickeyName, algorithm, keylength, mode } = row.original; // 필요한 데이터 추출
    navigate(`/safedb/common/key/encryptPublicKey/edit?publickeyName=${publickeyName}&algorithm=${algorithm}&keylength=${keylength}&mode=${mode}`); // 데이터와 함께 이동
  };


  const handleDeleteClick = () => {
    setModalShow(true); // 관리자 비밀번호 모달 열기
  };

  const table = useAdvanceTable({
    // data, // 전체 데이터를 전달
    data: tableData, // 상태에서 데이터 가져오기
    columns,
    sortable: true,
    selection: true,
    pagination: true,
    perPage: 10,
    selectionColumnWidth: 30
  });

  const selectedRowCount = table.getSelectedRowModel().rows.length;

  const handleDelete = () => {
    setModalShow(false);
    setModalReShow(true);
  };

  const handleDeleteRe = () => {
    const selectedRowIds = table
      .getSelectedRowModel()
      .rows.map(row => row.original.id);
    const newData = tableData.filter(row => !selectedRowIds.includes(row.id)); // 선택된 행 제외
    setTableData(newData); // 데이터 업데이트
    table.resetRowSelection();
    setModalReShow(false);
  };




  return (

    <AdvanceTableProvider {...table}>
      {/* <BulAction rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> */}
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }} className="mb-2">

        <AdvanceTableFooter rowsPerPageSelection />
        {selectedRowCount > 0 ? (
          <Button
            variant="outline-danger"
            style={{
              position: 'absolute',
              right: 10,
              top: -8,
              width: '100px'
            }}
            onClick={handleDeleteClick}
          >
            삭제
          </Button>
        ) : (
          <Link to={paths.addencryptPublicKey} style={{ textDecoration: 'none' }}>
            <Button
              variant="outline-success"
              style={{
                position: 'absolute',
                right: 10,
                top: -8,
                width: '100px'
              }}
            >
              등록
            </Button>
          </Link>
        )}
      </div>
      <AdvanceTable
        headerClassName="bg-200 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          striped: true,
          className: 'fs-10 mb-0 overflow-hidden'
        }}
        onRowClick={handleRowClick} // 이벤트 핸들러 전달

      />
      <div className="mt-3">
        {/* <p style={{ margin: 0, fontWeight: 700 }}>총 {data.length}건</p> */}
        <AdvanceTablePagination />
      </div>

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleDelete}
        title="경고" // 제목
        body="선택하신 관리자에 대한 모든 설정값 및 권한 정보가 삭제됩니다. 이 작업은 취소할 수 없으며, 삭제된 데이터는 복구할 수 없습니다. 정말 삭제하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="삭제" // 확인 버튼 텍스트
        variant="warning" // 원하는 variant
        CancelButton={true}
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalReShow}
        onHide={() => setModalReShow(false)}
        onConfirm={handleDeleteRe}
        title="완료" // 제목
        body="삭제 되었습니다." // 본문 내용
        // cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="success" // 원하는 variant
        SubmitButton={true}
      />
    </AdvanceTableProvider >

  );
}

const encryptPublicKey = () => {
  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header as="h4">암호화 공유키 관리</Card.Header>
        <Card.Body>
          <AdvanceTableExample />
        </Card.Body>
      </Card>
    </div>
  );
};

export default encryptPublicKey;
