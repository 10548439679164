import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import routes from 'routes/siteMaps';
import bgNavbar from 'assets/img/generic/bg-navbar.png';
import { useAppContext } from 'providers/AppProvider';
import Avatar from 'components/common/Avatar';
import avatarImg from 'assets/img/team/avatar.png';


const NavbarVertical = () => {
  const {
    config: {
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
      checkorg,
      userAuth
    },
    setConfig
  } = useAppContext();


  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };



  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };


  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  const filteredRoutes = routes.filter(route => {
    if (route.label === '계 정 관 리' && userAuth === 'MANAGER') {
      return false;
    }

    return true;
  });
  // const filteredRoutes = routes.map(route => {
  //   // 기존 조건 유지
  //   if (route.label === '계정관리' && userAuth === 'MANAGER') {
  //     return null; // 해당 route는 MANAGER일 때 표시하지 않음
  //   }

  //   // // "대시보드" 메뉴를 SA 사용자에게 제외하도록 설정
  //   // const filteredChildren = route.children.filter(child => {
  //   //   if (userAuth === 'SA' && child.name === '대시보드') {
  //   //     return false;
  //   //   }
  //   //   return true;
  //   // });

  //   return { ...route, children: filteredChildren };
  // }).filter(route => route !== null); // null을 제외하여 실제로 표시될 routes만 남김


  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
    >
      <Flex alignItems="center">
        <ToggleButton />
        <Logo at="navbar-vertical" textClass="text-primary" width={40} />
      </Flex>
      {checkorg && ( // checkorg 값이 있을 때만 Nav 영역을 렌더링
        <Navbar.Collapse
          in={showBurgerMenu}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            backgroundImage:
              navbarStyle === 'vibrant'
                ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
                : 'none'
          }}
        >
          <div className="pt-3 text-center d-flex align-items-center justify-content-center">
            <Avatar
              src={avatarImg}
              className="me-2 text-600"
              style={{ height: '30px', width: '30px' }}
            />
            {!isNavbarVerticalCollapsed && (
              <span>{checkorg}</span>
            )}
          </div>
          <div className="navbar-vertical-content scrollbar">
            {/* <Nav className="flex-column" as="ul">
              {routes.map(route => (
                <Fragment key={route.label}>
                  {!route.labelDisable && (
                    <NavbarLabel label={route.label} />
                  )}
                  <NavbarVerticalMenu routes={route.children} />
                </Fragment>
              ))}
            </Nav> */}
            <Nav className="flex-column" as="ul">
              {filteredRoutes.map(route => (
                <Fragment key={route.label}>
                  {!route.labelDisable && (
                    <NavbarLabel label={route.label} />
                  )}
                  <NavbarVerticalMenu routes={route.children} />
                </Fragment>
              ))}
            </Nav>

            <div className='footer'>
              {/* <Nav.Item >
                <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
                  <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
                  </Col>
                  <Col className="p-2 mb-3">
                    <hr className="mb-0 navbar-vertical-divider"></hr>

                  </Col>
                </Row>
              </Nav.Item> */}
            </div>
          </div>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string
};

export default NavbarVertical;
