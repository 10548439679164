import React, { useState, useEffect } from 'react';

import { Card, Button } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';

// import data from 'safedb/common/sampledata/sampledataApi'; // data.js 파일에서 데이터 가져오기
import paths from 'routes/paths';
import GroupManagementModal from 'components/modal/GroupManagementModal';

import GroupAddModal from 'components/modal/GroupAddModal'; // 경로에 맞게 수정
import { fetchApiKeyList } from 'hooks/CallApi'; // API 함수 가져오기


const columns = [
    {
        accessorKey: 'num',
        header: '순번',
        meta: { headerProps: { className: 'text-900' } }
    },

    {
        accessorKey: 'status',
        header: '상태',
        meta: { headerProps: { className: 'text-900' } }
    },
    {
        accessorKey: 'group',
        header: '그룹',
        meta: { headerProps: { className: 'text-900' } }
    },

    {
        accessorKey: 'keyname',
        header: '인증키 이름',

        meta: { headerProps: { className: 'text-900' } }
    },

    {
        accessorKey: 'role',
        header: '용도',
        meta: { headerProps: { className: 'text-900' } }
    },


    {
        accessorKey: 'desc',
        header: '설명',
        meta: { headerProps: { className: 'text-900' } }
    },

    {
        accessorKey: 'createdAt',
        header: '등록일시',
        meta: { headerProps: { className: 'text-900' } }
    },
    {
        accessorKey: 'invalidAt',
        header: '만료일시',
        meta: { headerProps: { className: 'text-900' } }
    }
];

function AdvanceTableExample() {
    const navigate = useNavigate(); // useNavigate 훅 사용

    const [tableData, setTableData] = useState([]); // 데이터 상태 추가
    const [showGroupModal, setShowGroupModal] = useState(false); // 모달 상태 추가
    const [showGroupAddModal, setShowGroupAddModal] = useState(false); // 모달 상태 추가

    const [formData, setFormData] = useState({ adderId: '' });
    const [totalElements, setTotalElements] = useState(0);
    const [pageSize, setPageSize] = useState(10); // 초기 페이지 사이즈 설정
    const [loading, setLoading] = useState(true); // 로딩 상태 
    const [currentPage, setCurrentPage] = useState(0); // 현재 페이지 번호
    const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
    const handleGroupModalShow = () => {
        setShowGroupModal(true); // 모달 열기
    };

    const handleGroupModalHide = () => {
        setShowGroupModal(false); // 모달 닫기
    };
    const handleGroupModalConfirm = () => {
        // console.log('그룹 등록:', formData.adderId);
        // setShowGroupModal(false);
        // setFormData({ adderId: '' });
        setShowGroupModal(false);
        setShowGroupAddModal(true);
    };


    const handleGroupAddModalHide = () => {
        setShowGroupAddModal(false); // 모달 닫기
    };
    const handleGroupAddModalConfirm = () => {
        setShowGroupAddModal(false);
    };

    const table = useAdvanceTable({
        // data, // 전체 데이터를 전달
        data: tableData, // 상태에서 데이터 가져오기
        columns,
        sortable: true,
        selection: false,
        pagination: true,
        perPage: 10,
        selectionColumnWidth: 30
    });
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // 총 데이터 개수를 먼저 확인
                const initialResponse = await fetchApiKeyList(0, 1); // 한 개만 요청해서 총 개수 확인
                const totalElements = initialResponse.data.page.totalElements;

                // 총 개수로 pageSize를 설정하여 요청
                const response = await fetchApiKeyList(0, totalElements);
                const content = response.data.content.map((item, index) => ({
                    ...item,
                    num: index + 1,
                    createdAt: item.insertTimeUtc ? new Date(item.insertTimeUtc).toLocaleString('ko-KR') : '',
                    invalidAt: item.expiredTimeUtc ? new Date(item.expiredTimeUtc).toLocaleString('ko-KR') : '',
                    status: item.apikeyStatus ? '정상' : '만료',
                    group: item.apiGroupName,
                    keyname: item.apikeyName,
                    role: item.apikeyType,
                    desc: item.apikeyDescription,
                }));
                setTableData(content);
            } catch (error) {
                console.error('데이터 불러오기 에러:', error);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    // // API 호출 
    // useEffect(() => {
    //     // 전체 데이터 로드를 위한 함수
    //     const loadAllData = async () => {
    //         let currentPage = 0;
    //         let allData = [];

    //         // 300ms 후에 스피너를 표시하는 타이머 설정
    //         const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

    //         try {
    //             const totalData = await fetchApiKeyList(0, pageSize);
    //             const totalPages = totalData.data.page.totalPages; // totalPages를 통해 페이지 수 계산

    //             for (currentPage = 0; currentPage < totalPages; currentPage++) {
    //                 const data = await fetchApiKeyList(currentPage, pageSize);
    //                 console.log("currentPage ", currentPage, "    pageSize ", pageSize);
    //                 console.log("data ", data);
    //                 const content = data.data.content.map((item, index) => ({
    //                     ...item,
    //                     num: currentPage * pageSize + index + 1, // 순번 추가 (전체 순번 기준)
    //                     createdAt: item.insertTimeUtc ? new Date(item.insertTimeUtc).toLocaleString('ko-KR', {
    //                         year: 'numeric',
    //                         month: '2-digit',
    //                         day: '2-digit',
    //                         hour: '2-digit',
    //                         minute: '2-digit',
    //                         second: '2-digit',
    //                         hour12: false, // 24시간제
    //                     }) : '',
    //                     invalidAt: item.expiredTimeUtc
    //                         ? new Date(item.expiredTimeUtc).toLocaleString('ko-KR', {
    //                             year: 'numeric',
    //                             month: '2-digit',
    //                             day: '2-digit',
    //                             hour: '2-digit',
    //                             minute: '2-digit',
    //                             second: '2-digit',
    //                             hour12: false,
    //                         })
    //                         : '', // null일 경우 빈 문자열 반환
    //                     remakeAt: item.reissuanceTimeUtc ? new Date(item.reissuanceTimeUtc).toLocaleString('ko-KR', {
    //                         year: 'numeric',
    //                         month: '2-digit',
    //                         day: '2-digit',
    //                         hour: '2-digit',
    //                         minute: '2-digit',
    //                         second: '2-digit',
    //                         hour12: false, // 24시간제
    //                     }) : '',
    //                     lastuseAt: item.lastUsedTimeUtc ? new Date(item.lastUsedTimeUtc).toLocaleString('ko-KR', {
    //                         year: 'numeric',
    //                         month: '2-digit',
    //                         day: '2-digit',
    //                         hour: '2-digit',
    //                         minute: '2-digit',
    //                         second: '2-digit',
    //                         hour12: false, // 24시간제
    //                     }) : '',
    //                     status: item.apikeyStatus ? '정상' : '만료',
    //                     apikeyStatus: item.apikeyStatus,
    //                     group: item.apiGroupName,
    //                     keyname: item.apikeyName,
    //                     role: item.apikeyType,
    //                     desc: item.apikeyDescription,
    //                     keyuuid: item.apikeyId.apikeyUuid,
    //                     keycompanyId: item.companyId,
    //                     adderId: item.issuerId,
    //                     adderRole: item.issuerRole,
    //                     apikey: item.apiKey,
    //                     apiGroupUuid: item.apiGroupUuid

    //                 }));

    //                 allData = [...allData, ...content];
    //             }
    //             setTableData(allData);
    //             setTotalElements(totalData.data.page.totalElements); // 총 요소 수 설정
    //         } catch (error) {
    //             console.error('데이터 불러오기 에러:', error);
    //         } finally {
    //             clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
    //             setLoading(false);
    //             setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
    //         }
    //     };

    //     loadAllData();
    // }, [pageSize]); // pageSize가 변경될 때마다 loadAllData 실행



    const handleRowClick = row => {
        const { createdAt, invalidAt, remakeAt, lastuseAt, apiGroupUuid, status, apikeyStatus, group, keyname, role, desc, keyuuid, keycompanyId, adderId, adderRole, apikey } = row.original; // 필요한 데이터 추출
        navigate(`/safedb/common/key/apikey/edit`, {
            state: { createdAt, invalidAt, remakeAt, lastuseAt, apiGroupUuid, status, apikeyStatus, group, keyname, role, desc, keyuuid, keycompanyId, adderId, adderRole, apikey } // 전달할 데이터
        });
    };

    return (
        <>
            <AdvanceTableProvider {...table}>
                {/* <BulAction rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> */}
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }} className="mb-2">
                    <AdvanceTableFooter rowsPerPageSelection />
                    <Button
                        variant="outline-success"
                        style={{
                            position: 'absolute',
                            right: 130,
                            top: -8,
                            width: '110px'
                        }}
                        onClick={handleGroupModalShow} // 모달 열기

                    >
                        그룹 관리
                    </Button>
                    <Link to={paths.addapikey} style={{ textDecoration: 'none' }}>
                        <Button
                            variant="success"
                            style={{
                                position: 'absolute',
                                right: 10,
                                top: -8,
                                width: '110px'
                            }}

                        >
                            등록
                        </Button>


                    </Link>

                </div>
                <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        striped: true,
                        className: 'fs-10 mb-0 overflow-hidden'
                    }}
                    onRowClick={handleRowClick} // 이벤트 핸들러 전달

                />
                <div className="mt-3">
                    <p style={{ margin: 0, fontWeight: 700 }}>총 {tableData.length}건</p>
                    <AdvanceTablePagination />
                </div>

            </AdvanceTableProvider >
            <GroupManagementModal
                show={showGroupModal}
                onHide={handleGroupModalHide}
                onConfirm={handleGroupModalConfirm}
            // formData={formData}
            // setFormData={setFormData}
            />
            <GroupAddModal
                show={showGroupAddModal}
                onHide={handleGroupAddModalHide}
                onConfirm={handleGroupAddModalConfirm}
            />
        </>
    );
}

const apikey = () => {
    return (
        <div className="container mt-5">
            <Card className="mypage-card">
                <Card.Header as="h4">인증키</Card.Header>
                <Card.Body>
                    <AdvanceTableExample />
                </Card.Body>
            </Card>
        </div>
    );
};

export default apikey;
