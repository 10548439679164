import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'; // useState 추가
import { Link } from 'react-router-dom';
import { Dropdown, InputGroup, Modal, Button } from 'react-bootstrap'; // Button 추가
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import paths from 'routes/paths';
import Flex from 'components/common/Flex';
import MyPage from 'safedb/common/mypage';
import ChangePasswordModal from 'safedb/common/changePasswordModal'; // ChangePasswordModal 임포트
import avatarImg from 'assets/img/team/avatar.png';

import { useAppContext } from 'providers/AppProvider';

const ProfileDropdown = () => {
  const [showModal, setShowModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false); // 비밀번호 변경 모달 상태 추가

  const {
    config: { isNavbarVerticalCollapsed, userAuth, userId },
    setConfig
  } = useAppContext();
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleClosePasswordModal = () => setShowPasswordModal(false);
  const handleShowPasswordModal = () => setShowPasswordModal(true);
  return (
    <>
      <InputGroup>
        <InputGroup.Text id="inputGroup-sizing-sm" style={{ width: '100px', justifyContent: 'center' }}>  {userAuth}  </InputGroup.Text>
      </InputGroup >

      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#!"
          className="pe-0 ps-2 nav-link"
        >
          <Flex className="mb-2">
            <Avatar
              src={avatarImg}
              className="me-2 text-700"

              style={{ height: '30px', width: '30px' }}
            />
            <i className="fas fa-user"></i>

            <label className='mt-2' style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{userId}</label>

          </Flex>

        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            <Dropdown className="fw-bold text-warning" >
              <FontAwesomeIcon icon="crown" className="me-2 ms-3" />
              <span>시스템 관리자</span>
            </Dropdown>
            <Dropdown.Divider />
            {/* <Dropdown.Item as={Link} to={paths.myPage}>내 정보</Dropdown.Item> */}
            <Dropdown.Item onClick={handleShow}>
              내 정보
            </Dropdown.Item>
            <Dropdown.Item onClick={handleShowPasswordModal}>
              비밀번호 변경
            </Dropdown.Item>
            {/* <Dropdown.Item href="#!">Feedback</Dropdown.Item> */}
            <Dropdown.Divider />
            {/* <Dropdown.Item as={Link} to={paths.userSettings}>
              Settings
            </Dropdown.Item> */}
            <Dropdown.Item as={Link} to={paths.cardLogout}>
              로그아웃
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>


      {/* 모달 컴포넌트 추가 */}
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>내 정보</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MyPage setValue={() => { }} handleClose={handleClose} /> {/* handleClose를 전달 */}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button> */}
        </Modal.Footer>
      </Modal>


      <ChangePasswordModal
        show={showPasswordModal}
        onHide={handleClosePasswordModal}
      />
    </>
  );
};

export default ProfileDropdown;
