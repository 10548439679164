const limitStringLength = (str, maxLength) => {
    return str.length > maxLength ? str.slice(0, maxLength) : str;
};


const generateData = (count) => {
    const data = [];

    for (let i = 1; i <= count; i++) {
        const keyname = `KeyName${i}`;
        const group = `그룹${i}`;
        const createdAt = `2021-${String((12 - ((i - 1) % 12))).padStart(2, '0')}-10 09:00:00`;
        const invalidAt = `2024-${String((12 - ((i - 1) % 12))).padStart(2, '0')}-15 09:00:00`;
        const lastUsedAt = `2024-${String((12 - ((i - 1) % 12))).padStart(2, '0')}-15 09:00:00`;


        data.push({
            num: i,
            isChecked: i % 2 !== 0,

            status: i % 3 === 0 ? '정상' : '만료',
            group: group,
            keyname: keyname,
            role: i % 2 === 0 ? 'API' : 'CF',
            aboutEnc: 'O',
            aboutDec: 'O',
            masking: '설정',
            createdAt: limitStringLength(createdAt, 19), // 날짜 형식을 유지하기 위해 19자로 제한
            invalidAt: limitStringLength(invalidAt, 19), // 날짜 형식을 유지하기 위해 19자로 제한
            lastUsedAt: limitStringLength(lastUsedAt, 19), // 날짜 형식을 유지하기 위해 19자로 제한
            // active: i % 4 === 0 ? true : false // db에서가져올떄 active값으로 초기 렌더링될때 checked 표기
        });
    }
    return data;
};

const data = generateData(100); // 데이터 40개 생성



export default data; // 데이터를 내보내기
