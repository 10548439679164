import React, { useState, useEffect } from 'react';

import { Card, Button } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import paths from 'routes/paths';
import GroupAddModal from 'components/modal/GroupAddModal'; // 경로에 맞게 수정
import CheckOutModal from 'components/modal/CheckOutModal';
import { fetchEncKeyList } from 'hooks/CallApi';
import AlgorithmCompare from 'components/dashboards/crm/AlgorithmCompare';

function AdvanceTableExample() {

    const navigate = useNavigate();
    const columns = [
        {
            accessorKey: 'num',
            header: '순번',
            meta: { headerProps: { className: 'text-900 ' } }
        },

        {
            accessorKey: 'policyId',
            header: '암호키 아이디',

            meta: { headerProps: { className: 'text-900 col-md-6' } }
        },
        {
            accessorKey: 'keyname',
            header: '암호키 별칭',
            meta: { headerProps: { className: 'text-900 ' } }
        },
        {
            accessorKey: 'algorithmName',
            header: '알고리즘',
            meta: { headerProps: { className: 'text-900 ' } }
        },
        {
            accessorKey: 'keylength',
            header: '키 길이(bit)',
            meta: { headerProps: { className: 'text-900 ' } }
        },
        {
            accessorKey: 'mode',
            header: '운영모드',
            meta: { headerProps: { className: 'text-900 ' } }
        },

    ];




    const [tableData, setTableData] = useState([]); // 데이터 상태 추가
    const [showGroupModal, setShowGroupModal] = useState(false); // 모달 상태 추가
    const [modalShow, setModalShow] = useState(false); // 모달 상태
    const [modalReShow, setModalReShow] = useState(false); // 모달 상태
    const [totalElements, setTotalElements] = useState(0);

    const [formData, setFormData] = useState({ adderId: '' });
    const [pageSize, setPageSize] = useState(10); // 초기 페이지 사이즈 설정
    const [loading, setLoading] = useState(true); // 로딩 상태
    const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가

    const handleGroupModalShow = () => {
        setShowGroupModal(true); // 모달 열기
    };

    const handleGroupModalHide = () => {
        setShowGroupModal(false); // 모달 닫기
    };
    const handleGroupModalConfirm = () => {
        console.log('그룹 등록:', formData.adderId);
        setShowGroupModal(false);
        setFormData({ adderId: '' });
    };



    const handleDeleteClick = () => {
        setModalShow(true); // 관리자 비밀번호 모달 열기
    };

    const handleDelete = () => {
        setModalShow(false);
        const selectedRowIds = table
            .getSelectedRowModel()
            .rows.map(row => row.original.id);
        const newData = tableData.filter(row => !selectedRowIds.includes(row.id)); // 선택된 행 제외
        setTableData(newData); // 데이터 업데이트
        table.resetRowSelection();
        // setModalReShow(true);
        toast.error(`삭제가 완료되었습니다.`, {
            theme: 'colored'
        });
    };

    const handleDeleteRe = () => {

        setModalReShow(false);
    };


    const table = useAdvanceTable({
        // data, // 전체 데이터를 전달
        data: tableData, // 상태에서 데이터 가져오기
        columns,
        sortable: true,
        selection: true,
        pagination: true,
        perPage: 10,
        selectionColumnWidth: 10
    });

    const selectedRowCount = table.getSelectedRowModel().rows.length;
    // API 호출 
    useEffect(() => {
        // 전체 데이터 로드를 위한 함수
        const loadAllData = async () => {
            let currentPage = 0;
            let allData = [];

            // 300ms 후에 스피너를 표시하는 타이머 설정
            const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

            try {
                const totalData = await fetchEncKeyList(0, pageSize);
                const totalPages = totalData.data.page.totalPages; // totalPages를 통해 페이지 수 계산
                for (currentPage = 0; currentPage < totalPages; currentPage++) {
                    const data = await fetchEncKeyList(currentPage, pageSize);
                    const content = data.data.content.map((item, index) => ({
                        ...item,
                        num: currentPage * pageSize + index + 1, // 순번 추가 (전체 순번 기준)
                        policyId: item.policyId.policyUuid.slice(0, 40),
                        keyname: item.policyName.slice(0, 20),
                        algorithmName: item.algo.slice(0, 10),
                        keylength: item.keyLen,
                        mode: item.mode,
                        policyUuid: item.policyId.policyUuid,
                        createdAt: item.insertTimeUtc ? new Date(item.insertTimeUtc).toLocaleString('ko-KR', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false, // 24시간제
                        }) : '',
                    }));
                    console.log("key data ", data);
                    allData = [...allData, ...content];
                }

                setTableData(allData);
                setTotalElements(totalData.data.page.totalElements); // 총 요소 수 설정
            } catch (error) {
                console.error('데이터 불러오기 에러:', error);
            } finally {
                clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
                setLoading(false);
                setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
            }
        };

        loadAllData();
    }, [pageSize]); // pageSize가 변경될 때마다 loadAllData 실행


    const handleRowClick = (row) => {
        // const { policyId, pubkeyYN, pubkeyName } = row.original; // 필요한 데이터 추출
        // navigate(`/safedb/common/key/encryptKeyPolicy/edit?policyId=${policyId}&pubkeyYN=${pubkeyYN}&pubkeyName=${pubkeyName}`); // 데이터와 함께 이동
        const { policyId, keyname, algorithmName, keylength, mode, policyUuid, createdAt } = row.original; // 필요한 데이터 추출
        navigate(`/safedb/common/key/encryptKeyPolicy/edit`, {
            state: { policyId, keyname, algorithmName, keylength, mode, policyUuid, createdAt } // 전달할 데이터
        });
    };

    return (
        <>
            <AdvanceTableProvider {...table}>
                {/* <BulAction rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> */}
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }} className="mb-2">
                    {/* <AdvanceTableFooter rowsPerPageSelection /> */}
                    {selectedRowCount > 0 ? (
                        <Button
                            variant="outline-danger"
                            style={{
                                position: 'absolute',
                                right: '0rem',
                                top: '-2rem',
                                width: '100px'
                            }}
                            onClick={handleDeleteClick}
                        >
                            삭제
                        </Button>
                    ) : (
                        <Link to={paths.addencryptKeyPolicy} style={{ textDecoration: 'none' }}>
                            <Button
                                variant="outline-success"
                                style={{
                                    position: 'absolute',
                                    right: '0rem',
                                    top: '-2rem',
                                    width: '100px'
                                }}
                            >
                                등록
                            </Button>
                        </Link>
                    )}


                </div>
                <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        striped: true,
                        className: 'fs-10 mb-0 overflow-hidden'
                    }}
                    onRowClick={handleRowClick} // 이벤트 핸들러 전달

                />
                <div className="mt-3">
                    <p style={{ margin: 0, fontWeight: 700 }}>총 {tableData.length}건</p>
                    <AdvanceTablePagination />
                </div>

            </AdvanceTableProvider >
            <CheckOutModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onConfirm={handleDelete}
                title="경고" // 제목
                body="정말 삭제하시겠습니까?" // 본문 내용
                cancelText="취소" // 취소 버튼 텍스트
                confirmText="삭제" // 확인 버튼 텍스트
                variant="warning" // 원하는 variant
                CancelButton={true}
                SubmitButton={true}
            />
            <CheckOutModal
                show={modalReShow}
                onHide={() => setModalReShow(false)}
                onConfirm={handleDeleteRe}
                title="완료" // 제목
                body="삭제 되었습니다." // 본문 내용
                // cancelText="취소" // 취소 버튼 텍스트
                confirmText="확인" // 확인 버튼 텍스트
                variant="success" // 원하는 variant
                SubmitButton={true}
            />
            <GroupAddModal
                show={showGroupModal}
                onHide={handleGroupModalHide}
                onConfirm={handleGroupModalConfirm}
            // formData={formData}
            // setFormData={setFormData}
            />
        </>
    );
}

const encryptKeyPolicy = () => {
    return (
        <div className="container mt-5">
            <Card className="mypage-card">
                <Card.Header as="h4">암호화 키 정책 관리</Card.Header>
                <Card.Body>
                    <AdvanceTableExample />
                </Card.Body>
            </Card>
        </div>
    );
};

export default encryptKeyPolicy;
