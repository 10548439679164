import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Card } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';

const SiteAverageApiProcess = () => {

  const {
    config: { dashboardDate },
    setConfig
  } = useAppContext();

  const chartRef = useRef(null);

  useEffect(() => {
    // ECharts 인스턴스 초기화
    const chart = echarts.init(chartRef.current);

    // 샘플 데이터 (지연 시 추가 정보 포함)
    const apiData = [
      { name: '고객사 A', concurrentCalls: 34000, avgRequestSize: 70000, avgProcessingTime: 0.35, isDelayed: false, delaySpeedExcess: 0, delayCount: 0 },
      { name: '고객사 B', concurrentCalls: 100000, avgRequestSize: 95000, avgProcessingTime: 0.45, isDelayed: true, delaySpeedExcess: 0.4, delayCount: 10 },
      { name: '고객사 C', concurrentCalls: 85000, avgRequestSize: 85000, avgProcessingTime: 0.50, isDelayed: false, delaySpeedExcess: 0, delayCount: 0 },
      { name: '고객사 D', concurrentCalls: 60000, avgRequestSize: 60000, avgProcessingTime: 0.40, isDelayed: false, delaySpeedExcess: 0, delayCount: 0 },
      { name: '고객사 E', concurrentCalls: 100000, avgRequestSize: 100000, avgProcessingTime: 0.60, isDelayed: true, delaySpeedExcess: 0.5, delayCount: 15 },
      // 추가 고객사 데이터...
    ];

    // 색상 맵 설정
    const delayColorMap = {
      false: '#5cb85c',  // 초록색: 정상
      true: '#f49402'    // 노란색: 지연
    };

    // 숫자 포맷팅 함수 (K, M 단위)
    const formatNumber = (value) => {
      if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + 'M';
      } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + 'K';
      } else {
        return value;
      }
    };

    // ECharts 옵션 설정
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          const data = params.data;
          let tooltipText = `<strong>${data[3]}</strong><br/>
                            동시 요청 수: ${formatNumber(data[0])} 건<br/>
                            평균 요청 용량: ${(data[2] / 1000).toFixed(1)} MB<br/>
                            평균 처리 속도: ${data[1]} 초<br/>`;

          if (data[4]) { // 지연 여부가 true인 경우
            tooltipText += `지연 여부: 지연됨<br/>
                            지연 시 속도 초과: ${data[5]} 초<br/>
                            지연 횟수: ${formatNumber(data[6])} 회<br/>`;
          } else {
            tooltipText += `지연 여부: 정상<br/>`;
          }

          return tooltipText;
        }
      },
      legend: {
        data: ['정상', '지연'],
        bottom: '5%'
      },
      // toolbox: {
      //   show: true,
      //   feature: {
      //     dataZoom: { yAxisIndex: 'none' },
      //     dataView: { readOnly: true },
      //     magicType: { type: ['scatter', 'line'] },
      //     restore: {},
      //     saveAsImage: {}
      //   }
      // },
      grid: {
        left: '10%',
        right: '15%',
        bottom: '15%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        name: '동시 요청 수',
        scale: true,
        splitLine: {
          show: true
        },
        axisLabel: {
          formatter: function (value) {
            return formatNumber(value) + ' 건';
          }
        }
      },
      yAxis: {
        type: 'value',
        name: '평균 처리 시간',
        max: 1, // 적절한 최대값으로 조정
        splitLine: {
          show: true
        },
        axisLabel: {
          formatter: '{value} 초'
        }
      },
      series: [
        {
          name: '정상',
          type: 'scatter',
          data: apiData.filter(c => !c.isDelayed).map(c => [c.concurrentCalls, c.avgProcessingTime, c.avgRequestSize, c.name, c.isDelayed, c.delaySpeedExcess, c.delayCount]),
          symbolSize: function (data) {
            return Math.sqrt(data[2] / 1000) * 10; // avgRequestSize을 기준으로 버블 크기 조정
          },
          label: {
            emphasis: {
              show: true,
              formatter: '{b}',
              position: 'right'
            }
          },
          itemStyle: {
            color: delayColorMap[false]
          }
        },
        {
          name: '지연',
          type: 'scatter',
          data: apiData.filter(c => c.isDelayed).map(c => [c.concurrentCalls, c.avgProcessingTime, c.avgRequestSize, c.name, c.isDelayed, c.delaySpeedExcess, c.delayCount]),
          symbolSize: function (data) {
            return Math.sqrt(data[2] / 1000) * 10; // avgRequestSize을 기준으로 버블 크기 조정
          },
          label: {
            emphasis: {
              show: true,
              formatter: '{b}',
              position: 'right'
            }
          },
          itemStyle: {
            color: delayColorMap[true]
          }
        },
        {
          name: '배정 용량 기준',
          type: 'line',
          data: [[100000, 0.4], [0, 0.4]], // x축을 기준으로 선 그리기
          symbol: 'none',
          lineStyle: {
            color: '#0000FF',
            type: 'dashed'
          },
          tooltip: {
            formatter: '배정 용량 기준<br/>동시 요청 수: 100K 건<br/>평균 처리 시간: 0.4 초'
          },
          z: 0 // 기준선이 데이터 포인트 뒤에 표시되도록 설정
        }
      ]
    };

    // 옵션 설정
    chart.setOption(option);

    // 반응형 리사이즈
    const resizeChart = () => {
      chart.resize();
    };

    window.addEventListener('resize', resizeChart);

    // 컴포넌트 언마운트 시 이벤트 리스너 정리
    return () => {
      window.removeEventListener('resize', resizeChart);
      chart.dispose();
    };
  }, []);

  return (
    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>고객별 평균 API 동시 처리량</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          {dashboardDate}

        </span>
      </Card.Header>
      <Card.Body>
        <div ref={chartRef} style={{ width: '100%', height: '20rem' }} />
      </Card.Body>

    </Card>

  );
};

export default SiteAverageApiProcess;
