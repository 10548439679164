import axios from 'axios';
import { toast } from 'react-toastify';


// 기본 설정으로 `api.safedb.biz` 사용
const api = axios.create({
    baseURL: 'https://api.safedb.biz' // 기본 API URL
});

// 토큰 발급 및 재발급용 인스턴스 (auth.safedb.biz 사용)
const authApi = axios.create({
    baseURL: 'https://auth.safedb.biz' // 인증용 API URL
});

// 로그인 API 호출 함수
export const loginUser = async ({ username, password }) => {
    try {
        const response = await authApi.post('/auth/login', { username, password });
        return response.data; // 토큰 및 리프레시 토큰을 반환
    } catch (error) {
        console.error('로그인 요청 에러:', error);
        throw error;
    }
};

// 가입 신청 시 인증번호 보내는 API
export const sendAuthCode = async ({ applyName, applyTel, applyEmail, companyName }) => {
    try {
        const response = await api.post('/apply/sendAuthCode', {
            applyName,
            applyTel,
            applyEmail,
            companyName,
        });
        return response.data; // {boolean} 타입의 응답을 반환
    } catch (error) {
        console.error('가입 신청 에러:', error);
        throw error;
    }
};

// 가입 신청 시 유효 인증코드 확인 API
export const checkAuthCode = async ({ email, code }) => {
    try {
        const response = await api.post('/apply/checkAuthCode', {
            email,
            code
        });
        return response.data; // {boolean} 타입의 응답을 반환
    } catch (error) {
        console.error('유효 인증코드 확인 에러:', error);
        throw error;
    }
};

// 회사 목록 조회 API 
export const fetchCompanyList = async (page = 0, size = 10) => {
    try {
        const response = await api.get('/companies', {
            params: { page, size },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져와 헤더에 포함
            }

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('회사 목록 조회 에러:', error);
        throw error;
    }
};
// 회사 생성 API
export const createCompany = async (data) => {
    try {
        const response = await api.post(`/companies`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`, // 토큰을 세션 스토리지에서 가져옴 
            },
        });
        return response.data;
    } catch (error) {
        console.error('회사 생성 에러:', error);
        throw error;
    }
};

// 회사 정보 변경 API
export const updateCompanyInfo = async (companyId, data) => {
    try {
        const response = await api.put(`/companies/${companyId}`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져옴
            }
        });
        return response.data;
    } catch (error) {
        console.error('회사 정보 업데이트 에러:', error);
        throw error;
    }
};




// 관리자 계정 목록 조회 API 
export const fetchAdminList = async (page = 0, size = 10) => {
    try {
        const response = await api.get('/users', {
            params: { page, size },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져와 헤더에 포함
            }

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('관리자 계정 목록 조회 에러:', error);
        throw error;
    }
};

// 로그인 정보 조회 API 
export const getAdminInfo = async () => {
    try {
        const response = await api.get('/users/login', {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져와 헤더에 포함
            }
        });
        console.log(response.data);
        return { adminInfo: response.data, isPasswordChangeNeeded: false, changeReasonCode: null };
    } catch (error) {
        console.log("error.response.data ", error.response.data.code);
        if (error.response && (error.response.data.code === -5 || error.response.data.code === -6)) {
            // 비밀번호 만료 에러 -5, 비밀번호 변경 필요 -6
            console.log("비밀번호 변경이 필요합니다.");
            const changeReasonCode = error.response.data.code;

            return { adminInfo: null, isPasswordChangeNeeded: true, changeReasonCode }; // 비밀번호 변경 필요 표시와 원인 코드 반환
        }

        // 기타 에러가 발생한 경우 에러를 던짐
        console.error('기타 에러 발생:', error);
        return { adminInfo: null, isPasswordChangeNeeded: false, error };
    }
};

// 관리자 정보 변경 API
export const updateAdminInfo = async (userUuid, data) => {
    try {
        const response = await api.put(`/users/${userUuid}`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져옴
            }
        });
        return response.data;
    } catch (error) {
        console.error('관리자 정보 업데이트 에러:', error);
        throw error;
    }
};

// 고객사 토큰 발급 API 호출 함수
export const requestSiteToken = async ({ username, refreshToken, companyId }) => {
    try {
        const response = await authApi.post('/auth/impersonation', {
            username,
            refreshToken,
            companyId
        });
        console.log("고객사 밟급 ", response.data)
        return response.data; // 발급된 토큰 반환

    } catch (error) {
        console.error('고객사 토큰 발급 요청 에러:', error);
        throw error;
    }
};

// 비밀번호 변경 API
export const changePassword = async (data) => {
    try {
        const response = await api.put(`/users/password`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져옴
            }
        });
        console.log("비번 반응 확인", response.data);
        return response.data;
    } catch (error) {
        console.error('비밀번호 변경 에러:', error);
        throw error;
    }
};


// // API KEY 목록 조회 API 
export const fetchApiKeyList = async (page = 0, size = 10) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get('/apikeys', {
            params: { page, size },
            headers

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('관리자 계정 목록 조회 에러:', error);
        throw error;
    }
};


// API 인증키  정보 변경 API
export const updateApiKeyInfo = async (apikeyUuid, data) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.put(`/apikeys/${apikeyUuid}`, data, {
            headers
        });
        return response.data;
    } catch (error) {
        console.error('API 인증키 정보 업데이트 에러:', error);
        throw error;
    }
};

// API 인증키 UUID 재발급 API
export const remakeApiKeyInfo = async (apikeyUuid) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.put(`/apikeys/${apikeyUuid}/reissuance`, {}, {
            headers
        });
        return response.data;
    } catch (error) {
        console.error('API 키 재발급 에러:', error);
        throw error;
    }
};
// API 인증키 추가 API
export const addApiKey = async (data) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.post('/apikeys', data, {
            headers
        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('그룹목록 조회 에러:', error);
        throw error;
    }
};




// // 그룹관리 목록 조회 API 
export const fetchGroupList = async (page = 0, size = 10) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get('/groups', {
            params: { page, size },
            headers

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('그룹목록 조회 에러:', error);
        throw error;
    }
};

// // 그룹 목록 단순 조회 API 
export const SelectGroupList = async () => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');

    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get('/groups/list', {
            headers

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('그룹 목록 단순 조회 에러:', error);
        throw error;
    }
};


// 그룹 등록 API
export const addGroup = async ({ groupName, groupDescription }) => {
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');

    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.post('/groups', {
            groupName,
            groupDescription
        }, { headers });
        return response.data; // {boolean} 타입의 응답을 반환
    } catch (error) {
        console.error('그룹 추가 에러:', error);
        throw error;
    }
};







// // 암호키 목록 조회 API 
export const fetchEncKeyList = async (page = 0, size = 10) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get('/policy', {
            params: { page, size },
            headers

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('암호키 목록 조회 에러:', error);
        throw error;
    }
};

// // 암호키 상세 조회 API 
export const detailEncKey = async (policyUuid) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.get(`/policy/${policyUuid}`, {
            headers
        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('암호키 상세 조회 에러:', error);
        throw error;
    }
};

// // 암호키 등록 세팅 값 조회 API 
export const settingEncKey = async () => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.get('/policy/settings', {
            headers
        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('암호키 세팅 값 조회 에러:', error);
        throw error;
    }
};


// // 암호키 상세내 적용대상목록 조회 API 
// export const fetchEncPolicyList = async (policyUuid) => {
//     // 기본 헤더 구성
//     const headers = {};

//     // siteToken이 존재하면 사용하고, 없으면 token을 사용
//     const siteToken = sessionStorage.getItem('siteToken');
//     const token = sessionStorage.getItem('token');
//     if (siteToken) {
//         headers.Authorization = `Bearer ${siteToken}`;
//     } else if (token) {
//         headers.Authorization = `Bearer ${token}`;
//     }
//     try {
//         const response = await api.get(`/policy/${policyUuid}/apikeys`, {
//             headers
//         });
//         console.log(response);
//         return response.data; // API 응답 데이터를 반환
//     } catch (error) {
//         console.error('암호키 상세 적용대상목록 조회 에러:', error);
//         throw error;
//     }
// };

export const fetchEncPolicyList = async (policyUuid, page = 0, size = 10) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get(`/policy/${policyUuid}/apikeys`, {
            params: { page, size }, // 페이지네이션 파라미터 추가
            headers
        });
        console.log(response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('암호키 상세 적용대상목록 조회 에러:', error);
        throw error;
    }
};

// // 암호키 등록 시  적용대상목록 조회 API 
export const addEncPolicyList = async () => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.get('policy/settings/apikeys', {
            headers
        });
        console.log(response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('암호키 상세 [등록] 적용대상목록 조회 에러:', error);
        throw error;
    }
};

// 암호화 키 정책 변경 API
export const updateEncryptKeyPolicy = async (policyUuid, apikeyPolicies) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.put(`/policy/${policyUuid}`, { apikeyPolicies }, {
            headers
        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('암호화 키 정책 변경 에러:', error);
        throw error;
    }
};

// 암호화 키 정책 추가 API
export const addEncryptKeyPolicy = async (policyData) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    console.log("body ", policyData);
    try {
        const response = await api.post('/policy', policyData, {
            headers
        });

        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('암호화 키 정책 추가 에러:', error);
        throw error;
    }
};

//////////////////////////////////////////////////////////////////////////////////////// 공유키 관련

// // 공유키 목록 조회 API 
export const fetchPubKeyList = async (page = 0, size = 10) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.get('policy/shared', {
            params: { page, size },
            headers
        });
        console.log("public", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('공유키 목록 조회 에러:', error);
        throw error;
    }
};

//////////////////////////////////////////////////////////////////////////////////////// 대시보드 관련
// 고객사  대시보드 요약
export const fetchSummaryCounts = async ({ period, timeZone }) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.post('/chart/allCnt',
            { period, timeZone },
            {
                headers
            }
        );
        console.log("summary chart ", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('요약 데이터 조회 에러:', error);
        throw error;
    }
};
// 고객사 대시보드 지난 처리량
export const fetchDataSize = async ({ period, timeZone }) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.post('/chart/dataSize',
            { period, timeZone },
            {
                headers
            }
        );
        console.log("dataSize chart ", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('요약 데이터 조회 에러:', error);
        throw error;
    }
};

// 고객사 대시보드 지난 암복호화 요청량
export const fetchRecentlyEncDec = async ({ period, timeZone }) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.post('/chart/encdecCnt',
            { period, timeZone },
            {
                headers
            }
        );
        console.log("encdecCnt chart ", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('요약 데이터 조회 에러:', error);
        throw error;
    }
};

// 고객사 대시보드 인증키 타입별 요청 비교
export const fetchKeyTypeComp = async ({ period, timeZone }) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.post('/chart/type',
            { period, timeZone },
            {
                headers
            }
        );
        console.log("type chart ", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('요약 데이터 조회 에러:', error);
        throw error;
    }
};

// 고객사 대시보드 알고리즘 요청 비교
export const fetchAlgoComp = async ({ period, timeZone }) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.post('/chart/algo',
            { period, timeZone },
            {
                headers
            }
        );
        console.log("algo chart ", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('요약 데이터 조회 에러:', error);
        throw error;
    }
};

// 고객사 대시보드 암호키 요청량 비교
export const fetchEncKeyComp = async ({ period, timeZone, order }) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        console.log("api period", period);
        console.log("api timeZone", timeZone);
        console.log("api order", order);
        const response = await api.post('/chart/topPolicy',
            { period, timeZone, order },
            {
                headers
            }

        );
        console.log("topPolicy chart ", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('요약 데이터 조회 에러:', error);
        throw error;
    }
};


// 고객사 대시보드 6개월간 요금 청구량
export const fetchSixMonthPay = async () => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.post('/chart/billing', {}, { headers });
        console.log("six chart ", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('요약 데이터 조회 에러:', error);
        throw error;
    }
};
//////////////////////////////////////////////////////////////////////////////////////// 모니터링 관련
// // 관리 로그 조회 API 
export const fetchManageLog = async () => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get('/logs/management', {

            headers

        });
        console.log("관리로그", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('그룹목록 조회 에러:', error);
        throw error;
    }
};

// // 암복호화 로그 조회 API 
export const fetchEncDecLog = async () => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get('/logs/management', {

            headers

        });
        console.log("관리로그", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('그룹목록 조회 에러:', error);
        throw error;
    }
};


/////////////////////////////////////////////////////////////////////////////////////// 토큰 유지관련
// 요청 인터셉터
api.interceptors.request.use(
    (config) => {
        const siteToken = sessionStorage.getItem('siteToken');
        const token = sessionStorage.getItem('token');

        // siteToken이 없을 때만 일반 token을 설정
        if (!siteToken && token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// 응답 인터셉터
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // 토큰 만료 시 재발급 시도
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = sessionStorage.getItem('refreshToken');
                const response = await authApi.post('/auth/refresh', { refreshToken });

                const newToken = response.data.token;
                sessionStorage.setItem('token', newToken);
                console.log('토큰 재발급 완료');
                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                return api(originalRequest); // 실패했던 요청 재시도
            } catch (refreshError) {
                console.error('토큰 재발급 실패:', refreshError);
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('refreshToken');
                window.location.href = '/login'; // 로그인 페이지로 이동
            }
        }

        return Promise.reject(error);
    }
);

export { api, authApi }; // 필요한 곳에서 `api`와 `authApi` 둘 다 사용할 수 있게 export
