
// function SunnyUtilFunc() {
//     const handleValueDelete = () => {
//         setFormData({
//             ...formData,
//             password: '' // 비밀번호 필드를 비움
//         });
//     };
// } 
const handleValueDelete = (fieldName, formData, setFormData, Change) => {
    setFormData({
        ...formData,
        [fieldName]: '' // 전달된 필드명을 기준으로 해당 필드의 값을 비움
    });

};

const goToParentPath = () => {
    const pathSegments = location.pathname.split('/');
    // 마지막 경로 세그먼트를 제거하고 재조합
    pathSegments.pop(); // 'bc' 제거
    return pathSegments.join('/') || '/'; // '/'로 리턴 (루트로 이동)
};

export {
    handleValueDelete,
    goToParentPath
}