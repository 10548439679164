import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import avatarImg from 'assets/img/team/avatar.png';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Select from 'react-select';
import "safedb/common/css/IniCustomCss.css"
import CheckOutModal from 'components/modal/CheckOutModal';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { handleValueDelete, goToParentPath } from 'components/common/SunnyUtils';

import { useAppContext } from 'providers/AppProvider';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import GroupManagementModal from 'components/modal/GroupManagementModal';
import GroupAddModal from 'components/modal/GroupAddModal'; // 경로에 맞게 수정
import data from 'safedb/common/sampledata/sampledataEditEncrypt';

import PolicyControlModal from 'components/modal/PolicyControlModal';
import paths from 'routes/paths';
import EncryptKeyPolicyModal from 'safedb/common/key/encryptKeyPolicyModal'
import { detailEncKey, updateEncryptKeyPolicy, fetchEncPolicyList } from 'hooks/CallApi';

const editencryptKeyPolicy = () => {
    const columns = [
        {
            accessorKey: 'status',
            header: '상태',
            meta: { headerProps: { className: 'text-900' } }
        },
        {
            accessorKey: 'group',
            header: '그룹명',
            meta: { headerProps: { className: 'text-900' } }
        },
        {
            accessorKey: 'keyname',
            header: '인증키 이름',
            meta: { headerProps: { className: 'text-900' } }
        },
        {
            accessorKey: 'role',
            header: '용도',
            meta: { headerProps: { className: 'text-900' } }
        },
        {
            accessorKey: 'aboutEnc',
            header: '암호화 관련',
            meta: { headerProps: { className: 'text-900' } }
        },
        {
            accessorKey: 'aboutDec',
            header: '복호화 관련',
            meta: { headerProps: { className: 'text-900' } }
        },
        {
            accessorKey: 'masking',
            header: '마스킹',
            meta: { headerProps: { className: 'text-900' } }
        },
        {
            accessorKey: 'createdAt',
            header: '등록일시',
            meta: { headerProps: { className: 'text-900' } }
        },
        {
            accessorKey: 'invalidAt',
            header: '만료일시',
            meta: { headerProps: { className: 'text-900' } }
        },
        {
            accessorKey: 'lastUsedAt',
            header: '최근 사용 일시',
            meta: { headerProps: { className: 'text-900' } }
        }
    ];


    const cardRef = useRef(null);

    const focusCard = () => {
        if (cardRef.current) {
            cardRef.current.focus();
            cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };


    const [tableData, setTableData] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [selectedControlOptions, setSelectedControlOptions] = useState([]);

    const [pageSize, setPageSize] = useState(5); // 초기 페이지 사이즈 설정
    const [loading, setLoading] = useState(true); // 로딩 상태
    const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
    const [apiKeyPolicyData, setApiKeyPolicyData] = useState([]); // 인증키 설정 모달 데이터 저장

    const table = useAdvanceTable({
        data: tableData,
        columns,
        sortable: true,
        selection: true,
        pagination: true,
        perPage: 5,
        selectionColumnWidth: 30,

    });

    const navigate = useNavigate();
    const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    const {
        config: { userAuth_K, userId, checkorg },
        setConfig
    } = useAppContext();
    const [modalShow, setModalShow] = useState(false);
    const [modalPolicyDeleteShow, setModalPolicyDeleteShow] = useState(false); // 관리자 비밀번호 모달 상태 
    const [modalInfoShow, setModalInfoShow] = useState(false); // 관리자 비밀번호 모달 상태 


    const [modalConfirmShow, setModalConfirmShow] = useState(false);

    const [isModified, setIsModified] = useState(false); // 변경 상태 추적
    const [showToast, setShowToast] = useState(false); // 토스트 상태
    const [showGroupModal, setShowGroupModal] = useState(false); // 모달 상태 추가
    const [showGroupAddModal, setShowGroupAddModal] = useState(false); // 모달 상태 추가
    const [showPolicyControlModal, setShowPolicyControlModal] = useState(false); // 모달 상태 추가
    const [selectedOptions, setSelectedOptions] = useState([]); // 선택된 옵션 저장
    const [binaryOptions, setBinaryOptions] = useState('00000'); // 선택된 옵션 저장
    const [encOpt, setEncOpt] = useState('00000'); // encOpt 값을 저장할 상태
    const [isSelect, setIsSelect] = useState(false);// 공유키 입력 버튼 관리

    const [encryptOptions, setEncryptOptions] = useState(''); // 선택된 옵션 저장

    const [selecteValues, setSelecteValues] = useState([]);
    const [showencryptKeyPolicyModal, setShowencryptKeyPolicyModal] = useState(false);

    const [value, setValue] = useState(null);


    const { policyId, keyname, algorithmName, keylength, mode, policyUuid, createdAt } = location.state || {}; // 추가 데이터 포함

    const organizerOptions = [
        { value: '1', label: 'MD' },
        { value: '2', label: 'NOBASE64' },
        { value: '3', label: '랜덤문자열 적용' },
        { value: '4', label: '이중 암호화 방지' },
        { value: '5', label: '부분 암호화' },

    ];


    const handleGroupModalShow = () => {
        setShowGroupModal(true); // 모달 열기
    };

    const handleGroupModalHide = () => {
        setShowGroupModal(false); // 모달 닫기
    };

    const handleCheckOutModal = () => {
        setModalShow(false); // 관리자 모달 닫기  
    };
    const handleGroupModalConfirm = () => {
        setShowGroupModal(false);
        setShowGroupAddModal(true);

    };
    const handleGroupAddModalShow = () => {
        setShowGroupAddModal(true); // 모달 열기
    };

    const handleGroupAddModalHide = () => {
        setShowGroupAddModal(false); // 모달 닫기
    };
    const handlePolicyControlConfirm = (selectedOptions) => {
        console.log('선택한 옵션:', selectedOptions);
        console.log('선택한 행:', selecteValues);

        const selectedKeynames = selecteValues.map(value => value.keyname); // 선택된 행의 keyname 목록

        // 선택된 행에만 옵션 값 적용
        const updatedTableData = tableData.map(row => {
            if (selectedKeynames.includes(row.keyname)) {
                console.log(`변경 중인 행: ${row.keyname}`);
                return {
                    ...row,
                    encryptionPermission: selectedOptions.encryptActive ? selectedOptions.encryptActive : true,
                    decryptionPermission: selectedOptions.decryptActive ? selectedOptions.decryptActive : true,
                    maskingEnabled: selectedOptions.isMaskingEnabled,
                    maskingData: selectedOptions.isMaskingEnabled ? selectedOptions.maskingChar : "",
                    startIndex: selectedOptions.isMaskingEnabled ? selectedOptions.startNumber : "",
                    size: selectedOptions.isMaskingEnabled ? selectedOptions.size : "",
                };
            }
            console.log(`변경되지 않은 행: ${row.keyname}`);
            return row; // 선택되지 않은 행은 그대로 유지
        });

        console.log('변경 후 데이터:', updatedTableData);

        setTableData(updatedTableData); // 상태 업데이트
        setSelectedControlOptions(selectedOptions); // 선택된 옵션 저장 (필요시)
        setShowPolicyControlModal(false); // 모달 닫기
        setIsModified(true); // 변경 상태 업데이트


    };
    const handleDeleteClick = () => {
        setModalShow(true); // 모달 열기
    };

    const handleCheckOutModalConfirm = () => {
        setModalShow(false);
        setModalPolicyDeleteShow(true);
    };

    const handleDataFromModal = (selectedData) => {
        // setTableData((prevData) => [
        //     ...prevData,  // 기존 데이터 유지
        //     ...selectedData // 모달에서 받아온 데이터 추가
        // ]);
        console.log("키 받아온거 ", selectedData);
        setTableData(selectedData);
        setApiKeyPolicyData(selectedData); // 모달에서 받은 데이터를 상태로 저장
        setTotalElements(selectedData.length);
        setIsModified(true); // 변경 상태 업데이트

    };
    const handleEncryptKeyPolicyModal = () => {
        console.log("누름");
        setShowencryptKeyPolicyModal(true);
    }


    const handlePolicyAdd = async () => {
        console.log("handlePolicyAdd 호출");

        const apikeyPolicies = tableData.map(item => ({
            apikeyName: item.keyname,
            apikeyUuid: item.apikeyUuid,
            encryptionPermission: item.encryptionPermission ?? false,
            decryptionPermission: item.decryptionPermission ?? false,
            maskingEnabled: item.maskingEnabled ?? false,
            maskingData: item.maskingData ?? "",
            startIndex: item.startIndex ?? "",
            size: item.size ?? "",
        }));

        // 폼 데이터를 이용하여 policyData 구성
        const policyData = {
            policyUuid: policyUuid, // 정책 UUID
            sharedkeyUuid: isSelect ? formData.keyname : "", // 공유키 UUID (선택된 경우)
            policy: {
                algo: formData.algorithm,
                mode: formData.mode,
                keyLen: formData.keylength,
                policyName: formData.keyname, // 설정할 정책 이름
                encOption: binaryOptions, // 이진수 문자열로 표현된 암호화 옵션
                // encSub: `${encryptOptions?.startPosition || ""}-${encryptOptions?.encryptionSize || ""}` // 부분 암호화 설정값 (기본값 "")
                encSub: encryptOptions
            },
            apikeyPolicies: apikeyPolicies // 모달에서 받아온 모든 인증키 데이터를 사용
        };
        console.log("policyData", policyData);

        try {
            // API 호출
            const response = await updateEncryptKeyPolicy(policyUuid, apikeyPolicies);
            console.log("과연? ", response);
            setModalConfirmShow(false);
            setModalInfoShow(true);
        } catch (error) {
            console.error("암호화 키 정책 등록 오류:", error);
            toast.error("암호화 키 정책 등록 중 오류가 발생했습니다.");
        }


    };
    const handlePolicyControlButton = () => {

        const selectedRows = table.getSelectedRowModel().rows;

        // 선택된 행에서 keyname 값만 추출
        const selecteValues = selectedRows.map(row => ({
            group: row.original.group, // group 정보를 추출
            keyname: row.original.keyname // keyname 정보를 추출
        }));


        setSelecteValues(selecteValues); // 선택된 keyname 상태 업데이트 

        setShowPolicyControlModal(true);
    }

    const handleClosePolicyControl = () => {
        setShowPolicyControlModal(false); // 모달 열기
    };


    const handleInfo = () => {
        setModalInfoShow(false); // 모달 열기
        navigate(-1);
    };
    const [pwType, setpwType] = useState({
        type: "password",
        visible: false,
    });
    const selectedRowCount = table.getSelectedRowModel().rows.length;

    const [formData, setFormData] = useState({
        policyId: policyId,
        algorithm: algorithmName,
        keylength: keylength,
        mode: mode,
        invalidDate: 'invalidDate',
        keyname: keyname,
        role: 'API',
        pubkeyYN: '',
        createdAt: createdAt,
        pubkeyName: '',
        description: '',
        adderRole: '',
        adderId: '',
        policyUuid: policyUuid,
    });
    const formatDateTimeForInput = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const { user } = useAuthWizardContext();
    // console.log(queryParams.get('pubkeyYN'));
    const [avatar, setAvatar] = useState([
        ...(user.avatar ? user.avatar : []),
        { src: avatarImg }
    ]);

    // API 호출 후 데이터 확인
    useEffect(() => {
        if (policyUuid) { // policyUuid가 존재할 때만 API 호출
            const detailKey = async () => {
                try {
                    const response = await detailEncKey(policyUuid);
                    console.log(response); // API 응답 데이터 확인
                    if (response.data && response.data.encOption) {
                        setEncOpt(response.data.encOption);
                    }
                    if (response.data && response.data.encSub) {
                        setEncryptOptions(response.data.encSub);
                    }


                } catch (error) {
                    console.error('불러오기 실패:', error);
                }
            };
            detailKey();
        }
    }, [policyUuid]);

    // 추출된 encOpt 값 출력
    useEffect(() => {
        if (encOpt) {
            console.log('추출된 encOpt 값:', encOpt);
        }
    }, [encOpt]);

    useEffect(() => {
        if (encOpt) {
            const initialSelectedOptions = [];
            const binaryArray = encOpt.split('');
            organizerOptions.forEach((option, index) => {
                if (binaryArray[index] === '1') {
                    initialSelectedOptions.push(option.value); // encOpt 값이 1인 옵션만 선택
                }
            });
            setSelectedOptions(initialSelectedOptions); // 초기 선택 상태 설정
            setBinaryOptions(encOpt);
        }
    }, [encOpt]);



    useEffect(() => {
        // 전체 데이터 로드를 위한 함수
        const loadAllData = async () => {
            const pageSize = 5; // 5 단위로 로드
            let allData = [];
            let totalElements = 0;

            // 300ms 후에 스피너를 표시하는 타이머 설정
            const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

            try {
                // 첫 번째 호출로 totalElements 확인
                const initialResponse = await fetchEncPolicyList(policyUuid, 0, pageSize);
                totalElements = initialResponse.data.page.totalElements;

                // 필요한 전체 데이터를 한 번에 요청
                const fullResponse = await fetchEncPolicyList(policyUuid, 0, totalElements);
                const content = fullResponse.data.content
                    .filter(item => item.selected === "1") // 필터링 조건 추가
                    .map((item, index) => ({
                        ...item,
                        num: index + 1, // 순번 추가 (전체 순번 기준)
                        status: item.state ? "정상" : "만료",
                        state: item.state,
                        group: item.groupName,
                        keyname: item.apiKeyName,
                        role: item.apiKeyType,
                        apikeyUuid: item.apiKeyUuid,
                        aboutEnc: item.encPermission ? "O" : "X",
                        aboutDec: item.decPermission ? "O" : "X",
                        masking: item.maskingEnabled ? "O" : "X",
                        createdAt: item.inserTimeUtc ? new Date(item.inserTimeUtc).toLocaleString('ko-KR', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false, // 24시간제
                        }) : '',
                        invalidAt: item.expiredTimeUtc ? new Date(item.expiredTimeUtc).toLocaleString('ko-KR', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false, // 24시간제
                        }) : '',
                        lastUsedAt: item.lastUsedTimeUtc ? new Date(item.lastUsedTimeUtc).toLocaleString('ko-KR', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false, // 24시간제
                        }) : '',
                        selected: item.selected
                    }));
                // 중복 제거
                console.log("fullResponse", fullResponse);
                const uniqueData = Array.from(new Map(content
                    .filter(item => item.selected === "1")
                    .map(item => [item.apiKeyUuid, item])
                ).values());

                console.log("uniqueData", uniqueData);
                allData = [...uniqueData];

                setTableData(allData);
                console.log("key data ", allData);
                setTotalElements(allData.length); // 총 요소 수 설정
            } catch (error) {
                console.error('데이터 불러오기 에러:', error);
            } finally {
                clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
                setLoading(false);
                setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
            }
        };

        loadAllData();
    }, [policyUuid]); // pageSize가 변경될 때마다 loadAllData 실행

    const handleSubmit = (e) => {
        e.preventDefault();
        setModalConfirmShow(true);

    };

    return (

        <div className="container mt-5">
            <Card className="mypage-card" >
                <Link to={goToParentPath()} style={{ textDecoration: 'none', color: 'inherit', }}>

                    <Card.Header className="px-3" as="h4">암호화 키 정책 관리</Card.Header>
                </Link>

                <Card.Title className="border border-bottom-0 px-3 pt-3">  정책 정보</Card.Title>
                <Card.Body className="border border-top-0">
                    <Form onSubmit={handleSubmit}>
                        {/* Right Column (ID, Password, Name) */}
                        <Col md={16}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>정책 아이디</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="policyId"
                                            value={formData.policyId}
                                            disabled
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>

                                    <Form.Group className="mb-3">
                                        <Form.Label>등록 일시</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            name="invalidDate"
                                            value={formatDateTimeForInput(formData.createdAt)}

                                            disabled

                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                                        <Form.Label className="d-flex mt-3">
                                            암호키 별칭
                                        </Form.Label>

                                        <Form.Control
                                            type="text"
                                            name="keyname"
                                            value={formData.keyname}
                                            disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <div className='p-1' style={{ border: '1px', borderStyle: 'solid' }}>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                                                    <Form.Label>암호화 알고리즘</Form.Label>
                                                    <Form.Select
                                                        name="algorithm"
                                                        // onChange={handleComboChange}
                                                        value={formData.algorithm}
                                                        disabled
                                                    >
                                                        <option value="" disabled>선택</option>
                                                        <option value="ARIA">ARIA</option>
                                                        <option value="SEED">SEED</option>
                                                        <option value="AES">AES</option>
                                                        <option disabled value="DES">DES</option>
                                                        <option disabled value="HAS160">HAS160</option>
                                                        <option disabled value="HMACwithHAS160">HMACwithHAS160</option>
                                                        <option disabled value="HMACwithMD5">HMACwithMD5</option>
                                                        <option disabled value="HMACwithMDC2">HMACwithMDC2</option>
                                                        <option disabled value="HMACwithSHA1">HMACwithSHA1</option>
                                                        <option disabled value="HMACwithSHA224">HMACwithSHA224</option>
                                                        <option disabled value="HMACwithSHA256">HMACwithSHA256</option>
                                                        <option disabled value="HMACwithSHA384">HMACwithSHA384</option>
                                                        <option disabled value="HMACwithSHA512">HMACwithSHA512</option>
                                                        <option disabled value="MD5">MD5</option>
                                                        <option disabled value="MDC2">MDC2</option>
                                                        <option disabled value="SHA1">SHA1</option>
                                                        <option disabled value="SHA224">SHA224</option>
                                                        <option disabled value="SHA256">SHA256</option>
                                                        <option disabled value="SHA384">SHA384</option>
                                                        <option disabled value="SHA512">SHA512</option>
                                                        <option disabled value="TripleDES">TripleDES</option>


                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                                                    <Form.Label>암호화 키 길이</Form.Label>
                                                    <Form.Select
                                                        name="keylength"
                                                        // onChange={handleComboChange}
                                                        value={formData.keylength}
                                                        disabled
                                                    >
                                                        <option value="" disabled>선택</option>
                                                        <option value="128">128</option>
                                                        <option value="192">192</option>
                                                        <option value="256">256</option>
                                                    </Form.Select>

                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                                                    <Form.Label>암호화 운영모드</Form.Label>
                                                    <Form.Select
                                                        name="mode"
                                                        // onChange={handleComboChange}
                                                        value={formData.mode}
                                                        disabled
                                                    >
                                                        <option value="" disabled>선택</option>
                                                        <option value="CBC" >CBC</option>
                                                        <option value="CFB" >CFB</option>
                                                        <option value="OFB" >OFB</option>
                                                        <option value="ECB" >ECB</option>
                                                        {/* {modeOptions.map((option) => (
                                                            <option key={option} value={option}>{option}</option>
                                                        ))} */}

                                                    </Form.Select>

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group className="mb-3">
                                            <Form.Label>암호화 옵션</Form.Label>
                                            <Select
                                                closeMenuOnSelect={false}
                                                options={organizerOptions}
                                                placeholder='Select...'
                                                isMulti
                                                classNamePrefix="react-select"
                                                value={organizerOptions.filter(option => selectedOptions.includes(option.value))}

                                                isDisabled={true}
                                            />
                                        </Form.Group>
                                    </div>

                                </Col>
                            </Row>

                        </Col>


                    </Form>
                </Card.Body>
            </Card>
            <Card >
                <Card.Title className="border border-bottom-0 px-3 pt-3">  적용 대상 인증키</Card.Title>
                <Card.Body>
                    <AdvanceTableProvider {...table}>
                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }} className="mb-2">

                            {/* <AdvanceTableFooter rowsPerPageSelection_inner /> */}
                            <Button
                                variant="secondary"
                                style={{
                                    position: 'absolute',
                                    right: '0rem',
                                    width: '8rem',
                                    top: '-2rem',
                                    whiteSpace: 'nowrap'
                                }}
                                onClick={handleEncryptKeyPolicyModal}
                            // disabled={selectedRowCount === 0}
                            // onClick={handlePolicyControlButton}
                            >
                                인증키 설정
                            </Button>
                            <Button
                                variant="secondary"
                                style={{
                                    position: 'absolute',
                                    right: '9rem',
                                    width: '10rem',
                                    top: '-2rem',
                                    whiteSpace: 'nowrap'
                                }}
                                disabled={selectedRowCount === 0}
                                onClick={handlePolicyControlButton}
                            >
                                권한 제어 설정
                            </Button>

                        </div>
                        <AdvanceTable
                            headerClassName="bg-200 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                striped: false,
                                className: 'fs-10 mb-0 overflow-hidden'
                            }}
                        />
                        <div className="mt-3">
                            <p style={{ margin: 0, fontWeight: 700 }}>총 {totalElements}건</p>
                            <AdvanceTablePagination />
                        </div>

                    </AdvanceTableProvider>
                    <div className="d-flex justify-content-between mt-3">
                        <Button variant="outline-danger" style={{ width: '100px' }} onClick={handleDeleteClick}>
                            삭제
                        </Button>
                        <div>
                            <Button className='mx-3' onClick={() => setModalConfirmShow(true)} variant="primary" style={{ width: '100px' }} disabled={!isModified}>
                                수정
                            </Button>
                            <Link to={paths.encryptKeyPolicy}>
                                <Button variant="outline-secondary" style={{ whiteSpace: 'nowrap', width: '100px' }}  >
                                    목록
                                </Button>
                            </Link>
                        </div>
                    </div>
                </Card.Body>

            </Card>

            <CheckOutModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onConfirm={handleCheckOutModalConfirm}
                title="경고" // 제목
                body="삭제된 암호키는 복구될 수 없습니다. 정말 삭제하시겠습니까?"
                cancelText="취소" // 취소 버튼 텍스트
                confirmText="삭제" // 확인 버튼 텍스트
                variant="warning" // 원하는 variant
                CancelButton={true}
                DefaultButton={true}
            />

            <CheckOutModal
                show={modalConfirmShow}
                onHide={() => setModalConfirmShow(false)}
                onConfirm={handlePolicyAdd}
                title="확인" // 제목
                body="적용 대상 인증키 수정 사항을 반영하시겠습니까?"
                cancelText="취소" // 취소 버튼 텍스트
                confirmText="확인" // 확인 버튼 텍스트
                variant="info" // 원하는 variant
                CancelButton={true}
                SubmitButton={true}
            />


            <CheckOutModal
                show={modalInfoShow}
                onHide={() => setModalInfoShow(false)}
                onConfirm={handleInfo}
                title="확인" // 제목
                body="수정 사항이 반영되었습니다."

                confirmText="확인" // 확인 버튼 텍스트
                variant="success" // 원하는 variant

                DefaultButton={true}
            />
            <CheckOutModal
                show={modalPolicyDeleteShow}
                onHide={() => setModalPolicyDeleteShow(false)}
                title="완료" // 제목
                body="암호키가 삭제되었습니다."
                cancelText="취소" // 취소 버튼 텍스트
                confirmText="삭제" // 확인 버튼 텍스트
                variant="success" // 원하는 variant
            />


            <PolicyControlModal
                show={showPolicyControlModal}
                onHide={handleClosePolicyControl}
                onConfirm={handlePolicyControlConfirm}
                selecteValues={selecteValues} // 선택된 keyname 전달
                policyId={formData.policyId}
                keyname={formData.keyname}

            />

            <EncryptKeyPolicyModal
                show={showencryptKeyPolicyModal}
                onHide={() => setShowencryptKeyPolicyModal(false)}
                onConfirm={(selectedData) => {
                    handleDataFromModal(selectedData); // 부모 페이지에서 데이터를 처리
                    setShowencryptKeyPolicyModal(false); // 모달 닫기
                }} policyUuid={formData.policyUuid}
            />
        </div >


    );
};

export default editencryptKeyPolicy;
